export const LOADING_INVENTORY = 'LOADING_INVENTORY';
export const FETCHING_INVENTORY = 'FETCHING_INVENTORY';
export const SET_ERROR = 'SET_ERROR';

export const PROCESS_PROCUREMENT_ITEMS = 'PROCESS_PROCUREMENT_ITEMS';
export const GENERAL_PROCUREMENT_ITEMS = 'GENERAL_REQUEST_PROCUREMENT_ITEMS';
export const PROCUREMENT_ITEMS_BY_ID = 'PROCUREMENT_ITEMS_BY_ID';
export const PURCHASE_ORDER = 'PURCHASE_ORDER';
export const PURCHASE_ORDER_BY_ID = 'PURCHASE_ORDER_BY_ID';
export const INVENTORY_ITEMS = 'INVENTORY_ITEMS';
export const PROCUREMENT_PURCHASE_ORDER = 'PROCUREMENT_PURCHASE_ORDER';
export const INVENTORY_ORDER = 'INVENTORY_ORDER';
export const INVENTORY_ORDER_BY_ID = 'INVENTORY_ORDER_BY_ID';
export const GENERAL_ITEM_LIST = 'GENERAL_ITEM_LIST';
export const INVENTORY_REQUEST_LIST = 'INVENTORY_REQUEST_LIST';
export const INVENTORY_REQUEST_BY_ID = 'INVENTORY_REQUEST_BY_ID';
export const GENERAL_REQUEST_ITEM_BY_ID = 'GENERAL_REQUEST_ITEM_BY_ID';

export const MAINTENANCE_PART_TYPE_LIST = 'MAINTENANCE_PART_TYPE_LIST';
export const JOBCARD_STATUS_SUCCESS = 'JOBCARD_STATUS_SUCCESS';

export const GET_JOBCARD_REQUEST = 'GET_JOBCARD_REQUEST';
export const GET_VEHICLE_PART_TYPE = 'GET_VEHICLE_PART_TYPE';
export const GET_PART_TYPE_SETTINGS = 'GET_PART_TYPE_SETTINGS';
export const GET_IDLE_VEHICLE = 'GET_IDLE_VEHICLE';
export const GET_JOBCARD_SETTINGS = 'GET_JOBCARD_SETTINGS';
export const SCHEDULE_MAINTENACE_QUEUE = 'SCHEDULE_MAINTENACE_QUEUE';

export const VEHICLE_MAKE_LISTING = 'VEHICLE_MAKE_LISTING';
export const WORKSHOP_SETTINGS_LIST = 'WORKSHOP_SETTINGS_LIST';
export const JOB_CATEGORY_LIST = 'JOB_CATEGORY_LIST';
export const JOB_CARD_MANAGEMENT = 'JOB_CARD_MANAGEMENT';

export const SCHEDULED_MAINENANCE_QUEUE = 'SCHEDULED_MAINENANCE_QUEUE';

export const STORE_LIST = 'STORE_LIST';
export const VENDOR_LIST = 'VENDOR_LIST';
export const VEHICLE_PART_LIST = 'VEHICLE_PART_LIST';
export const VEHICLE_PART_BY_ID = 'VEHICLE_PART_BY_ID';
export const VEHICLE_PART_POSITION = 'VEHICLE_PART_POSITION';

export const INVENTORY_HISTORY = 'INVENTORY_HISTORY';
export const INVENTORY_REPORT = 'INVENTORY_REPORT';
export const INVENTORY_STORE_ITEM = 'INVENTORY_STORE_ITEM';
export const INVENTORY_GENERAL_STORE = 'INVENTORY_GENERAL_STORE';

export const RECEIVE_ITEM_LIST = 'RECEIVE_ITEM_LIST';
export const SENT_ITEM_LIST = 'SENT_ITEM_LIST';
export const APPROVE_STOCK_IN = 'APPROVE_STOCK_IN';

export const PROCUMENT_ITEM_LIST = 'PROCUMENT_ITEM_LIST';
export const PROCUMENT_ITEM_DETAILS = 'PROCUMENT_ITEM_DETAILS';
export const SUPPLY_ITEM_LIST = 'SUPPLY_ITEM_LIST';
export const SUPPLY_ITEM_PURCHASE_ORDER = 'SUPPLY_ITEM_PURCHASE_ORDER';
export const VEHICLE_DUE_FOR_SERVICE = 'VEHICLE_DUE_FOR_SERVICE';
export const SCHEDULED_MAINENANCE_MILEAGE = 'SCHEDULED_MAINENANCE_MILEAGE';

export const LISTING_RESET = 'LISTING_RESET';
export const LOGOUT = 'LOGOUT';
