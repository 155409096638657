import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
// form
import { useSnackbar } from 'notistack';

// @mui
import { LoadingButton } from '@mui/lab';
import { Autocomplete, Box, Grid, Stack, TextField } from '@mui/material';
import { httpRequest } from '../../../../../https/http';

// components
import { useDispatch } from '../../../../../redux/store';
import { getAllCaptainByAction } from '../../../../../redux/settings/settingsActions';
import { importedDeduction } from '../../../../../redux/captains/captainActions';

DeductionsImportForm.propTypes = {
  handleCloseModal: PropTypes.func,
};

export default function DeductionsImportForm({ handleCloseModal }) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const nextDay = new Date();
  nextDay.setDate(nextDay.getDate() + 1);
  const [loading, setloading] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [documentType, setDocumentType] = useState('');
  const [documentTypeUniqueId, setDocumentTypeUniqueId] = useState('');
  const [documentTypeList, setDocumentTypeList] = useState([]);

  const handleSubmit = async () => {
    if (!documentType) {
      enqueueSnackbar('Please select Document Type', { variant: 'error' });
      return;
    }

    if (!selectedFile) {
      enqueueSnackbar('Please select a file', { variant: 'error' });
      return;
    }
    setloading(true);

    const formData = new FormData();
    formData.append('File', selectedFile);
    formData.append('DocumentTypeUniqueId', documentTypeUniqueId);

    try {
      const result = await httpRequest({
        url: `DocumentConfiguration/CaptainSalaryDeductionDocumentsUpload`,
        method: 'POST',
        urlType: 'settings',
        body: formData,
        isFormData: true,
      });

      if (result.code === 1) {
        const data = {
          currentPage: 1,
          totalPages: 1,
          pageSize: result?.data?.captainDeductionsResponseDataDtos?.length || 50,
          totalCount: result?.data?.captainDeductionsResponseDataDtos?.length || 50,
          userData: result?.data?.captainDeductionsResponseDataDtos,
        };
        setDocumentType('');
        setDocumentTypeUniqueId('');
        setSelectedFile(null);
        handleCloseModal();
        dispatch(importedDeduction(data));
        if (result.shortDescription) {
          enqueueSnackbar(result.shortDescription, { variant: 'success' });
        }
      } else if (result.shortDescription) {
        enqueueSnackbar(result.shortDescription, { variant: 'error' });
      }

      setloading(false);
    } catch (error) {
      setloading(false);
      enqueueSnackbar(error?.message || 'Something went wrong, please refresh and try again!', { variant: 'error' });
    }

    // eslint-disable-next-line consistent-return
    return true;
  };

  const getDocumentType = async () => {
    setFetching(true);

    try {
      const result = await httpRequest({
        url: `DocumentConfiguration/DocumentTypes`,
        urlType: 'settings',
      });
      if (result.code === 1) {
        setDocumentTypeList(result?.data);
      }
      if (result.code !== 1) {
        if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
      }
      setFetching(false);
    } catch (error) {
      setFetching(false);
      enqueueSnackbar('Something went wrong, please refresh and try again!', { variant: 'error' });
    }
    return true;
  };

  useEffect(() => {
    getDocumentType();
    dispatch(getAllCaptainByAction(enqueueSnackbar));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container spacing={3} sx={{ px: 3 }}>
      <Grid item xs={12}>
        <Box>
          <Box>
            <Box>
              <Autocomplete
                value={documentType}
                inputValue={documentType}
                onInputChange={(event, newInputValue) => {
                  setDocumentType(newInputValue);

                  const termInfo = documentTypeList?.find(
                    (item) => `${item?.name} (${item?.numberOfColumns} Columns)` === newInputValue
                  );

                  if (termInfo) {
                    setDocumentTypeUniqueId(termInfo?.uniqueId);
                  } else {
                    setDocumentTypeUniqueId('');
                  }
                }}
                includeInputInList
                id="controllable-states-demo"
                options={
                  // eslint-disable-next-line no-nested-ternary
                  documentTypeList?.length
                    ? ['', documentTypeList?.map((c) => `${c?.name} (${c?.numberOfColumns} Columns)` || '-')]
                    : fetching
                    ? ['Fetching...']
                    : []
                }
                renderInput={(params) => <TextField {...params} label="Select a Document Type" />}
              />
            </Box>
            <Box mt={3}>
              <input
                onChange={(e) => setSelectedFile(e.target.files[0])}
                type="file"
                accept="application/pdf, application/msword, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/csv, text/csv, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              />

              <Box mt={2} sx={{ py: '4', color: '#E21D00' }}>
                <b style={{ fontSize: '12px' }}>Type: excel & docx </b>
              </Box>
            </Box>
          </Box>
        </Box>

        <Stack alignItems="flex-end" sx={{ mt: 3 }}>
          <LoadingButton type="button" onClick={handleSubmit} variant="contained" loading={loading}>
            Import and Save
          </LoadingButton>
        </Stack>
      </Grid>
    </Grid>
  );
}
