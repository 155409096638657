import { httpRequest } from '../../https/http';
import { modalCloseAction } from '../modal/modalActions';
import { handleConvertByte } from '../../utils/convertByteArray';
import * as BookingsTypes from './bookingsTypes';

export const getWalletHistoryCreditByAction =
  (enqueueSnackbar, email = '', isCredited = true, pageNumber = 1, pageSize = 50, paginated = true) =>
  async (dispatch) => {
    dispatch({ type: BookingsTypes.LOADING_BOOKING });
    let filteredURL = `wallet/transactionHistory?Email=${email}&isCredited=${isCredited}&isDescending=true`;
    if (paginated) filteredURL = `${filteredURL}&PageNumber=${pageNumber}&PageSize=${pageSize}&Paginated=${paginated}`;

    try {
      const result = await httpRequest({
        url: filteredURL,
        urlType: 'bookings',
      });
      if (result.code === 1) {
        dispatch({ type: BookingsTypes.USER_WALLET_INFLOW_HISTORY, payload: result?.data });
      }
      if (result.code !== 1) {
        dispatch({ type: BookingsTypes.LISTING_RESET });
        if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
      }
    } catch (error) {
      dispatch({ type: BookingsTypes.LISTING_RESET });
      enqueueSnackbar(error?.message || 'Unable to fetch data! Please refresh and try again', { variant: 'error' });
    }
  };
export const getAirtimePaymentHistoryByAction =
  (enqueueSnackbar, email = '', pageNumber = 1, pageSize = 50, paginated = false) =>
  async (dispatch) => {
    dispatch({ type: BookingsTypes.FETCHING_BOOKING });
    let filteredURL = `payment/history?Email=${email}&PaymentType=1&isDescending=true`;

    if (paginated)
      filteredURL = `${filteredURL}&PageNumber=${pageNumber}&PageSize=${pageSize}&isPaginated=${paginated}`;

    try {
      const result = await httpRequest({
        url: filteredURL,
        urlType: 'bookings',
      });
      if (result.code === 1) {
        dispatch({ type: BookingsTypes.USER_AIRTME_PAYMENT_HISTORY, payload: result?.data });
      }
      if (result.code !== 1) {
        dispatch({ type: BookingsTypes.LISTING_RESET });
        if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
      }
    } catch (error) {
      dispatch({ type: BookingsTypes.LISTING_RESET });
      enqueueSnackbar(error?.message || 'Unable to fetch data! Please refresh and try again', { variant: 'error' });
    }
  };

export const getUserBoatHistoryAction =
  (enqueueSnackbar, email = '', pageNumber = 1, pageSize = 50, paginated = true) =>
  async (dispatch) => {
    dispatch({ type: BookingsTypes.LOADING_BOOKING });
    let filteredURL = `boat/history?Email=${email}&isDescending=true`;
    if (paginated) filteredURL = `${filteredURL}&pageNumber=${pageNumber}&pageSize=${pageSize}&Paginated=${paginated}`;

    try {
      const result = await httpRequest({
        url: filteredURL,
        urlType: 'bookings',
      });

      if (result.code === 1) {
        dispatch({ type: BookingsTypes.USER_BOAT_HISTORY, payload: result?.data });
      }

      if (result.code !== 1) {
        dispatch({ type: BookingsTypes.LISTING_RESET });
        if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
      }
    } catch (error) {
      dispatch({ type: BookingsTypes.LISTING_RESET });
      enqueueSnackbar(error?.message || 'Unable to fetch data! Please refresh and try again', { variant: 'error' });
    }
  };

export const getUserBookingHistoryAction =
  (enqueueSnackbar, phoneNo = '', pageNumber = 1, pageSize = 50, paginated = false) =>
  async (dispatch) => {
    dispatch({ type: BookingsTypes.LOADING_BOOKING });
    // let filteredURL = `booking/historyByPhonenumber?phoneNo=${phoneNo}&Descending=true`;
    let filteredURL = `booking/historyByPhonenumber?Phonenumber=${phoneNo}&PageNumber=${pageNumber}&PageSize=${pageSize}&Descending=true&Paginated=true`;
    if (paginated) filteredURL = `${filteredURL}&pageNumber=${pageNumber}&pageSize=${pageSize}&Paginated=${paginated}`;

    try {
      const result = await httpRequest({
        url: filteredURL,
        urlType: 'bookings',
      });

      if (result.code === 1) {
        dispatch({ type: BookingsTypes.USER_BOOKING_HISTORY, payload: result?.data });
      }

      if (result.code !== 1) {
        dispatch({ type: BookingsTypes.LISTING_RESET });
        if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
      }
    } catch (error) {
      dispatch({ type: BookingsTypes.LISTING_RESET });
      enqueueSnackbar(error?.message || 'Unable to fetch data! Please refresh and try again', { variant: 'error' });
    }
  };

export const userBusHistoryAction =
  (enqueueSnackbar, email = '', pageNumber = 1, pageSize = 50, paginated = true) =>
  async (dispatch) => {
    dispatch({ type: BookingsTypes.LOADING_BOOKING });
    let filteredURL = `booking/history?Email=${email}&isPaginated=${paginated}&isDescending=true`;
    if (paginated) filteredURL = `${filteredURL}&pageNumber=${pageNumber}&pageSize=${pageSize}`;

    try {
      const result = await httpRequest({
        url: filteredURL,
        urlType: 'bookings',
      });

      if (result.code === 1) {
        dispatch({ type: BookingsTypes.USER_BUS_HISTORY, payload: result?.data });
      }

      if (result.code !== 1) {
        dispatch({ type: BookingsTypes.LISTING_RESET });
        if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
      }
    } catch (error) {
      dispatch({ type: BookingsTypes.LISTING_RESET });
      enqueueSnackbar(error?.message || 'Unable to fetch data! Please refresh and try again', { variant: 'error' });
    }
  };

export const getCustomerPaymentHistory =
  (enqueueSnackbar, email = '', paymentType = 0, pageNumber = 1, pageSize = 50, paginated = true) =>
  async (dispatch) => {
    dispatch({ type: BookingsTypes.LOADING_BOOKING });
    let filteredURL = `payment/history?Email=${email}&PaymentType=${paymentType}&isPaginated=${paginated}&isDescending=true`;
    if (paginated) filteredURL = `${filteredURL}&pageNumber=${pageNumber}&pageSize=${pageSize}`;

    try {
      const result = await httpRequest({
        url: filteredURL,
        urlType: 'bookings',
      });

      if (result.code === 1) {
        dispatch({ type: BookingsTypes.USER_BOOKING_HISTORY, payload: result?.data });
      }

      if (result.code !== 1) {
        dispatch({ type: BookingsTypes.LISTING_RESET });
        if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
      }
    } catch (error) {
      dispatch({ type: BookingsTypes.LISTING_RESET });
      enqueueSnackbar(error?.message || 'Unable to fetch data! Please refresh and try again', { variant: 'error' });
    }
  };

export const fetchCountryTableAction =
  (enqueueSnackbar, pageNumber = 1, pageSize = 50, paginated = false) =>
  async (dispatch) => {
    dispatch({ type: BookingsTypes.LOADING_BOOKING });
    let filteredURL = `Country?Paginated=${paginated}`;
    if (paginated) filteredURL = `${filteredURL}&PageNumber=${pageNumber}&PageSize=${pageSize}&Paginated=${paginated}`;

    try {
      const result = await httpRequest({
        url: filteredURL,
        method: 'GET',
        urlType: 'settings',
      });

      if (result.code === 1) {
        dispatch({ type: BookingsTypes.COUNTRY_SUCCESS, payload: result?.data });
      }

      if (result.code !== 1) {
        if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
        dispatch({ type: BookingsTypes.LISTING_FAILED });
      }
    } catch (error) {
      dispatch({ type: BookingsTypes.LISTING_FAILED });
      enqueueSnackbar(error?.message || 'Something went wrong, please refresh and try again!', { variant: 'error' });
    }
  };
export const fetchCountryAction =
  (enqueueSnackbar, pageNumber = 1, pageSize = 50, paginated = false) =>
  async (dispatch) => {
    dispatch({ type: BookingsTypes.LOADING_BOOKING });
    let filteredURL = `Country?Paginated=${paginated}`;
    if (paginated) filteredURL = `${filteredURL}&PageNumber=${pageNumber}&PageSize=${pageSize}&Paginated=${paginated}`;

    try {
      const result = await httpRequest({
        url: filteredURL,
        method: 'GET',
        urlType: 'settings',
      });

      if (result.code === 1) {
        dispatch({ type: BookingsTypes.COUNTRY_SUCCESS, payload: result?.data?.userData });
      }

      if (result.code !== 1) {
        if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
        dispatch({ type: BookingsTypes.LISTING_FAILED });
      }
    } catch (error) {
      dispatch({ type: BookingsTypes.LISTING_FAILED });
      enqueueSnackbar(error?.message || 'Something went wrong, please refresh and try again!', { variant: 'error' });
    }
  };
export const fetchRegionTableAction =
  (enqueueSnackbar, pageNumber = 1, pageSize = 50, paginated = false) =>
  async (dispatch) => {
    dispatch({ type: BookingsTypes.LOADING_BOOKING });
    let filteredURL = `Region?Descending=true`;
    if (paginated) filteredURL = `${filteredURL}&PageNumber=${pageNumber}&PageSize=${pageSize}&Paginated=${paginated}`;

    try {
      const result = await httpRequest({
        url: filteredURL,
        method: 'GET',
        urlType: 'settings',
      });

      if (result.code === 1) {
        dispatch({ type: BookingsTypes.REGION_SUCCESS, payload: result?.data });
      }

      if (result.code !== 1) {
        if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
        dispatch({ type: BookingsTypes.LISTING_FAILED });
      }
    } catch (error) {
      dispatch({ type: BookingsTypes.LISTING_FAILED });
      enqueueSnackbar(error?.message || 'Something went wrong, please refresh and try again!', { variant: 'error' });
    }
  };
export const fetchRegionAction =
  (enqueueSnackbar, paginated = false) =>
  async (dispatch) => {
    dispatch({ type: BookingsTypes.LOADING_BOOKING });
    try {
      const result = await httpRequest({
        url: `Region?Descending=true&Paginated=${paginated}`,
        method: 'GET',
        urlType: 'settings',
      });

      if (result.code === 1) {
        dispatch({ type: BookingsTypes.REGION_SUCCESS, payload: result?.data?.userData });
      }

      if (result.code !== 1) {
        if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
        dispatch({ type: BookingsTypes.LISTING_FAILED });
      }
    } catch (error) {
      dispatch({ type: BookingsTypes.LISTING_FAILED });
      enqueueSnackbar(error?.message || 'Something went wrong, please refresh and try again!', { variant: 'error' });
    }
  };

export const fetchStateAction = (enqueueSnackbar) => async (dispatch) => {
  dispatch({ type: BookingsTypes.LOADING_BOOKING });
  try {
    const result = await httpRequest({
      url: `State/StateRegion`,
      method: 'GET',
      urlType: 'settings',
    });

    if (result.code === 1) {
      dispatch({ type: BookingsTypes.STATE_SUCCESS, payload: result?.data });
    }

    if (result.code !== 1) {
      if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
      dispatch({ type: BookingsTypes.LISTING_FAILED });
    }
  } catch (error) {
    dispatch({ type: BookingsTypes.LISTING_FAILED });
    enqueueSnackbar(error?.message || 'Something went wrong, please refresh and try again!', { variant: 'error' });
  }
};

export const getFixedChargesAction =
  (enqueueSnackbar, pageNumber = 1, pageSize = 50, paginated = false) =>
  async (dispatch) => {
    dispatch({ type: BookingsTypes.LOADING_BOOKING });
    let filteredURL = `FixedCharges?Descending=true`;
    if (paginated) filteredURL = `${filteredURL}&PageNumber=${pageNumber}&PageSize=${pageSize}&Paginated=${paginated}`;

    try {
      const result = await httpRequest({
        url: filteredURL,
        method: 'GET',
        urlType: 'bookings',
      });

      if (result.code === 1) {
        dispatch({ type: BookingsTypes.FIXED_CHARGES_SETTINGS, payload: result?.data });
      }

      if (result.code !== 1) {
        if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
        dispatch({ type: BookingsTypes.LISTING_FAILED });
      }
    } catch (error) {
      dispatch({ type: BookingsTypes.LISTING_FAILED });
      enqueueSnackbar(error?.message || 'Something went wrong, please refresh and try again!', { variant: 'error' });
    }
  };

export const getFuelCostAction =
  (enqueueSnackbar, pageNumber = 1, pageSize = 50, paginated = false) =>
  async (dispatch) => {
    dispatch({ type: BookingsTypes.LOADING_BOOKING });
    let filteredURL = `FuelCost?Descending=true`;
    if (paginated) filteredURL = `${filteredURL}&PageNumber=${pageNumber}&PageSize=${pageSize}&Paginated=${paginated}`;

    try {
      const result = await httpRequest({
        url: filteredURL,
        method: 'GET',
        urlType: 'bookings',
      });

      if (result.code === 1) {
        dispatch({ type: BookingsTypes.FUEL_COST_SETTINGS, payload: result?.data });
      }

      if (result.code !== 1) {
        if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
        dispatch({ type: BookingsTypes.LISTING_FAILED });
      }
    } catch (error) {
      dispatch({ type: BookingsTypes.LISTING_FAILED });
      enqueueSnackbar(error?.message || 'Something went wrong, please refresh and try again!', { variant: 'error' });
    }
  };

export const fetchDiscountAction =
  (enqueueSnackbar, pageNumber = 1, pageSize = 50, paginated = false) =>
  async (dispatch) => {
    dispatch({ type: BookingsTypes.LOADING_BOOKING });
    let filteredURL = `Discount?Descending=true`;
    if (paginated) filteredURL = `${filteredURL}&PageNumber=${pageNumber}&PageSize=${pageSize}&Paginated=${paginated}`;

    try {
      const result = await httpRequest({
        url: filteredURL,
        method: 'GET',
        urlType: 'bookings',
      });

      if (result.code === 1) {
        dispatch({ type: BookingsTypes.DISCOUNT_SUCCESS, payload: result?.data });
      }

      if (result.code !== 1) {
        if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
        dispatch({ type: BookingsTypes.LISTING_FAILED });
      }
    } catch (error) {
      dispatch({ type: BookingsTypes.LISTING_FAILED });
      enqueueSnackbar(error?.message || 'Something went wrong, please refresh and try again!', { variant: 'error' });
    }
  };

export const fetchRouteAction =
  (enqueueSnackbar, paginated = false, pageNumber = 1, pageSize = 50, isReport = false, param = {}) =>
  async (dispatch) => {
    dispatch({ type: BookingsTypes.LOADING_BOOKING });
    let filteredURL = `Route?Descending=true&Paginated=${paginated}`;
    if (paginated) filteredURL = `${filteredURL}&pageNumber=${pageNumber}&pageSize=${pageSize}`;
    if (isReport) filteredURL = `${filteredURL}&IsReport=${isReport}`;
    if (param?.routeSearchValue)
      filteredURL = `${filteredURL}&parameter=${param?.routeSearchName}&Search=${param?.routeSearchValue}`;

    try {
      const result = await httpRequest({
        url: filteredURL,
        method: 'GET',
        urlType: 'settings',
      });

      if (result.code === 1) {
        if (isReport) {
          handleConvertByte(result?.data?.reportData, result?.data?.reportName);
          dispatch({ type: BookingsTypes.LISTING_RESET });
        } else if (paginated) {
          dispatch({ type: BookingsTypes.ROUTE_SUCCESS_PAGINATED, payload: result?.data });
        } else {
          dispatch({ type: BookingsTypes.ROUTE_SUCCESS, payload: result?.data });
        }
      }

      if (result.code !== 1) {
        if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
        dispatch({ type: BookingsTypes.LISTING_FAILED });
      }
    } catch (error) {
      dispatch({ type: BookingsTypes.LISTING_FAILED });
      enqueueSnackbar(error?.message || 'Something went wrong, please refresh and try again!', { variant: 'error' });
    }
  };

export const fetchOnlineRouteAction = (enqueueSnackbar) => async (dispatch) => {
  dispatch({ type: BookingsTypes.LOADING_BOOKING });
  try {
    const result = await httpRequest({
      url: `Route/onlineroutes`,
      method: 'GET',
      urlType: 'settings',
    });

    if (result.code === 1) {
      dispatch({ type: BookingsTypes.ONLINE_ROUTE_SUCCESS, payload: result?.data });
    }

    if (result.code !== 1) {
      if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
      dispatch({ type: BookingsTypes.LISTING_FAILED });
    }
  } catch (error) {
    dispatch({ type: BookingsTypes.LISTING_FAILED });
    enqueueSnackbar(error?.message || 'Something went wrong, please refresh and try again!', { variant: 'error' });
  }
};

export const fetchTerminalAction =
  (enqueueSnackbar, paginated = false, pageNumber = 1, pageSize = 50) =>
  async (dispatch) => {
    // dispatch({ type: BookingsTypes.LOADING_BOOKING });

    dispatch({ type: BookingsTypes.LOADING_BOOKING });

    let filteredURL = `Terminal?AvialableOnLine=1000&Paginated=${paginated}`;
    if (paginated) filteredURL = `${filteredURL}&pageNumber=${pageNumber}&pageSize=${pageSize}`;

    try {
      const result = await httpRequest({
        url: filteredURL,
        method: 'GET',
        urlType: 'settings',
      });

      if (result.code !== 1) {
        if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
        dispatch({ type: BookingsTypes.LISTING_FAILED });
      }
      if (result.code === 1) {
        if (paginated) {
          dispatch({ type: BookingsTypes.TERMINAL_SUCCESS, payload: result?.data });
        } else {
          dispatch({ type: BookingsTypes.TERMINAL_SUCCESS, payload: result?.data });
        }
      }
    } catch (error) {
      dispatch({ type: BookingsTypes.LISTING_FAILED });
      enqueueSnackbar(error?.message || 'Something went wrong, please refresh and try again!', { variant: 'error' });
    }
  };

export const getDestinationByTerminals = (enqueueSnackbar, departureTerminalId) => async (dispatch) => {
  dispatch({ type: BookingsTypes.LOADING_BOOKING });
  try {
    const result = await httpRequest({
      url: `Terminal/GetDestinationTerminals/${departureTerminalId}`,
      urlType: 'settings',
    });

    if (result.code === 1) {
      dispatch({ type: BookingsTypes.TERMINAL_BY_DESTINATION_SUCCESS, payload: result?.data });
    }

    if (result.code !== 1) {
      if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
      dispatch({ type: BookingsTypes.LISTING_FAILED });
    }
  } catch (error) {
    dispatch({ type: BookingsTypes.LISTING_FAILED });
    enqueueSnackbar('Unable to fetch data! Please refresh and try again', { variant: 'error' });
  }
};

export const getPickUpPointAction =
  (enqueueSnackbar, routeId = '', pageNumber = 1, pageSize = 50, paginated = false) =>
  async (dispatch) => {
    dispatch({ type: BookingsTypes.LOADING_BOOKING });
    try {
      let filteredURL = `PickupPoint?&Descending=true`;
      if (routeId) filteredURL = `${filteredURL}&RouteId=${routeId}`;
      if (paginated)
        filteredURL = `${filteredURL}&PageNumber=${pageNumber}&PageSize=${pageSize}&Paginated=${paginated}`;

      const result = await httpRequest({
        url: filteredURL,
        urlType: 'bookings',
      });

      if (result.code === 1) {
        dispatch({ type: BookingsTypes.PICKUP_POINT_SUCCESS, payload: result?.data });
      }

      if (result.code !== 1) {
        if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
        dispatch({ type: BookingsTypes.LISTING_FAILED });
      }
    } catch (error) {
      dispatch({ type: BookingsTypes.LISTING_FAILED });
      enqueueSnackbar(error?.message || 'Something went wrong, please refresh and try again!', { variant: 'error' });
    }
  };
export const getTerminalBookingReportDetailAction =
  (enqueueSnackbar, startDate = '', endDate = '', email = '', pageNumber = 1, pageSize = 50, paginated = false) =>
  async (dispatch) => {
    dispatch({ type: BookingsTypes.LOADING_BOOKING });

    let filteredURL = `Report/getTerminalBookingDetailsReport?Descending=true`;
    if (startDate) filteredURL = `${filteredURL}&StartDate=${startDate}`;
    if (endDate) filteredURL = `${filteredURL}&EndDate=${endDate}`;
    if (email) filteredURL = `${filteredURL}&Email=${email}`;
    if (paginated) filteredURL = `${filteredURL}&PageNumber=${pageNumber}&PageSize=${pageSize}&Paginated=${paginated}`;

    try {
      const result = await httpRequest({
        url: filteredURL,
        urlType: 'bookings',
      });
      if (result.code === 1) {
        dispatch({ type: BookingsTypes.TERMINAL_BOOKING_REPORT_DETAILS, payload: result?.data });
      }

      if (result.code !== 1) {
        dispatch({ type: BookingsTypes.LISTING_FAILED });
        if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
      }
    } catch (error) {
      dispatch({ type: BookingsTypes.LISTING_FAILED });
      enqueueSnackbar(error?.message || 'Unable to fetch data! Please refresh and try again', { variant: 'error' });
    }
  };
export const getAdvanceBookingReportDetailAction =
  (enqueueSnackbar, startDate = '', endDate = '', email = '', pageNumber = 1, pageSize = 50, paginated = false) =>
  async (dispatch) => {
    dispatch({ type: BookingsTypes.LOADING_BOOKING });

    let filteredURL = `Report/getAdvanceBookingDetailsReport?&Descending=true`;
    if (startDate) filteredURL = `${filteredURL}&StartDate=${startDate}`;
    if (endDate) filteredURL = `${filteredURL}&EndDate=${endDate}`;
    if (email) filteredURL = `${filteredURL}&Email=${email}`;
    if (paginated) filteredURL = `${filteredURL}&PageNumber=${pageNumber}&PageSize=${pageSize}&Paginated=${paginated}`;

    try {
      const result = await httpRequest({
        url: filteredURL,
        urlType: 'bookings',
      });
      if (result.code === 1) {
        dispatch({ type: BookingsTypes.ADVANCE_BOOKING_REPORT_DETAILS, payload: result?.data });
      }

      if (result.code !== 1) {
        dispatch({ type: BookingsTypes.LISTING_FAILED });
        if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
      }
    } catch (error) {
      dispatch({ type: BookingsTypes.LISTING_FAILED });
      enqueueSnackbar(error?.message || 'Unable to fetch data! Please refresh and try again', { variant: 'error' });
    }
  };
export const getShortageReportAction =
  (
    enqueueSnackbar,
    startDate = '',
    endDate = '',
    terminalId = '',
    routeId = '',
    vehicleRegNo = '',
    pageNumber = 1,
    pageSize = 50,
    paginated = false
  ) =>
  async (dispatch) => {
    dispatch({ type: BookingsTypes.LOADING_BOOKING });

    let filteredURL = `Report/getShortageBookingReport?Descending=true`;
    if (startDate) filteredURL = `${filteredURL}&StartDate=${startDate}`;
    if (endDate) filteredURL = `${filteredURL}&EndDate=${endDate}`;
    if (terminalId) filteredURL = `${filteredURL}&TerminalId=${terminalId}`;
    if (routeId) filteredURL = `${filteredURL}&RouteId=${routeId}`;
    if (vehicleRegNo) filteredURL = `${filteredURL}&VehicleRegistrationNo=${vehicleRegNo}`;
    if (paginated) filteredURL = `${filteredURL}&PageNumber=${pageNumber}&PageSize=${pageSize}&Paginated=${paginated}`;

    try {
      const result = await httpRequest({
        url: filteredURL,
        urlType: 'bookings',
      });
      if (result.code === 1) {
        dispatch({ type: BookingsTypes.SHORTAGE_REPORT, payload: result?.data });
      }

      if (result.code !== 1) {
        dispatch({ type: BookingsTypes.LISTING_FAILED });
        if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
      }
    } catch (error) {
      dispatch({ type: BookingsTypes.LISTING_FAILED });
      enqueueSnackbar(error?.message || 'Unable to fetch data! Please refresh and try again', { variant: 'error' });
    }
  };
export const getAdvanceBookingReportAction =
  (
    enqueueSnackbar,
    startDate = '',
    endDate = '',
    terminalId = '',
    isCrossSell = false,
    email = '',
    pageNumber = 1,
    pageSize = 50,
    paginated = false
  ) =>
  async (dispatch) => {
    dispatch({ type: BookingsTypes.LOADING_BOOKING });

    let filteredURL = `Report/getAdvanceBookingReport?Descending=true`;
    if (startDate) filteredURL = `${filteredURL}&StartDate=${startDate}`;
    if (endDate) filteredURL = `${filteredURL}&EndDate=${endDate}`;
    if (terminalId) filteredURL = `${filteredURL}&TerminalId=${terminalId}`;
    filteredURL = `${filteredURL}&IsCrossSell=${isCrossSell}`;
    if (email) filteredURL = `${filteredURL}&Email=${email}`;
    if (paginated) filteredURL = `${filteredURL}&PageNumber=${pageNumber}&PageSize=${pageSize}&Paginated=${paginated}`;

    try {
      const result = await httpRequest({
        url: filteredURL,
        urlType: 'bookings',
      });
      if (result.code === 1) {
        dispatch({ type: BookingsTypes.ADVANCE_BOOKING_REPORT, payload: result?.data });
      }

      if (result.code !== 1) {
        dispatch({ type: BookingsTypes.LISTING_FAILED });
        if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
      }
    } catch (error) {
      dispatch({ type: BookingsTypes.LISTING_FAILED });
      enqueueSnackbar(error?.message || 'Unable to fetch data! Please refresh and try again', { variant: 'error' });
    }
  };
export const getTerminalBookingReportAction =
  (
    enqueueSnackbar,
    startDate = '',
    endDate = '',
    terminalId = '',
    email = '',
    pageNumber = 1,
    pageSize = 50,
    paginated = false
  ) =>
  async (dispatch) => {
    dispatch({ type: BookingsTypes.LOADING_BOOKING });

    let filteredURL = `Report/getTerminalBookingReport?Descending=true`;
    if (terminalId) filteredURL = `${filteredURL}&TerminalId=${terminalId}`;
    if (startDate) filteredURL = `${filteredURL}&StartDate=${startDate}`;
    if (endDate) filteredURL = `${filteredURL}&EndDate=${endDate}`;
    if (email) filteredURL = `${filteredURL}&Email=${email}`;

    if (paginated) filteredURL = `${filteredURL}&PageNumber=${pageNumber}&PageSize=${pageSize}&Paginated=${paginated}`;

    try {
      const result = await httpRequest({
        url: filteredURL,
        urlType: 'bookings',
      });
      if (result.code === 1) {
        dispatch({ type: BookingsTypes.TERMINAL_BOOKING_REPORT, payload: result?.data });
      }

      if (result.code !== 1) {
        dispatch({ type: BookingsTypes.LISTING_FAILED });
        if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
      }
    } catch (error) {
      dispatch({ type: BookingsTypes.LISTING_FAILED });
      enqueueSnackbar(error?.message || 'Unable to fetch data! Please refresh and try again', { variant: 'error' });
    }
  };

export const getDispatchFeeAction =
  (
    enqueueSnackbar,
    routeId = '',
    vehicleModelId = '',
    dispatchPartnerId = '',
    dispatchType = '100',
    pageNumber = 1,
    pageSize = 50,
    paginated = false,
    isReport = false
  ) =>
  async (dispatch) => {
    dispatch({ type: BookingsTypes.LOADING_BOOKING });

    let filteredURL = `Dispatchfee/AllDisPatchFees?Descending=true`;
    if (vehicleModelId) filteredURL = `${filteredURL}&VehicleModelId=${vehicleModelId}`;
    if (dispatchPartnerId) filteredURL = `${filteredURL}&DispatchPartnerTypeId=${dispatchPartnerId}`;
    if (dispatchType) filteredURL = `${filteredURL}&DispatchType=${dispatchType}`;
    if (routeId) filteredURL = `${filteredURL}&RouteId=${routeId}`;
    if (isReport) filteredURL = `${filteredURL}&IsReport=${isReport}`;

    if (paginated) filteredURL = `${filteredURL}&PageNumber=${pageNumber}&PageSize=${pageSize}&Paginated=${paginated}`;

    try {
      const result = await httpRequest({
        url: filteredURL,
        urlType: 'bookings',
      });
      if (result.code === 1) {
        if (isReport) {
          handleConvertByte(result?.data?.reportData, result?.data?.reportName);
          dispatch({ type: BookingsTypes.LISTING_RESET });
        } else {
          dispatch({ type: BookingsTypes.DISPATCH_FEE_LIST, payload: result?.data });
        }
      }

      if (result.code !== 1) {
        dispatch({ type: BookingsTypes.LISTING_FAILED });
        if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
      }
    } catch (error) {
      dispatch({ type: BookingsTypes.LISTING_FAILED });
      enqueueSnackbar(error?.message || 'Unable to fetch data! Please refresh and try again', { variant: 'error' });
    }
  };

export const getBlownBusAction = (enqueueSnackbar) => async (dispatch) => {
  dispatch({ type: BookingsTypes.LOADING_BOOKING });
  try {
    const result = await httpRequest({
      url: 'Trips/BlownBuses?Paginated=true',
      urlType: 'bookings',
    });
    if (result.code === 1) {
      dispatch({ type: BookingsTypes.BLOWN_BUS, payload: result?.data });
    }

    if (result.code !== 1) {
      if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
      dispatch({ type: BookingsTypes.LISTING_FAILED });
    }
  } catch (error) {
    dispatch({ type: BookingsTypes.LISTING_FAILED });
    enqueueSnackbar(error?.message || 'Something went wrong, please refresh and try again!', { variant: 'error' });
  }
};
export const getCaptainRequestAction =
  (enqueueSnackbar, startDate = '', endDate = '', pageNumber = 1, pageSize = 50, paginated = false) =>
  async (dispatch) => {
    dispatch({ type: BookingsTypes.LOADING_BOOKING });
    let filteredURL = `Trips/CaptainApprovalRecords?Descending=true&CaptainSwap=1
`;
    if (startDate) filteredURL = `${filteredURL}&StartDate=${startDate}`;
    if (endDate) filteredURL = `${filteredURL}&EndDate=${endDate}`;

    if (paginated) filteredURL = `${filteredURL}&PageNumber=${pageNumber}&PageSize=${pageSize}&Paginated=${paginated}`;

    try {
      const result = await httpRequest({
        url: filteredURL,
        urlType: 'bookings',
      });

      if (result.code === 1) {
        dispatch({ type: BookingsTypes.CAPTAIN_APPROVAL_REQUEST, payload: result?.data });
      }

      if (result.code !== 1) {
        if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
        dispatch({ type: BookingsTypes.LISTING_FAILED });
      }
    } catch (error) {
      dispatch({ type: BookingsTypes.LISTING_FAILED });
      enqueueSnackbar(error?.message || 'Something went wrong, please refresh and try again!', { variant: 'error' });
    }
  };
export const getBookingChannelReportAction =
  (enqueueSnackbar, startDate = 'Jun%202024', bookingChannel = '1', pageNumber = 1, pageSize = 50, paginated = false) =>
  async (dispatch) => {
    dispatch({ type: BookingsTypes.LOADING_BOOKING });
    let filteredURL = `Report/BookingChannelSummariesReport?SeachParameter=${startDate}&BookingChannel=${bookingChannel}&Descending=true`;

    if (paginated) filteredURL = `${filteredURL}&PageNumber=${pageNumber}&PageSize=${pageSize}&Paginated=${paginated}`;

    try {
      const result = await httpRequest({
        url: filteredURL,
        urlType: 'bookings',
      });
      if (result.code === 1) {
        dispatch({ type: BookingsTypes.GET_BOOKING_CHANNEL_REPORT, payload: result?.data });
      }

      if (result.code !== 1) {
        if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
        dispatch({ type: BookingsTypes.LISTING_FAILED });
      }
    } catch (error) {
      dispatch({ type: BookingsTypes.LISTING_FAILED });
      enqueueSnackbar(error?.message || 'Something went wrong, please refresh and try again!', { variant: 'error' });
    }
  };
export const getBookingChannelCounterAction =
  (enqueueSnackbar, startDate = 'Jun%202024', bookingChannel = '1') =>
  async (dispatch) => {
    dispatch({ type: BookingsTypes.LOADING_BOOKING });

    try {
      const result = await httpRequest({
        url: `Report/BookingChannelSummaryReportCount?searchParameter=${startDate}&bookingChannel=${bookingChannel}`,
        urlType: 'bookings',
      });
      if (result.code === 1) {
        dispatch({ type: BookingsTypes.GET_BOOKING_CHANNEL_COUNTER, payload: result?.data });
      }

      if (result.code !== 1) {
        if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
        dispatch({ type: BookingsTypes.LISTING_FAILED });
      }
    } catch (error) {
      dispatch({ type: BookingsTypes.LISTING_FAILED });
      enqueueSnackbar(error?.message || 'Something went wrong, please refresh and try again!', { variant: 'error' });
    }
  };
export const getCaptainSwapAction =
  (enqueueSnackbar, pageNumber = 1, pageSize = 50, paginated = false) =>
  async (dispatch) => {
    dispatch({ type: BookingsTypes.LOADING_BOOKING });
    let filteredURL = `Trips/TripsWithPhysicalBus?Descending=true`;
    if (paginated) filteredURL = `${filteredURL}&PageNumber=${pageNumber}&PageSize=${pageSize}&Paginated=${paginated}`;

    try {
      const result = await httpRequest({
        url: filteredURL,
        urlType: 'bookings',
      });

      if (result.code === 1) {
        dispatch({ type: BookingsTypes.CAPTAIN_SWAP_REQUEST, payload: result?.data });
      }

      if (result.code !== 1) {
        if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
        dispatch({ type: BookingsTypes.LISTING_FAILED });
      }
    } catch (error) {
      dispatch({ type: BookingsTypes.LISTING_FAILED });
      enqueueSnackbar(error?.message || 'Something went wrong, please refresh and try again!', { variant: 'error' });
    }
  };

export const getBusinessPartnerAction = (enqueueSnackbar) => async (dispatch) => {
  dispatch({ type: BookingsTypes.LOADING_BOOKING });

  try {
    const result = await httpRequest({
      url: `BusinessPartner`,
      urlType: 'partners',
    });

    if (result.code === 1) {
      dispatch({ type: BookingsTypes.GET_BUSINESS_PARTNER, payload: result?.data });
    }

    if (result.code !== 1) {
      if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
      dispatch({ type: BookingsTypes.LISTING_FAILED });
    }
  } catch (error) {
    dispatch({ type: BookingsTypes.LISTING_FAILED });
    enqueueSnackbar(error?.message || 'Something went wrong, please refresh and try again!', { variant: 'error' });
  }
};
export const getBusinessPartnerBookingAction =
  (enqueueSnackbar, businessPartnerCode = '', pageNumber = 1, pageSize = 50, paginated = false) =>
  async (dispatch) => {
    dispatch({ type: BookingsTypes.LOADING_BOOKING });
    let filteredURL = `BusinessPartner/allBooking?BusinessPartnerCode=${businessPartnerCode}&Descending=true`;
    if (paginated) filteredURL = `${filteredURL}&PageNumber=${pageNumber}&PageSize=${pageSize}&Paginated=${paginated}`;

    try {
      const result = await httpRequest({
        url: filteredURL,
        urlType: 'partners',
      });

      if (result.code === 1) {
        dispatch({ type: BookingsTypes.CAPTAIN_APPROVAL_REQUEST, payload: result?.data });
      }

      if (result.code !== 1) {
        if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
        dispatch({ type: BookingsTypes.LISTING_FAILED });
      }
    } catch (error) {
      dispatch({ type: BookingsTypes.LISTING_FAILED });
      enqueueSnackbar(error?.message || 'Something went wrong, please refresh and try again!', { variant: 'error' });
    }
  };
export const getBusinessPartnerPaymentAction =
  (enqueueSnackbar, businessPartnerCode = '') =>
  async (dispatch) => {
    dispatch({ type: BookingsTypes.LOADING_BOOKING });

    try {
      const result = await httpRequest({
        url: `BusinessPartner/paymentHistory/${businessPartnerCode}`,
        urlType: 'partners',
      });

      if (result.code === 1) {
        dispatch({ type: BookingsTypes.GET_BUSINESS_PARTNER_PAYMENT, payload: result?.data });
      }

      if (result.code !== 1) {
        if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
        dispatch({ type: BookingsTypes.LISTING_FAILED });
      }
    } catch (error) {
      dispatch({ type: BookingsTypes.LISTING_FAILED });
      enqueueSnackbar(error?.message || 'Something went wrong, please refresh and try again!', { variant: 'error' });
    }
  };
export const getPartnerVehicleReportAction =
  (enqueueSnackbar, startDate = '', endDate = '', vehicleRegNo = '', paginated = false) =>
  async (dispatch) => {
    dispatch({ type: BookingsTypes.LOADING_BOOKING });
    try {
      const result = await httpRequest({
        url: `Fleet/partnerfleetfinancialvehicleIncomedetails?StartDate=${startDate}&EndDate=${endDate}&VehicleRegNo=${vehicleRegNo}&Paginated=${paginated}`,
        urlType: 'bookings',
      });

      if (result.code === 1) {
        dispatch({ type: BookingsTypes.PARTNER_FLEET_REPORT, payload: result?.data });
      }

      if (result.code !== 1) {
        if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
        dispatch({ type: BookingsTypes.LISTING_FAILED });
      }
    } catch (error) {
      dispatch({ type: BookingsTypes.LISTING_FAILED });
      enqueueSnackbar(error?.message || 'Something went wrong, please refresh and try again!', { variant: 'error' });
    }
  };

export const getPartnerVehicleRepairReportAction =
  (enqueueSnackbar, startDate = '', endDate = '', vehicleRegNo = '', paginated = false) =>
  async (dispatch) => {
    dispatch({ type: BookingsTypes.LOADING_BOOKING });
    try {
      const result = await httpRequest({
        url: `Fleet/fleetfinancialsvehiclerepairs?StartDate=${startDate}&EndDate=${endDate}&VehicleRegNo=${vehicleRegNo}&Paginated=${paginated}`,
        urlType: 'bookings',
      });

      if (result.code === 1) {
        dispatch({ type: BookingsTypes.PARTNER_FLEET_REPAIR_REPORT, payload: result?.data });
      }

      if (result.code !== 1) {
        if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
        dispatch({ type: BookingsTypes.LISTING_FAILED });
      }
    } catch (error) {
      dispatch({ type: BookingsTypes.LISTING_FAILED });
      enqueueSnackbar(error?.message || 'Something went wrong, please refresh and try again!', { variant: 'error' });
    }
  };

export const fetchAllBookingsAction =
  (enqueueSnackbar, bookingInfo = {}, pageNumber = 1, pageSize = 50) =>
  async (dispatch) => {
    dispatch({ type: BookingsTypes.FETCHING_BOOKING });
    let filteredURL = `Booking?Paginated=true&Descending=true&pageNumber=${pageNumber}&pageSize=${pageSize}`;
    if (bookingInfo?.startDate) filteredURL = `${filteredURL}&StartDate=${bookingInfo?.startDate}`;
    if (bookingInfo?.endDate) filteredURL = `${filteredURL}&EndDate=${bookingInfo?.endDate}`;
    if (bookingInfo?.refCode) filteredURL = `${filteredURL}&BookingRefCode=${bookingInfo?.refCode}`;
    if (bookingInfo?.bookingStatus || bookingInfo?.bookingStatus === 0)
      filteredURL = `${filteredURL}&BookingStatus=${bookingInfo?.bookingStatus}`;
    if (bookingInfo?.bookingType || bookingInfo?.bookingType === 0)
      filteredURL = `${filteredURL}&BookingType=${bookingInfo?.bookingType}`;
    if (bookingInfo?.phoneNo) filteredURL = `${filteredURL}&PhoneNumber=${bookingInfo?.phoneNo}`;
    if (bookingInfo?.isReport) filteredURL = `${filteredURL}&IsReport=${bookingInfo?.isReport}`;
    if (bookingInfo?.departureTerminalId)
      filteredURL = `${filteredURL}&DepartureTerminalId=${bookingInfo?.departureTerminalId}`;
    if (bookingInfo?.destinationTerminalId)
      filteredURL = `${filteredURL}&DestinationTerminalId=${bookingInfo?.destinationTerminalId}`;
    if (bookingInfo?.dateTravelled) filteredURL = `${filteredURL}&TravalledDate=${bookingInfo?.dateTravelled}`;
    try {
      const result = await httpRequest({
        url: filteredURL,
        method: 'GET',
        urlType: 'bookings',
      });

      if (result.code === 1) {
        if (bookingInfo?.isReport) {
          handleConvertByte(result?.data?.reportData, result?.data?.reportName);
          dispatch({ type: BookingsTypes.LISTING_RESET });
        } else {
          dispatch({ type: BookingsTypes.BOOKING_SUCCESS, payload: result?.data });
        }
      }

      if (result.code !== 1) {
        if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
        dispatch({ type: BookingsTypes.LISTING_FAILED });
      }
    } catch (error) {
      dispatch({ type: BookingsTypes.LISTING_FAILED });
      enqueueSnackbar(error?.message || 'Something went wrong, please refresh and try again!', { variant: 'error' });
    }
  };
export const ticketSalesRecordAction =
  (enqueueSnackbar, ticketInfo = {}, pageNumber = 1, pageSize = 50) =>
  async (dispatch) => {
    dispatch({ type: BookingsTypes.LOADING_BOOKING });
    try {
      let filteredURL = `Booking/searchticketsalesrecord?Paginated=true&Descending=true&PageNumber=${pageNumber}&PageSize=${pageSize}`;
      if (ticketInfo?.startDate) filteredURL = `${filteredURL}&StartDate=${ticketInfo?.startDate}`;
      if (ticketInfo?.endDate) filteredURL = `${filteredURL}&EndDate=${ticketInfo?.endDate}`;
      if (ticketInfo?.travelDate) filteredURL = `${filteredURL}&TravelDate=${ticketInfo?.travelDate}`;
      if (ticketInfo?.paymentMethod) filteredURL = `${filteredURL}&PaymentMethod=${ticketInfo?.paymentMethod}`;
      if (ticketInfo?.bookingType) filteredURL = `${filteredURL}&BookingType=${ticketInfo?.bookingType}`;
      if (ticketInfo?.terminalId) filteredURL = `${filteredURL}&TerminalId=${ticketInfo?.terminalId}`;
      if (ticketInfo?.isReport) filteredURL = `${filteredURL}&IsReport=${ticketInfo?.isReport}`;
      if (ticketInfo?.bookingTerminalId)
        filteredURL = `${filteredURL}&BookingTerminalId=${ticketInfo?.bookingTerminalId}`;

      const result = await httpRequest({
        url: filteredURL,
        urlType: 'bookings',
      });

      if (result.code === 1) {
        if (ticketInfo?.isReport) {
          handleConvertByte(result?.data?.reportData, result?.data?.reportName);
          dispatch({ type: BookingsTypes.LISTING_RESET });
        } else {
          dispatch({ type: BookingsTypes.TICKET_SALES_RECORDS, payload: result?.data });
        }
      }

      if (result.code !== 1) {
        if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
        dispatch({ type: BookingsTypes.LISTING_FAILED });
      }
    } catch (error) {
      dispatch({ type: BookingsTypes.LISTING_FAILED });
      enqueueSnackbar(error?.message || 'Something went wrong, please refresh and try again!', { variant: 'error' });
    }
  };
export const allTicketBookingsAction =
  (enqueueSnackbar, bookingInfo = {}, pageNumber = 1, pageSize = 50, paginated = true) =>
  async (dispatch) => {
    dispatch({ type: BookingsTypes.FETCHING_BOOKING });
    let filteredURL = `Booking/limited?Paginated=${paginated}&Descending=true&pageNumber=${pageNumber}&pageSize=${pageSize}`;
    if (bookingInfo?.startDate) filteredURL = `${filteredURL}&StartDate=${bookingInfo?.startDate}`;
    if (bookingInfo?.endDate) filteredURL = `${filteredURL}&EndDate=${bookingInfo?.endDate}`;
    if (bookingInfo?.refCode) filteredURL = `${filteredURL}&BookingRefCode=${bookingInfo?.refCode}`;
    if (bookingInfo?.bookingStatus || bookingInfo?.bookingStatus === 0)
      filteredURL = `${filteredURL}&BookingStatus=${bookingInfo?.bookingStatus}`;
    if (bookingInfo?.bookingType || bookingInfo?.bookingType === 0)
      filteredURL = `${filteredURL}&BookingType=${bookingInfo?.bookingType}`;
    if (bookingInfo?.phoneNo) filteredURL = `${filteredURL}&PhoneNumber=${bookingInfo?.phoneNo}`;
    if (bookingInfo?.countryId) filteredURL = `${filteredURL}&CountryId=${bookingInfo?.countryId}`;
    if (bookingInfo?.departureTerminalId)
      filteredURL = `${filteredURL}&DepartureTerminalId=${bookingInfo?.departureTerminalId}`;
    if (bookingInfo?.destinationTerminalId)
      filteredURL = `${filteredURL}&DestinationTerminalId=${bookingInfo?.destinationTerminalId}`;
    if (bookingInfo?.dateApproved) filteredURL = `${filteredURL}&DateApproved=${bookingInfo?.dateApproved}`;
    if (bookingInfo?.bookingTerminalId)
      filteredURL = `${filteredURL}&BookedTerminalId=${bookingInfo?.bookingTerminalId}`;
    if (bookingInfo?.dateApproved) filteredURL = `${filteredURL}&DateApproved=${bookingInfo?.dateApproved}`;
    if (bookingInfo?.isReport) filteredURL = `${filteredURL}&IsReport=${bookingInfo?.isReport}`;
    if (bookingInfo?.travalledStartDate)
      filteredURL = `${filteredURL}&TravalledStartDate=${bookingInfo?.travalledStartDate}`;
    if (bookingInfo?.travalledEndDate) filteredURL = `${filteredURL}&TravalledEndDate=${bookingInfo?.travalledEndDate}`;
    if (bookingInfo?.couponChannel || bookingInfo?.couponChannel === 0)
      filteredURL = `${filteredURL}&CouponChannel=${bookingInfo?.couponChannel}`;
    try {
      const result = await httpRequest({
        url: filteredURL,
        method: 'GET',
        urlType: 'bookings',
      });
      console.log('result', result);
      if (result.code === 1) {
        if (bookingInfo?.isReport) {
          handleConvertByte(result?.data?.reportData, result?.data?.reportName);
          dispatch({ type: BookingsTypes.LISTING_RESET });
        } else {
          dispatch({ type: BookingsTypes.BOOKING_SUCCESS, payload: result?.data });
        }
      }

      if (result.code !== 1) {
        if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
        dispatch({ type: BookingsTypes.LISTING_FAILED });
      }
    } catch (error) {
      dispatch({ type: BookingsTypes.LISTING_FAILED });
      enqueueSnackbar(error?.message || 'Something went wrong, please refresh and try again!', { variant: 'error' });
    }
  };

export const fetchMoreTicketAction = (enqueueSnackbar, refID) => async (dispatch) => {
  dispatch({ type: BookingsTypes.LOADING_MORE });
  try {
    const result = await httpRequest({
      url: `Booking/${refID}`,
      method: 'GET',
      urlType: 'bookings',
    });

    if (result.code === 1) {
      dispatch({ type: BookingsTypes.MORE_TICKET_SUCCESS, payload: result?.data });
    }

    if (result.code !== 1) {
      if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
      dispatch({ type: BookingsTypes.LISTING_FAILED });
    }
  } catch (error) {
    dispatch({ type: BookingsTypes.LISTING_FAILED });
    enqueueSnackbar(error?.message || 'Something went wrong, please refresh and try again!', { variant: 'error' });
  }
};

export const getUserBookingDetails = (enqueueSnackbar, details) => (dispatch) => {
  if (details?.id) {
    dispatch({ type: BookingsTypes.USER_BOOKING_DETAILS_SUCCESS, payload: details });
  } else {
    dispatch({ type: BookingsTypes.LISTING_FAILED });
    enqueueSnackbar('Something went wrong, please refresh and try again!', { variant: 'error' });
  }
};

export const availableSeatAction = (enqueueSnackbar, vehicleTripRegId) => async (dispatch) => {
  try {
    const result = await httpRequest({
      url: `Booking/seatsavailable/${vehicleTripRegId}`,
      method: 'GET',
      urlType: 'bookings',
    });

    if (result.code === 1) {
      dispatch({ type: BookingsTypes.SET_AVAILABLE_SEATS, payload: result?.data });
    }

    if (result.code !== 1) {
      if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
    }
  } catch (error) {
    enqueueSnackbar('Unable to fetch available seats!', { variant: 'error' });
  }
};

export const bookingChargesAction = (enqueueSnackbar, bookingRefCode, routeId) => async (dispatch) => {
  try {
    const result = await httpRequest({
      url: `Booking/NewRouteFare/${bookingRefCode}/${routeId}`,
      method: 'GET',
      urlType: 'bookings',
    });

    if (result.code === 1) {
      dispatch({ type: BookingsTypes.SET_BOOKING_CHARGES, payload: result?.data });
    }

    // if (result.code !== 1) {
    //   if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
    // }
  } catch (error) {
    enqueueSnackbar('Unable to fetch available seats!', { variant: 'error' });
  }
};

export const getTripsAction =
  (enqueueSnackbar, routeId = '', paginated = false) =>
  async (dispatch) => {
    dispatch({ type: BookingsTypes.LOADING_BOOKING });
    try {
      const result = await httpRequest({
        url: `Trips${routeId ? `?RouteId=${routeId}&Paginated=${paginated}` : ''}`,
        method: 'GET',
        urlType: 'bookings',
      });

      if (result.code === 1) {
        dispatch({ type: BookingsTypes.TRIP_LISTING_SUCCESS, payload: result?.data });
      }

      if (result.code !== 1) {
        if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
      }
    } catch (error) {
      enqueueSnackbar('Unable to fetch available seats!', { variant: 'error' });
    }
  };

export const availableTripsAction = (enqueueSnackbar, tripDetails) => async (dispatch) => {
  dispatch({ type: BookingsTypes.FETCHING_BOOKING });
  try {
    const result = await httpRequest({
      url: `Booking/availabletripdetails`,
      method: 'POST',
      urlType: 'bookings',
      body: tripDetails,
    });

    if (result.code === 1 && result.data.departures.length > 0) {
      return dispatch({ type: BookingsTypes.SET_AVAILABLE_TRIPS, payload: result?.data });
    }

    if (result.code === 1) {
      enqueueSnackbar('No result found or this filtered!', { variant: 'error' });
      dispatch({ type: BookingsTypes.SET_AVAILABLE_TRIPS, payload: result?.data?.departures });
    }

    if (result.code !== 1) {
      dispatch({ type: BookingsTypes.LISTING_FAILED });
      if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
    }
  } catch (error) {
    dispatch({ type: BookingsTypes.LISTING_FAILED });
    enqueueSnackbar('Unable to fetch available seats!', { variant: 'error' });
  }
};

export const clearBookingsInfoAction = () => async (dispatch) => {
  dispatch({ type: BookingsTypes.USER_BOOKING_INFO_CLEAR });
};

export const bookingsDetailsByPhoneAction = (enqueueSnackbar, phoneNo) => async (dispatch) => {
  try {
    const result = await httpRequest({
      url: `Booking/PhoneNumber?number=${phoneNo}`,
      urlType: 'bookings',
    });
    if (result.code === 1) {
      dispatch({ type: BookingsTypes.USER_BOOKING_INFO_SUCCESS, payload: result?.data });
      enqueueSnackbar('User Found!', { variant: 'success' });
    }
  } catch (error) {
    dispatch({ type: BookingsTypes.LISTING_FAILED });
  }
};

export const bookedVehicleAction = (enqueueSnackbar, bookedDetail) => async (dispatch) => {
  dispatch({ type: BookingsTypes.FETCHING_BOOKING });
  try {
    const result = await httpRequest({
      url: `Booking/BookedVehicles?startDate=${bookedDetail.startDate}&endDate=${bookedDetail.endDate}&DepartureTerminalId=${bookedDetail.departureTerminalId}`,
      urlType: 'bookings',
    });

    if (result.code === 1 && result?.data.length > 0) {
      return dispatch({ type: BookingsTypes.SET_BOOKED_VEHICLES, payload: result?.data });
    }

    if (result.code === 1) {
      enqueueSnackbar('No availabel details!', { variant: 'error' });
      dispatch({ type: BookingsTypes.SET_BOOKED_VEHICLES, payload: result?.data });
    }
    if (result.code !== 1) {
      dispatch({ type: BookingsTypes.LISTING_RESET });
      if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
    }
  } catch (error) {
    dispatch({ type: BookingsTypes.LISTING_RESET });
    enqueueSnackbar('Unable to fetch data! Please refresh and try again', { variant: 'error' });
  }
};

export const bookedManifestAction = (enqueueSnackbar, vehicleTripRegId) => async (dispatch) => {
  dispatch({ type: BookingsTypes.FETCHING_BOOKING });
  try {
    const result = await httpRequest({
      url: `ManifestManagement/PassengerManifest/${vehicleTripRegId}`,
      urlType: 'bookings',
    });

    if (result.code === 1) {
      dispatch({ type: BookingsTypes.SET_BOOKED_MANIFEST, payload: result?.data });
    }

    if (result.code !== 1) {
      dispatch({ type: BookingsTypes.LISTING_RESET });
      if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
    }
  } catch (error) {
    dispatch({ type: BookingsTypes.LISTING_RESET });
    enqueueSnackbar('Unable to fetch data! Please refresh and try again', { variant: 'error' });
  }
};
export const ebmAction = (enqueueSnackbar) => async (dispatch) => {
  dispatch({ type: BookingsTypes.FETCHING_BOOKING });
  try {
    const result = await httpRequest({
      url: `Accounts/ebms`,
    });

    if (result.code === 1) {
      dispatch({ type: BookingsTypes.GET_EBM, payload: result?.data });
    }
    if (result.code !== 1) {
      dispatch({ type: BookingsTypes.LISTING_RESET });
      if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
    }
  } catch (error) {
    dispatch({ type: BookingsTypes.LISTING_RESET });
    enqueueSnackbar(error?.message || 'Something went wrong, please refresh and try again!', { variant: 'error' });
  }
};

export const tripsWithPhysicalBusAction =
  (enqueueSnackbar, routeId = '', paginated = false) =>
  async (dispatch) => {
    dispatch({ type: BookingsTypes.FETCHING_BOOKING });
    try {
      const result = await httpRequest({
        url: `Trips/TripsWithPhysicalBus${routeId ? `?RouteId=${routeId}&Paginated=${paginated}` : ''}`,
        urlType: 'bookings',
      });

      if (result.code === 1) {
        dispatch({ type: BookingsTypes.GET_TRIP_WITH_PHISICAL_BUS, payload: result?.data });
      }

      if (result.code !== 1) {
        dispatch({ type: BookingsTypes.LISTING_RESET });
        if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
      }
    } catch (error) {
      dispatch({ type: BookingsTypes.LISTING_RESET });
      enqueueSnackbar('Unable to fetch data! Please refresh and try again', { variant: 'error' });
    }
  };
export const todayTripsWithPhysicalBusAction =
  (enqueueSnackbar, terminalId = '') =>
  async (dispatch) => {
    dispatch({ type: BookingsTypes.FETCHING_BOOKING });
    try {
      const result = await httpRequest({
        url: `Trips/TripsWithPhysicalBus${terminalId ? `?TerminalId=${terminalId}` : ''}&Paginated=false`,
        urlType: 'bookings',
      });

      if (result.code === 1) {
        dispatch({ type: BookingsTypes.GET_TODAY_TRIP_WITH_PHISICAL_BUS, payload: result?.data });
      }

      if (result.code !== 1) {
        dispatch({ type: BookingsTypes.LISTING_RESET });
        if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
      }
    } catch (error) {
      dispatch({ type: BookingsTypes.LISTING_RESET });
      enqueueSnackbar('Unable to fetch data! Please refresh and try again', { variant: 'error' });
    }
  };

export const getFareListAction =
  (
    enqueueSnackbar,
    routeId = '',
    vehicleModelId = '',
    paginated = false,
    pageNumber = 1,
    pageSize = 50,
    isReport = false
  ) =>
  async (dispatch) => {
    dispatch({ type: BookingsTypes.FETCHING_BOOKING });
    try {
      let filteredURL = `Fares?Descending=true&Paginated=${paginated}`;
      if (routeId) filteredURL = `${filteredURL}&RouteId=${routeId}`;
      if (vehicleModelId) filteredURL = `${filteredURL}&vehicleModelId=${vehicleModelId}`;
      if (paginated) filteredURL = `${filteredURL}&pageNumber=${pageNumber}&pageSize=${pageSize}`;

      const result = await httpRequest({
        url: filteredURL,
        urlType: 'bookings',
        method: 'GET',
      });

      if (result.code === 1) {
        dispatch({ type: BookingsTypes.GET_ALL_FARES, payload: result?.data });
      }

      if (result.code !== 1) {
        dispatch({ type: BookingsTypes.LISTING_RESET });
        if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
      }
    } catch (error) {
      dispatch({ type: BookingsTypes.LISTING_RESET });
      enqueueSnackbar('Unable to fetch data! Please refresh and try again', { variant: 'error' });
    }
  };

export const searchHireServiceBookingAction =
  (enqueueSnackbar, hireInfo = {}) =>
  async (dispatch) => {
    dispatch({ type: BookingsTypes.FETCHING_BOOKING });
    try {
      let filteredURL = `HireBooking/bookingSearch?Descending=true&Paginated=true`;
      if (hireInfo?.hiredServiceType) filteredURL = `${filteredURL}&HiredServiceType=${hireInfo?.hiredServiceType}`;
      if (hireInfo?.onewayPickupDate) filteredURL = `${filteredURL}&OnewayPickupDate=${hireInfo?.onewayPickupDate}`;
      if (hireInfo?.onewayDistanceApart)
        filteredURL = `${filteredURL}&OnewayDistanceApart=${hireInfo?.onewayDistanceApart}`;
      if (hireInfo?.onewayPickupLocation)
        filteredURL = `${filteredURL}&OnewayPickupLocation=${hireInfo?.onewayPickupLocation}`;
      if (hireInfo?.oneWayDropoffLocation)
        filteredURL = `${filteredURL}&OneWayDropoffLocation=${hireInfo?.oneWayDropoffLocation}`;
      if (hireInfo?.isSleepOver) filteredURL = `${filteredURL}&IsSleepOver=${hireInfo?.isSleepOver}`;
      if (hireInfo?.returnPickupDate) filteredURL = `${filteredURL}&ReturnPickupDate=${hireInfo?.returnPickupDate}`;

      const result = await httpRequest({
        url: filteredURL,
        urlType: 'bookings',
        method: 'POST',
      });

      if (result.code === 1) {
        dispatch({ type: BookingsTypes.SEARCH_HIRE_BOOKINGS, payload: result?.data });
      }

      if (result.code !== 1) {
        dispatch({ type: BookingsTypes.LISTING_RESET });
        if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
      }
    } catch (error) {
      dispatch({ type: BookingsTypes.LISTING_RESET });
      enqueueSnackbar('Unable to fetch data! Please refresh and try again', { variant: 'error' });
    }
  };

export const getFareByRouteIdAction = (enqueueSnackbar, routeId, vehicleModelId) => async (dispatch) => {
  dispatch({ type: BookingsTypes.FETCHING_BOOKING });
  try {
    const result = await httpRequest({
      url: `Fares/${routeId}/${vehicleModelId}`,
      urlType: 'bookings',
    });

    if (result.code === 1) {
      dispatch({ type: BookingsTypes.GET_ALL_FARES_AMOUNT, payload: result?.data });
    }

    if (result.code !== 1) {
      dispatch({ type: BookingsTypes.LISTING_RESET });
      if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
    }
  } catch (error) {
    dispatch({ type: BookingsTypes.LISTING_RESET });
    enqueueSnackbar('Unable to fetch data! Please refresh and try again', { variant: 'error' });
  }
};

export const getPrintedTicketAction = (enqueueSnackbar, seatManagementId, vehicleTripRegId) => async (dispatch) => {
  dispatch({ type: BookingsTypes.FETCHING_BOOKING });
  try {
    const result = await httpRequest({
      url: `SeatManagement/printticket/${seatManagementId}`,
      urlType: 'bookings',
    });

    if (result.code === 1) {
      dispatch(bookedManifestAction(enqueueSnackbar, vehicleTripRegId));
    }

    if (result.code !== 1) {
      dispatch({ type: BookingsTypes.LISTING_RESET });
      if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
    }
  } catch (error) {
    dispatch({ type: BookingsTypes.LISTING_RESET });
    enqueueSnackbar('Unable to fetch data! Please refresh and try again', { variant: 'error' });
  }
};

export const getSalesDetailsAction = (enqueueSnackbar, vehicleTripRegId) => async (dispatch) => {
  try {
    const result = await httpRequest({
      url: `SeatManagement/gettotalsell/${vehicleTripRegId}`,
      urlType: 'bookings',
    });

    if (result.code === 1) {
      dispatch({ type: BookingsTypes.SALES_DETAILS_SUCCESS, payload: result?.data });
    }
  } catch (error) {
    enqueueSnackbar('Unable to fetch data! Please refresh and try again', { variant: 'error' });
  }
};

export const manifestPrintAction = (enqueueSnackbar, vehicleTripRegId) => async (dispatch) => {
  try {
    dispatch({ type: BookingsTypes.LOADING_BOOKING });
    const result = await httpRequest({
      url: `ManifestManagement/manifestPrint/${vehicleTripRegId}`,
      urlType: 'bookings',
    });

    if (result.code === 1) {
      dispatch({ type: BookingsTypes.MANIFEST_PRINT, payload: result?.data });
      dispatch(bookedManifestAction(enqueueSnackbar, vehicleTripRegId));
    }
    if (result.code !== 1 && result.shortDescription) {
      enqueueSnackbar(result.shortDescription, { variant: 'error' });
      dispatch({ type: BookingsTypes.LISTING_RESET });
    }
  } catch (error) {
    dispatch({ type: BookingsTypes.LISTING_RESET });
    enqueueSnackbar('Unable to fetch data! Please refresh and try again', { variant: 'error' });
  }
};

export const pendingHireRequestsAction =
  (enqueueSnackbar, pageNumber = 1, pageSize = 50) =>
  async (dispatch) => {
    dispatch({ type: BookingsTypes.FETCHING_BOOKING });
    try {
      const result = await httpRequest({
        url: `HireServiceVehicleRegistration/pendingHireRequests?Descending=true&Paginated=true&pageNumber=${pageNumber}&pageSize=${pageSize}`,
        method: 'GET',
        urlType: 'bookings',
      });

      if (result.code === 1) {
        dispatch({ type: BookingsTypes.GET_PENDING_HIRE_REQUEST, payload: result?.data });
      }

      if (result.code !== 1) {
        if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
        dispatch({ type: BookingsTypes.LISTING_FAILED });
      }
    } catch (error) {
      dispatch({ type: BookingsTypes.LISTING_FAILED });
      enqueueSnackbar(error?.message || 'Something went wrong, please refresh and try again!', { variant: 'error' });
    }
  };

export const pendingHireDetailsAction = (enqueueSnackbar, id) => async (dispatch) => {
  dispatch({ type: BookingsTypes.FETCHING_BOOKING });
  try {
    const result = await httpRequest({
      url: `HireServiceVehicleRegistration/pendingHireDetails?HiredServiceBookingId=${id}&Descending=true&Paginated=true`,
      method: 'GET',
      urlType: 'bookings',
    });

    if (result.code === 1) {
      dispatch({ type: BookingsTypes.GET_PENDING_HIRE_DETAILS, payload: result?.data });
    }

    if (result.code !== 1) {
      if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
      dispatch({ type: BookingsTypes.LISTING_FAILED });
    }
  } catch (error) {
    dispatch({ type: BookingsTypes.LISTING_FAILED });
    enqueueSnackbar(error?.message || 'Something went wrong, please refresh and try again!', { variant: 'error' });
  }
};

export const getHiredVehiclesAction = (enqueueSnackbar, id) => async (dispatch) => {
  dispatch({ type: BookingsTypes.FETCHING_BOOKING });
  try {
    const result = await httpRequest({
      url: `HireServiceVehicleRegistration/hiredVehicles?Descending=true&Paginated=true`,
      method: 'GET',
      urlType: 'bookings',
    });

    if (result.code === 1) {
      dispatch({ type: BookingsTypes.GET_HIRED_VEHICLES, payload: result?.data });
    }

    if (result.code !== 1) {
      if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
      dispatch({ type: BookingsTypes.LISTING_FAILED });
    }
  } catch (error) {
    dispatch({ type: BookingsTypes.LISTING_FAILED });
    enqueueSnackbar(error?.message || 'Something went wrong, please refresh and try again!', { variant: 'error' });
  }
};

export const getHireServiceReportAction =
  (enqueueSnackbar, startDate = '', endDate = '', paginated = true, pageNumber = 1, pageSize = 50) =>
  async (dispatch) => {
    dispatch({ type: BookingsTypes.FETCHING_BOOKING });
    try {
      let filteredURL = `HireBooking/report?Descending=true&Paginated=${paginated}`;
      if (startDate) filteredURL = `${filteredURL}&StartDate=${startDate}`;
      if (endDate) filteredURL = `${filteredURL}&endDate=${endDate}`;
      if (paginated) filteredURL = `${filteredURL}&pageNumber=${pageNumber}&pageSize=${pageSize}`;

      const result = await httpRequest({
        url: filteredURL,
        method: 'GET',
        urlType: 'bookings',
      });

      if (result.code === 1) {
        dispatch({ type: BookingsTypes.GET_HIRE_SERVICE_REPORT, payload: result?.data });
      }

      if (result.code !== 1) {
        if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });

        dispatch({ type: BookingsTypes.LISTING_FAILED });
      }
    } catch (error) {
      dispatch({ type: BookingsTypes.LISTING_FAILED });
      enqueueSnackbar(error?.message || 'Something went wrong, please refresh and try again!', { variant: 'error' });
    }
  };

export const getFareCalender =
  (enqueueSnackbar, routeId = '', fareType = '', startDate = '', endDate = '') =>
  async (dispatch) => {
    dispatch({ type: BookingsTypes.LOADING_BOOKING });

    let filteredURL = `FareCalender?Paginated=false`;
    if (routeId) filteredURL = `${filteredURL}&RouteId=${routeId}`;
    if (fareType) filteredURL = `${filteredURL}&FareType=${fareType}`;
    if (startDate) filteredURL = `${filteredURL}&StartDate=${startDate}`;
    if (endDate) filteredURL = `${filteredURL}&EndDate=${endDate}`;

    try {
      const result = await httpRequest({
        url: filteredURL,
        urlType: 'bookings',
      });
      if (result.code === 1) {
        dispatch({ type: BookingsTypes.FARE_CALENDER, payload: result?.data });
      }

      if (result.code !== 1) {
        dispatch({ type: BookingsTypes.LISTING_RESET });
        if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
      }
    } catch (error) {
      dispatch({ type: BookingsTypes.LISTING_FAILED });
      enqueueSnackbar(error?.message || 'Something went wrong, please refresh and try again!', { variant: 'error' });
    }
  };

export const getFleetFinancialDetailAction =
  (enqueueSnackbar, startDate = '', endDate = '', partnerId = '', paginated = true, pageNumber = 1, pageSize = 50) =>
  async (dispatch) => {
    dispatch({ type: BookingsTypes.LOADING_BOOKING });

    let filteredURL = `Fleet/allpartnerfleetfinancialvehicleIncomedetails?Descending=true`;
    if (startDate) filteredURL = `${filteredURL}&StartDate=${startDate}`;
    if (endDate) filteredURL = `${filteredURL}&EndDate=${endDate}`;
    if (partnerId) filteredURL = `${filteredURL}&PartnerId=${partnerId}`;
    if (paginated) filteredURL = `${filteredURL}&PageNumber=${pageNumber}&PageSize=${pageSize}&Paginated=${paginated}`;

    try {
      const result = await httpRequest({
        url: filteredURL,
        urlType: 'bookings',
      });
      if (result.code === 1) {
        dispatch({ type: BookingsTypes.ALL_FLEET_FINANCIAL_DETAIL, payload: result?.data });
      }

      if (result.code !== 1) {
        dispatch({ type: BookingsTypes.LISTING_RESET });
        if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
      }
    } catch (error) {
      dispatch({ type: BookingsTypes.LISTING_FAILED });
      enqueueSnackbar(error?.message || 'Something went wrong, please refresh and try again!', { variant: 'error' });
    }
  };
export const getPartnerFleetFinancialAction =
  (enqueueSnackbar, startDate = '', endDate = '', partnerId = '', paginated = true, pageNumber = 1, pageSize = 50) =>
  async (dispatch) => {
    dispatch({ type: BookingsTypes.LOADING_BOOKING });

    let filteredURL = `Fleet/partnerEbmfinancials?Descending=true`;
    if (startDate) filteredURL = `${filteredURL}&StartJourneyDate=${startDate}`;
    if (endDate) filteredURL = `${filteredURL}&EndJourneyDate=${endDate}`;
    if (partnerId) filteredURL = `${filteredURL}&PartnerId=${partnerId}`;
    if (paginated) filteredURL = `${filteredURL}&PageNumber=${pageNumber}&PageSize=${pageSize}&Paginated=${paginated}`;

    try {
      const result = await httpRequest({
        url: filteredURL,
        urlType: 'bookings',
      });
      if (result.code === 1) {
        dispatch({ type: BookingsTypes.PARTNER_FLEET_FINANCIAL, payload: result?.data });
      }

      if (result.code !== 1) {
        dispatch({ type: BookingsTypes.LISTING_RESET });
        if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
      }
    } catch (error) {
      dispatch({ type: BookingsTypes.LISTING_FAILED });
      enqueueSnackbar(error?.message || 'Something went wrong, please refresh and try again!', { variant: 'error' });
    }
  };

export const getFleetUsageReportAction =
  (enqueueSnackbar, startDate = '', endDate = '', ebm = '') =>
  async (dispatch) => {
    dispatch({ type: BookingsTypes.LOADING_BOOKING });

    // let filteredURL = `Fleet/fleetusagereport?Descending=false&Paginated=true`;
    let filteredURL = `Fleet/fleetusagereport?`;
    if (startDate) filteredURL = `${filteredURL}StartJourneyDate=${startDate}`;
    if (endDate) filteredURL = `${filteredURL}&EndJourneyDate=${endDate}`;
    if (ebm) filteredURL = `${filteredURL}&Ebm=${ebm}`;

    try {
      const result = await httpRequest({
        url: filteredURL,
        urlType: 'bookings',
      });

      if (result.code === 1) {
        dispatch({ type: BookingsTypes.FLEET_USAGE_REPORT, payload: result?.data });
      }

      if (result.code !== 1) {
        dispatch({ type: BookingsTypes.LISTING_RESET });
        if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
      }
    } catch (error) {
      dispatch({ type: BookingsTypes.LISTING_FAILED });
      enqueueSnackbar(error?.message || 'Something went wrong, please refresh and try again!', { variant: 'error' });
    }
  };

export const getFleetHistoryAction =
  (
    enqueueSnackbar,
    startDate = '',
    endDate = '',
    vehicleId = '',
    captainCode = '',
    isReport = false,
    paginated = true,
    pageNumber = 1,
    pageSize = 50
  ) =>
  async (dispatch) => {
    dispatch({ type: BookingsTypes.LOADING_BOOKING });

    let filteredURL = `Fleet/history?Descending=true`;

    if (startDate) filteredURL = `${filteredURL}&StartDate=${startDate}`;
    if (endDate) filteredURL = `${filteredURL}&EndDate=${endDate}`;
    if (vehicleId) filteredURL = `${filteredURL}&VehicleId=${vehicleId}`;
    if (captainCode) filteredURL = `${filteredURL}&CaptainCode=${captainCode}`;
    if (paginated) filteredURL = `${filteredURL}&PageNumber=${pageNumber}&PageSize=${pageSize}&Paginated=${paginated}`;
    if (isReport) filteredURL = `${filteredURL}&IsReport=${isReport}`;

    try {
      const result = await httpRequest({
        url: filteredURL,
        urlType: 'bookings',
      });

      if (result.code === 1) {
        if (isReport) {
          handleConvertByte(result?.data?.reportData, result?.data?.reportName);
          dispatch({ type: BookingsTypes.LISTING_RESET });
        } else {
          dispatch({ type: BookingsTypes.FLEET_HISTORY, payload: result?.data });
        }
      }

      if (result.code !== 1) {
        dispatch({ type: BookingsTypes.LISTING_RESET });
        if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
      }
    } catch (error) {
      dispatch({ type: BookingsTypes.LISTING_FAILED });
      enqueueSnackbar(error?.message || 'Something went wrong, please refresh and try again!', { variant: 'error' });
    }
  };
export const getDepartureReportAction =
  (
    enqueueSnackbar,
    startDate = '',
    endDate = '',
    terminalId = '',
    delayStatus = '',
    regionalId = '',
    isReport = false,
    paginated = true,
    pageNumber = 1,
    pageSize = 50
  ) =>
  async (dispatch) => {
    dispatch({ type: BookingsTypes.LOADING_BOOKING });

    let filteredURL = `JourneyManagement/DepartureReport?Descending=true`;

    if (startDate) filteredURL = `${filteredURL}&StartDate=${startDate}`;
    if (endDate) filteredURL = `${filteredURL}&EndDate=${endDate}`;
    if (terminalId) filteredURL = `${filteredURL}&TerminalId=${terminalId}`;
    if (delayStatus) filteredURL = `${filteredURL}&isDelayed=${delayStatus}`;
    if (regionalId) filteredURL = `${filteredURL}&RegionalId=${regionalId}`;
    if (paginated) filteredURL = `${filteredURL}&PageNumber=${pageNumber}&PageSize=${pageSize}&Paginated=${paginated}`;
    if (isReport) filteredURL = `${filteredURL}&IsReport=${isReport}`;

    try {
      const result = await httpRequest({
        url: filteredURL,
        urlType: 'bookings',
      });

      if (result.code === 1) {
        if (isReport) {
          handleConvertByte(result?.data?.reportData, result?.data?.reportName);
          dispatch({ type: BookingsTypes.LISTING_RESET });
        } else {
          dispatch({ type: BookingsTypes.GET_DEPARTURE_REPORT, payload: result?.data });
        }
      }

      if (result.code !== 1) {
        dispatch({ type: BookingsTypes.LISTING_RESET });
        if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
      }
    } catch (error) {
      dispatch({ type: BookingsTypes.LISTING_FAILED });
      enqueueSnackbar(error?.message || 'Something went wrong, please refresh and try again!', { variant: 'error' });
    }
  };

export const getOpsFleetSummaryByTerminalAction =
  (enqueueSnackbar, regionId = 1, paginated = false) =>
  async (dispatch) => {
    dispatch({ type: BookingsTypes.LOADING_BOOKING });

    try {
      const result = await httpRequest({
        url: `Fleet/regionalterminalfleetusagereport?StartJourneyDate=2023-06-22&EndJourneyDate=2023-06-29&RegionId=${regionId}&Paginated=${paginated}`,

        urlType: 'bookings',
      });
      if (result.code === 1) {
        dispatch({ type: BookingsTypes.OPS_FLEET_TERMINAL_SUMMARY, payload: result?.data });
      }

      if (result.code !== 1) {
        dispatch({ type: BookingsTypes.LISTING_RESET });
        if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
      }
    } catch (error) {
      dispatch({ type: BookingsTypes.LISTING_FAILED });
      enqueueSnackbar(error?.message || 'Something went wrong, please refresh and try again!', { variant: 'error' });
    }
  };
export const getOpsFleetSummaryAction =
  (enqueueSnackbar, countryCode = '', paginated = false) =>
  async (dispatch) => {
    dispatch({ type: BookingsTypes.LOADING_BOOKING });
    let filteredURL = `Fleet/regionalfleetusagereport?StartJourneyDate=2023-06-22&EndJourneyDate=2023-06-29&Paginated=${paginated}&CountryCode=${countryCode}`;
    if (countryCode === '') {
      filteredURL = `${filteredURL}NG`;
    } else {
      filteredURL = `${filteredURL}/${countryCode}`;
    }

    try {
      const result = await httpRequest({
        url: filteredURL,
        urlType: 'bookings',
      });
      if (result.code === 1) {
        dispatch({ type: BookingsTypes.OPS_FLEET_SUMMARY, payload: result?.data });
      }

      if (result.code !== 1) {
        dispatch({ type: BookingsTypes.LISTING_RESET });
        if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
      }
    } catch (error) {
      dispatch({ type: BookingsTypes.LISTING_FAILED });
      enqueueSnackbar(error?.message || 'Something went wrong, please refresh and try again!', { variant: 'error' });
    }
  };

export const getFleetMovementChartAction =
  (enqueueSnackbar, countryCode = '') =>
  async (dispatch) => {
    dispatch({ type: BookingsTypes.LOADING_BOOKING });
    let filteredURL = `Fleet`;
    if (countryCode === '') {
      filteredURL = `${filteredURL}/ALL`;
    } else {
      filteredURL = `${filteredURL}/${countryCode}`;
    }

    try {
      const result = await httpRequest({
        url: filteredURL,
        urlType: 'bookings',
      });

      if (result.code === 1) {
        dispatch({ type: BookingsTypes.FLEET_BY_COUNTRY, payload: result?.data });
      }

      if (result.code !== 1) {
        dispatch({ type: BookingsTypes.LISTING_RESET });
        if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
      }
    } catch (error) {
      dispatch({ type: BookingsTypes.LISTING_FAILED });
      enqueueSnackbar(error?.message || 'Something went wrong, please refresh and try again!', { variant: 'error' });
    }
  };

export const approveHireVehicle = (enqueueSnackbar, refCode, handleCloseModal) => async (dispatch) => {
  dispatch({ type: BookingsTypes.LOADING_BOOKING });
  try {
    const result = await httpRequest({
      url: `HireBooking/processHireServiceOnHoldPayment/${refCode}`,
      method: 'PUT',
      urlType: 'bookings',
    });

    if (result.code === 1) {
      dispatch(getHireServiceReportAction(enqueueSnackbar));
      if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'success' });
      dispatch({ type: BookingsTypes.LISTING_FAILED });
      handleCloseModal();
    }

    if (result.code !== 1) {
      if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
      dispatch({ type: BookingsTypes.LISTING_FAILED });
    }
  } catch (error) {
    dispatch({ type: BookingsTypes.LISTING_FAILED });
    enqueueSnackbar(error?.message || 'Something went wrong, please refresh and try again!', { variant: 'error' });
  }
};

export const receiveHireVehicle = (enqueueSnackbar, refCode, handleCloseModal) => async (dispatch) => {
  dispatch({ type: BookingsTypes.LOADING_BOOKING });
  try {
    const result = await httpRequest({
      url: `HireBooking/receiveHireService/${refCode}`,
      method: 'PUT',
      urlType: 'bookings',
    });

    if (result.code === 1) {
      if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'success' });
      dispatch({ type: BookingsTypes.LISTING_FAILED });
      handleCloseModal();
    }

    if (result.code !== 1) {
      if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
      dispatch({ type: BookingsTypes.LISTING_FAILED });
    }
  } catch (error) {
    dispatch({ type: BookingsTypes.LISTING_FAILED });
    enqueueSnackbar(error?.message || 'Something went wrong, please refresh and try again!', { variant: 'error' });
  }
};

export const getExtraDispatchAction =
  (enqueueSnackbar, paginated = false) =>
  async (dispatch) => {
    dispatch({ type: BookingsTypes.LOADING_BOOKING });
    try {
      const result = await httpRequest({
        url: `JourneyManagement?Descending=false&Paginated=${paginated}`,
        method: 'GET',
        urlType: 'bookings',
      });
      if (result.code === 1) {
        dispatch({ type: BookingsTypes.EXTRA_DISPATCH, payload: result?.data });
      }

      if (result.code !== 1) {
        if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
        dispatch({ type: BookingsTypes.LISTING_FAILED });
      }
    } catch (error) {
      dispatch({ type: BookingsTypes.LISTING_FAILED });
      enqueueSnackbar(error?.message || 'Something went wrong, please refresh and try again!', { variant: 'error' });
    }
  };

export const outGoingJourneyAction =
  (enqueueSnackbar, pageNumber = 1, pageSize = 50, paginated = false) =>
  async (dispatch) => {
    dispatch({ type: BookingsTypes.LOADING_BOOKING });

    let filteredURL = `JourneyManagement/OutGoing?Descending=true&Paginated=${paginated}`;
    if (paginated) filteredURL = `${filteredURL}&PageNumber=${pageNumber}&PageSize=${pageSize}`;

    try {
      const result = await httpRequest({
        url: filteredURL,
        method: 'GET',
        urlType: 'bookings',
      });

      if (result.code === 1) {
        dispatch({ type: BookingsTypes.OUTGOING_JOURNEY, payload: result?.data });
      }

      if (result.code !== 1) {
        if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
        dispatch({ type: BookingsTypes.LISTING_FAILED });
      }
    } catch (error) {
      dispatch({ type: BookingsTypes.LISTING_FAILED });
      enqueueSnackbar(error?.message || 'Something went wrong, please refresh and try again!', { variant: 'error' });
    }
  };

export const incomingJourneyAction =
  (enqueueSnackbar, pageNumber = 1, pageSize = 50, paginated = false) =>
  async (dispatch) => {
    dispatch({ type: BookingsTypes.LOADING_BOOKING });
    let filteredURL = `JourneyManagement/Incoming?JourneyType=1000&Descending=true&Paginated=${paginated}`;
    if (paginated) filteredURL = `${filteredURL}&PageNumber=${pageNumber}&PageSize=${pageSize}`;

    try {
      const result = await httpRequest({
        url: filteredURL,
        method: 'GET',
        urlType: 'bookings',
      });

      if (result.code === 1) {
        dispatch({ type: BookingsTypes.OUTGOING_JOURNEY, payload: result?.data });
      }

      if (result.code !== 1) {
        if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
        dispatch({ type: BookingsTypes.LISTING_FAILED });
      }
    } catch (error) {
      dispatch({ type: BookingsTypes.LISTING_FAILED });
      enqueueSnackbar(error?.message || 'Something went wrong, please refresh and try again!', { variant: 'error' });
    }
  };

export const dailyJourneyAction =
  (enqueueSnackbar, isFilter = false, startDate = '', endDate = '') =>
  async (dispatch) => {
    dispatch({ type: BookingsTypes.LOADING_BOOKING });

    try {
      const result = await httpRequest({
        url: isFilter
          ? `JourneyManagement/SearchJourney?StartDate=${startDate}&EndDate=${endDate}&Descending=false`
          : // : `JourneyManagement/GetDailyJourneyTrip`,
            `JourneyManagement/GetDailyJourneyTrip?Descending=false`,
        method: 'GET',
        urlType: 'bookings',
      });

      if (result.code === 1) {
        dispatch({ type: BookingsTypes.DAILY_JOURNEY, payload: result?.data });
      }

      if (result.code !== 1) {
        if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
        dispatch({ type: BookingsTypes.LISTING_FAILED });
      }
    } catch (error) {
      dispatch({ type: BookingsTypes.LISTING_FAILED });
      enqueueSnackbar(error?.message || 'Something went wrong, please refresh and try again!', { variant: 'error' });
    }
  };

export const sharedRevenueAction =
  (enqueueSnackbar, startDate = '', endDate = '', paginated = true, pageNumber = 1, pageSize = 50) =>
  async (dispatch) => {
    dispatch({ type: BookingsTypes.LOADING_BOOKING });
    let filteredURL = `JourneyManagement/allSharedRevenue?Descending=false&Paginated=${paginated}&PageNumber=${pageNumber}&PageSize=${pageSize}`;
    if (startDate) filteredURL = `${filteredURL}&StartDate=${startDate}`;
    if (endDate) filteredURL = `${filteredURL}&EndDate=${endDate}`;
    try {
      const result = await httpRequest({
        url: filteredURL,
        method: 'GET',
        urlType: 'bookings',
      });

      if (result.code === 1) {
        dispatch({ type: BookingsTypes.SHARED_JOURNEY_SUCCESS, payload: result?.data });
      }

      if (result.code !== 1) {
        if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
        dispatch({ type: BookingsTypes.LISTING_FAILED });
      }
    } catch (error) {
      dispatch({ type: BookingsTypes.LISTING_FAILED });
      enqueueSnackbar(error?.message || 'Something went wrong, please refresh and try again!', { variant: 'error' });
    }
  };

export const startJourneyAction = (enqueueSnackbar, vehMgtId) => async (dispatch) => {
  dispatch({ type: BookingsTypes.LOADING_MORE });
  try {
    const result = await httpRequest({
      url: `JourneyManagement/StartJourney/${vehMgtId}`,
      method: 'PUT',
      urlType: 'bookings',
    });

    if (result.code === 1) {
      dispatch(modalCloseAction());
      dispatch(outGoingJourneyAction(enqueueSnackbar));
      if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'success' });
      dispatch({ type: BookingsTypes.LISTING_RESET });
    }

    if (result.code !== 1) {
      if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
      dispatch({ type: BookingsTypes.LISTING_RESET });
    }
  } catch (error) {
    dispatch({ type: BookingsTypes.LISTING_FAILED });
    enqueueSnackbar(error?.message || 'Something went wrong, please refresh and try again!', { variant: 'error' });
  }
};

export const extraDispatchAction = (enqueueSnackbar, details) => async (dispatch) => {
  try {
    const result = await httpRequest({
      url: `JourneyManagement`,
      method: 'POST',
      urlType: 'bookings',
      body: details,
    });

    if (result.code !== 1) {
      if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
      dispatch({ type: BookingsTypes.LISTING_RESET });
    }
  } catch (error) {
    dispatch({ type: BookingsTypes.LISTING_FAILED });
    enqueueSnackbar(error?.message || 'Something went wrong, please refresh and try again!', { variant: 'error' });
  }
};

export const manageBusInfoAction = (enqueueSnackbar, journeymgtId, vehTripRegId) => async (dispatch) => {
  dispatch({ type: BookingsTypes.LOADING_BOOKING });
  try {
    const result = await httpRequest({
      url: `JourneyManagement/manage?journeymanagementId=${journeymgtId}&vehicleTripRegId=${vehTripRegId}`,
      method: 'GET',
      urlType: 'bookings',
    });

    if (result.code === 1) {
      dispatch({ type: BookingsTypes.MANAGE_BUS_INFO, payload: result?.data });
    }

    if (result.code !== 1) {
      if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
      dispatch({ type: BookingsTypes.LISTING_FAILED });
    }
  } catch (error) {
    dispatch({ type: BookingsTypes.LISTING_FAILED });
    enqueueSnackbar(error?.message || 'Something went wrong, please refresh and try again!', { variant: 'error' });
  }
};

export const receiveJourneyAction = (enqueueSnackbar, journeymanagementId) => async (dispatch) => {
  dispatch({ type: BookingsTypes.FETCHING_BOOKING });
  try {
    const result = await httpRequest({
      url: `JourneyManagement/receiveJourney/${journeymanagementId}`,
      method: 'PUT',
      urlType: 'bookings',
    });

    if (result.code === 1) {
      dispatch(incomingJourneyAction(enqueueSnackbar));
      if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'success' });
      dispatch({ type: BookingsTypes.LISTING_FAILED });
    }

    if (result.code !== 1) {
      if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
      dispatch({ type: BookingsTypes.LISTING_FAILED });
    }
  } catch (error) {
    dispatch({ type: BookingsTypes.LISTING_FAILED });
    enqueueSnackbar(error?.message || 'Something went wrong, please refresh and try again!', { variant: 'error' });
  }
};

export const combinedBusSearchAction =
  (enqueueSnackbar, startDate = '', endDate = '', terminalId = '') =>
  async (dispatch) => {
    let filterUrl = `Report/combinebussearch?Descending=true&Paginated=true`;
    if (startDate) filterUrl = `${filterUrl}&StartDate=${startDate}`;
    if (endDate) filterUrl = `${filterUrl}&endDate=${endDate}`;
    if (terminalId) filterUrl = `${filterUrl}&TerminalId=${terminalId}`;
    dispatch({ type: BookingsTypes.LOADING_BOOKING });
    try {
      const result = await httpRequest({
        url: filterUrl,
        urlType: 'bookings',
      });

      if (result.code === 1) {
        if (result.data?.userData?.length === 0) {
          if (startDate || endDate || terminalId) {
            enqueueSnackbar('No Record Found for this search!', { variant: 'error' });
          }
        }
        dispatch({ type: BookingsTypes.COMBINED_BUS_SEARCH, payload: result?.data });
      }

      if (result.code !== 1) {
        if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
        dispatch({ type: BookingsTypes.LISTING_FAILED });
      }
    } catch (error) {
      dispatch({ type: BookingsTypes.LISTING_FAILED });
      enqueueSnackbar(error?.message || 'Something went wrong, please refresh and try again!', { variant: 'error' });
    }
  };
