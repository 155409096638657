import * as AuthTypes from './authTypes';

const initalState = {
  isAuthenticated: false,
  loading: false,
  error: '',
  user: null,
  notAuthMsg: '',
};

// eslint-disable-next-line
const authReducer = (state = initalState, action) => {
  switch (action.type) {
    case AuthTypes.LOGIN_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        error: '',
        user: action.payload,
      };
    case AuthTypes.LOGGING_IN:
      return {
        ...state,
        isAuthenticated: false,
        loading: true,
        error: '',
        user: null,
      };
    case AuthTypes.LOGIN_FAILED:
      return {
        ...state,
        isAuthenticated: false,
        loading: false,
        user: null,
        error: action.payload,
      };
    case AuthTypes.LOGOUT:
      return {
        ...state,
        isAuthenticated: false,
        loading: false,
        user: null,
        error: '',
        notAuthMsg: action.payload,
      };

    case AuthTypes.CLEAR_AUTH_ERROR:
      return {
        ...state,
        error: '',
        loading: false,
        notAuthMsg: '',
      };

    case AuthTypes.REGISTERING:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case AuthTypes.REGISTER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
      };
    case AuthTypes.REGISTER_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default authReducer;
