import * as ProcurementType from './procurementTypes';

const initalState = {
  loading: false,
  fetching: false,
  error: '',

  generalItemList: { userData: [] },
  inventoryRequestList: { userData: [] },
};

// eslint-disable-next-line
const procurementReducer = (state = initalState, action) => {
  switch (action.type) {
    case ProcurementType.LOADING_PROCUREMENT:
      return {
        ...state,
        loading: true,
      };
    case ProcurementType.FETCHING_PROCUREMENT:
      return {
        ...state,
        fetching: true,
      };
    case ProcurementType.SET_ERROR:
      return {
        ...state,
        loading: false,
        fetching: false,
        error: action.payload,
      };

    case ProcurementType.GENERAL_ITEM_LIST:
      return {
        ...state,
        loading: false,
        generalItemList: action.payload,
      };
    case ProcurementType.INVENTORY_REQUEST_LIST:
      return {
        ...state,
        loading: false,
        inventoryRequestList: action.payload,
      };

    default:
      return state;
  }
};

export default procurementReducer;
