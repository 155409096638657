import PropTypes from 'prop-types';
import React from 'react';
import { Box, Paper, Button } from '@mui/material';
import ButtonGroup from '@mui/material/ButtonGroup';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { CSVLink } from 'react-csv';
import Iconify from '../Iconify';

const ExportButton = ({ data, csvHeader, exportFileName }) => {
  // Drop Down
  // const options = ['Export All', 'Export Current'];
  const options = ['Export'];

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const handleClick = () => {
    console.info(`You clicked ${options[selectedIndex]}`);
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const csvLink = {
    filename: `${exportFileName}.csv`,
    headers: csvHeader,
    data,
  };

  return (
    <Box mb={{ xs: 4, md: 0 }}>
      <ButtonGroup variant="outlined" color="error" ref={anchorRef} aria-label="split button">
        <Button onClick={handleClick} style={{ padding: 0, color: 'inherit' }}>
          <CSVLink {...csvLink} style={{ textDecoration: 'none', padding: '6px 10px', color: 'red' }}>
            {options[selectedIndex]}
          </CSVLink>
        </Button>
        <Button
          size="small"
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <Iconify icon={'eva:arrow-ios-downward-fill'} fontSize={26} mt={-0.5} />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {options.map((option, index) => (
                    <CSVLink key={index} {...csvLink} style={{ textDecoration: 'none', color: '#000' }}>
                      <MenuItem
                        key={option}
                        disabled={index === 2}
                        selected={index === selectedIndex}
                        onClick={(event) => handleMenuItemClick(event, index)}
                      >
                        {option}
                      </MenuItem>
                    </CSVLink>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  );
};
ExportButton.propTypes = {
  data: PropTypes.array,
  csvHeader: PropTypes.array,
  exportFileName: PropTypes.string,
};

ExportButton.propTypes = {
  data: PropTypes.array,
  csvHeader: PropTypes.array,
  exportFileName: PropTypes.string,
};

export default ExportButton;
