import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

// form
import { useSnackbar } from 'notistack';

// @mui
import { LoadingButton } from '@mui/lab';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { Autocomplete, Box, Card, Grid, Stack, TextField } from '@mui/material';

// components
import { useDispatch, useSelector } from '../../../../../redux/store';

import Iconify from '../../../../../components/Iconify';
import { getAllCaptainByAction } from '../../../../../redux/settings/settingsActions';
import { searchCaptainDeduction } from '../../../../../redux/captains/captainActions';

// import Chip from '../../../theme/overrides/Chip';

// ----------------------------------------------------------------------

DeductionsForm.propTypes = {
  getDateInfo: PropTypes.func,
};

export default function DeductionsForm({ getDateInfo }) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const today = new Date();
  const nextDay = new Date();
  nextDay.setDate(nextDay.getDate() + 1);

  const { fetchingBooking } = useSelector((state) => state.bookings);

  const [startDate, setStartDate] = useState(today);
  const [endDate, setEndDate] = useState(nextDay);
  const [captainCode, setCaptainCode] = useState('');

  const {
    allCaptainList: { userData: allCaptainList },
  } = useSelector((state) => state.settings);

  const handleSubmit = () => {
    dispatch(
      searchCaptainDeduction(
        enqueueSnackbar,
        captainCode,
        new Date(startDate).toISOString(),
        new Date(endDate).toISOString()
      )
    );

    getDateInfo({
      startDate: new Date(startDate).toDateString(),
      endDate: new Date(endDate).toDateString(),
    });
  };

  useEffect(() => {
    dispatch(getAllCaptainByAction(enqueueSnackbar));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Card sx={{ p: 3 }}>
          <Box>
            <Box
              sx={{
                marginTop: 3,
                display: 'grid',
                columnGap: 2,
                rowGap: 3,
                gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(3, 1fr)' },
              }}
            >
              <DesktopDatePicker
                label="Start Date"
                inputFormat="dd/MM/yyyy"
                value={startDate}
                // minDate={today.setDate(today.getDate())}
                onChange={(newValue) => {
                  setStartDate(newValue);
                }}
                renderInput={(params) => <TextField name="departureDate" {...params} fullWidth />}
              />
              <DesktopDatePicker
                label="End Date"
                name="endDate"
                inputFormat="dd/MM/yyyy"
                value={endDate}
                // minDate={today.setDate(today.getDate())}
                onChange={(newValue) => {
                  setEndDate(newValue);
                }}
                renderInput={(params) => <TextField name="endDate" {...params} fullWidth />}
              />

              <Autocomplete
                value={captainCode}
                inputValue={captainCode}
                onInputChange={(event, newInputValue) => {
                  setCaptainCode(newInputValue);
                }}
                includeInputInList
                id="controllable-states-demo"
                options={
                  allCaptainList?.length ? ['', ...new Set(allCaptainList?.map((c) => c?.captainCode || '-'))] : []
                }
                renderInput={(params) => <TextField {...params} label="Select a Captain" />}
              />
            </Box>
          </Box>

          <Stack alignItems="flex-end" sx={{ mt: 3 }}>
            <LoadingButton type="button" onClick={handleSubmit} variant="contained" loading={fetchingBooking}>
              <Iconify icon={'eva:search-fill'} sx={{ marginRight: 1, fontSize: '18px' }} /> Search
            </LoadingButton>
          </Stack>
        </Card>
      </Grid>
    </Grid>
  );
}
