import * as InventoryTypes from './inventoryTypes';

const initalState = {
  loading: false,
  fetching: false,
  error: '',
  bankList: [],
  vehicleMakeList: [],
  vehiclePartType: {},
  partTypeSettingsList: {},
  storeList: { userData: [] },
  vendorList: { userData: [] },
  vehiclePartList: { userData: [] },
  vehiclePartByModel: {},
  jobcardRequestList: {},
  workshopSettingsList: { userData: [] },
  jobCategoryList: { userData: [] },
  jobCardSettings: [],
  vehiclePartPosition: { userData: [] },
  inventoryHistory: { userData: [] },
  inventoryReport: { userData: [] },
  maintenancePartTypes: [],
  inventoryStoreItem: { userData: [] },
  inventoryGeneralStore: { userData: [] },
  inventoryProcumentItems: { userData: [] },
  idleVehicleList: { userData: [] },
  jobCardList: { userData: [] },
  vehicleDueForService: { userData: [] },
  scheduledMaintMileage: { userData: [] },
  inventoryProcumentItemsDetail: {},
  receiveItemList: { userData: [] },
  sendItemList: { userData: [] },
  approveStockInList: { userData: [] },
  scheduleMaintenanceQueueList: { userData: [] },

  procurementItemDetails: {},
  processProcurementItemList: { userData: [] },
  generalProcurementItemList: { userData: [] },
  generalItemList: { userData: [] },
  procurementPurchaseOrderList: { userData: [] },
  purchaseOrderList: { userData: [] },
  purchaseOrderDetail: { userData: [] },
  inventoryRequestList: { userData: [] },
  inventoryRequestDetail: {},
  inventoryItemList: [],
  inventoryOrderList: { userData: [] },
  inventoryOrderDetail: { userData: [] },
  generalRequestItemDetail: { userData: [] },
  supplyItemList: { userData: [] },
  supplyItemPurchaseOrder: {},
};

// eslint-disable-next-line
const inventoryReducer = (state = initalState, action) => {
  switch (action.type) {
    case InventoryTypes.LOADING_INVENTORY:
      return {
        ...state,
        loading: true,
      };
    case InventoryTypes.FETCHING_INVENTORY:
      return {
        ...state,
        fetching: true,
      };
    case InventoryTypes.SET_ERROR:
      return {
        ...state,
        loading: false,
        fetching: false,
        error: action.payload,
      };

    case InventoryTypes.SUPPLY_ITEM_LIST:
      return {
        ...state,
        loading: false,
        supplyItemList: action.payload,
      };
    case InventoryTypes.SUPPLY_ITEM_PURCHASE_ORDER:
      return {
        ...state,
        loading: false,
        supplyItemPurchaseOrder: action.payload,
      };
    case InventoryTypes.GENERAL_REQUEST_ITEM_BY_ID:
      return {
        ...state,
        loading: false,
        generalRequestItemDetail: action.payload,
      };
    case InventoryTypes.JOBCARD_STATUS_SUCCESS:
      return {
        ...state,
        fetching: false,
        isStatusUpdate: action.payload,
      };
    case InventoryTypes.INVENTORY_ORDER:
      return {
        ...state,
        loading: false,
        inventoryOrderList: action.payload,
      };
    case InventoryTypes.INVENTORY_ORDER_BY_ID:
      return {
        ...state,
        loading: false,
        inventoryOrderDetail: action.payload,
      };
    case InventoryTypes.GENERAL_ITEM_LIST:
      return {
        ...state,
        loading: false,
        generalItemList: action.payload,
      };
    case InventoryTypes.INVENTORY_REQUEST_LIST:
      return {
        ...state,
        loading: false,
        inventoryRequestList: action.payload,
      };
    case InventoryTypes.SCHEDULE_MAINTENACE_QUEUE:
      return {
        ...state,
        loading: false,
        scheduleMaintenanceQueueList: action.payload,
      };
    case InventoryTypes.INVENTORY_REQUEST_BY_ID:
      return {
        ...state,
        loading: false,
        inventoryRequestDetail: action.payload,
      };
    case InventoryTypes.PROCESS_PROCUREMENT_ITEMS:
      return {
        ...state,
        loading: false,
        processProcurementItemList: action.payload,
      };
    case InventoryTypes.GENERAL_PROCUREMENT_ITEMS:
      return {
        ...state,
        loading: false,
        generalProcurementItemList: action.payload,
      };
    case InventoryTypes.PROCUREMENT_ITEMS_BY_ID:
      return {
        ...state,
        loading: false,
        procurementItemDetails: action.payload,
      };
    case InventoryTypes.PURCHASE_ORDER:
      return {
        ...state,
        loading: false,
        purchaseOrderList: action.payload,
      };
    case InventoryTypes.PURCHASE_ORDER_BY_ID:
      return {
        ...state,
        loading: false,
        purchaseOrderDetail: action.payload,
      };
    case InventoryTypes.INVENTORY_ITEMS:
      return {
        ...state,
        loading: false,
        inventoryItemList: action.payload,
      };
    case InventoryTypes.GET_JOBCARD_REQUEST:
      return {
        ...state,
        loading: false,
        jobcardRequestList: action.payload,
      };
    case InventoryTypes.PROCUREMENT_PURCHASE_ORDER:
      return {
        ...state,
        loading: false,
        procurementPurchaseOrderList: action.payload,
      };

    // procument ends here
    case InventoryTypes.GET_VEHICLE_PART_TYPE:
      return {
        ...state,
        loading: false,
        vehiclePartType: action.payload,
      };
    case InventoryTypes.GET_PART_TYPE_SETTINGS:
      return {
        ...state,
        loading: false,
        partTypeSettingsList: action.payload,
      };
    case InventoryTypes.MAINTENANCE_PART_TYPE_LIST:
      return {
        ...state,
        loading: false,
        maintenancePartTypes: action.payload,
      };
    case InventoryTypes.JOB_CARD_MANAGEMENT:
      return {
        ...state,
        loading: false,
        jobCardList: action.payload,
      };
    case InventoryTypes.VEHICLE_DUE_FOR_SERVICE:
      return {
        ...state,
        loading: false,
        vehicleDueForService: action.payload,
      };
    case InventoryTypes.SCHEDULED_MAINENANCE_MILEAGE:
      return {
        ...state,
        loading: false,
        scheduledMaintMileage: action.payload,
      };
    case InventoryTypes.GET_JOBCARD_SETTINGS:
      return {
        ...state,
        loading: false,
        jobCardSettings: action.payload,
      };
    case InventoryTypes.GET_IDLE_VEHICLE:
      return {
        ...state,
        loading: false,
        idleVehicleList: action.payload,
      };
    case InventoryTypes.STORE_LIST:
      return {
        ...state,
        loading: false,
        storeList: action.payload,
      };
    case InventoryTypes.WORKSHOP_SETTINGS_LIST:
      return {
        ...state,
        loading: false,
        workshopSettingsList: action.payload,
      };
    case InventoryTypes.JOB_CATEGORY_LIST:
      return {
        ...state,
        loading: false,
        jobCategoryList: action.payload,
      };
    case InventoryTypes.VENDOR_LIST:
      return {
        ...state,
        loading: false,
        vendorList: action.payload,
      };
    case InventoryTypes.VEHICLE_PART_LIST:
      return {
        ...state,
        loading: false,
        vehiclePartList: action.payload,
      };
    case InventoryTypes.VEHICLE_PART_BY_ID:
      return {
        ...state,
        loading: false,
        vehiclePartByModel: action.payload,
      };
    case InventoryTypes.VEHICLE_PART_POSITION:
      return {
        ...state,
        loading: false,
        vehiclePartPosition: action.payload,
      };
    case InventoryTypes.INVENTORY_HISTORY:
      return {
        ...state,
        loading: false,
        inventoryHistory: action.payload,
      };
    case InventoryTypes.INVENTORY_REPORT:
      return {
        ...state,
        loading: false,
        inventoryReport: action.payload,
      };
    case InventoryTypes.INVENTORY_GENERAL_STORE:
      return {
        ...state,
        loading: false,
        inventoryGeneralStore: action.payload,
      };
    case InventoryTypes.INVENTORY_STORE_ITEM:
      return {
        ...state,
        loading: false,
        inventoryStoreItem: action.payload,
      };
    case InventoryTypes.PROCUMENT_ITEM_LIST:
      return {
        ...state,
        loading: false,
        inventoryProcumentItems: action.payload,
      };
    case InventoryTypes.PROCUMENT_ITEM_DETAILS:
      return {
        ...state,
        loading: false,
        inventoryProcumentItemsDetail: action.payload,
      };
    case InventoryTypes.RECEIVE_ITEM_LIST:
      return {
        ...state,
        loading: false,
        receiveItemList: action.payload,
      };
    case InventoryTypes.SENT_ITEM_LIST:
      return {
        ...state,
        loading: false,
        sendItemList: action.payload,
      };
    case InventoryTypes.APPROVE_STOCK_IN:
      return {
        ...state,
        loading: false,
        approveStockInList: action.payload,
      };

    case InventoryTypes.LOGOUT:
      return {
        loading: false,
        error: '',
        storeList: { userData: [] },
        vendorList: { userData: [] },
        vehiclePartList: { userData: [] },
        vehiclePartByModel: {},
        vehiclePartPosition: { userData: [] },
        inventoryHistory: { userData: [] },
        inventoryReport: { userData: [] },
        inventoryStoreItem: { userData: [] },
        inventoryGeneralStore: { userData: [] },
        inventoryProcumentItems: { userData: [] },
        inventoryProcumentItemsDetail: {},
        idleVehicleList: {},
        receiveItemList: { userData: [] },
        sendItemList: { userData: [] },
        supplyItemList: { userData: [] },
      };

    default:
      return state;
  }
};

export default inventoryReducer;
