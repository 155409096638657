import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Link, Typography } from '@mui/material';
// hooks
import { useSelector } from 'react-redux';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import Avatar from '../../../components/Avatar';
import createAvatar from '../../../utils/createAvatar';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: theme.palette.grey[500_12],
  transition: theme.transitions.create('opacity', {
    duration: theme.transitions.duration.shorter,
  }),
}));

// ----------------------------------------------------------------------

NavbarAccount.propTypes = {
  isCollapse: PropTypes.bool,
};

export default function NavbarAccount({ isCollapse }) {
  const { terminalDTO } = useSelector((state) => state.auth.user);

  return (
    <RootStyle
      sx={{
        ...(isCollapse && {
          bgcolor: 'transparent',
        }),
      }}
    >
      <Avatar alt={terminalDTO?.terminalName} color={createAvatar(terminalDTO?.terminalName).color}>
        {createAvatar(terminalDTO?.terminalName).name}
      </Avatar>

      <Box
        sx={{
          ml: 2,
          transition: (theme) =>
            theme.transitions.create('width', {
              duration: theme.transitions.duration.shorter,
            }),
          ...(isCollapse && {
            ml: 0,
            width: 0,
          }),
        }}
      >
        <Typography variant="subtitle2" noWrap>
          TERMINAL
        </Typography>
        <Typography variant="body2" noWrap sx={{ color: 'text.secondary' }}>
          {terminalDTO?.terminalName}
        </Typography>
      </Box>
    </RootStyle>
  );
}
