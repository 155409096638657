export const LOADING_CAPTAIN = 'LOADING_CAPTAIN';
export const FETCHING_CAPTAIN = 'FETCHING_CAPTAIN';

export const GET_CAPTAIN_LIST = 'GET_CAPTAIN_LIST';
export const CAPTAIN_SALARY_REPORT_LIST = 'CAPTAIN_SALARY_REPORT_LIST';
export const CAPTAIN_SALARY_REPORT_DETAILS = 'CAPTAIN_SALARY_REPORT_DETAILS';
export const CAPTAIN_TRIPS_REPORT = 'CAPTAIN_TRIPS_REPORT';
export const CAPTAIN_TRIPS_REPORT_DETAIL = 'CAPTAIN_TRIPS_REPORT_DETAIL';
export const CAPTAIN_TRIPS_HISTORY = 'CAPTAIN_TRIPS_HISTORY';
export const CAPTAIN_RECORD_HISTORY = 'CAPTAIN_RECORD_HISTORY';
export const CAPTAIN_WITH_17_TRIPS = 'CAPTAIN_WITH_17_TRIPS';
export const CAPTAIN_DEDUCTION_LIST = 'CAPTAIN_DEDUCTION_LIST';
export const LISTING_RESET = 'LISTING_RESET';
export const SET_ERROR = 'SET_ERROR';
export const LOGOUT = 'LOGOUT';
