import PropTypes from 'prop-types';
import { useState } from 'react';
// @mui
import {
  Box,
  Card,
  Table,
  MenuItem,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  Divider,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
// components
import Iconify from '../../../../components/Iconify';
import Scrollbar from '../../../../components/Scrollbar';
import { TableMoreMenu, TableHeadCustom, TableNoData } from '../../../../components/table';
import { useDispatch } from '../../../../redux/store';
import CustomLoader from '../../../../components/CustomLoader';
import { fetchCountryAction } from '../../../../redux/bookings/bookingsActions';
import * as ModalTypes from '../../../../redux/modal/modalTypes';
import { httpRequest } from '../../../../https/http';
import {
  getHandoverCaptainAction,
  getVehicleAction,
  getVehicleByIdAction,
} from '../../../../redux/settings/settingsActions';
import { VEHICLE_STATUS, getCaptainType } from '../../../../utils';

// ----------------------------------------------------------------------

CaptainsTripHistoryTable.propTypes = {
  title: PropTypes.string,
  loading: PropTypes.bool,
  tableData: PropTypes.array,

  tableLabels: PropTypes.array,
};

export default function CaptainsTripHistoryTable({ loading, tableLabels, tableData, ...other }) {
  return (
    <Card {...other}>
      <Scrollbar>
        <TableContainer sx={{ minWidth: 720 }} style={{ maxHeight: '70vh' }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHeadCustom headLabel={tableLabels} />

            <TableBody>
              {loading ? (
                <Box component="tr" sx={{ height: '60vh' }}>
                  <CustomLoader />
                </Box>
              ) : (
                tableData.map((row, i) => (
                  <CaptainsTripHistoryTableDetailsRow key={i} row={row} serialNo={i} tableData={tableData} />
                ))
              )}

              {!loading && tableData.length === 0 ? <TableNoData isNotFound /> : ''}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>
    </Card>
  );
}

// ----------------------------------------------------------------------

CaptainsTripHistoryTableDetailsRow.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.any,
    vehicleRegistrationNumber: PropTypes.string,
    vehicleModel: PropTypes.any,
    vehicleEngineNumber: PropTypes.string,
    vehicleIMEINumber: PropTypes.string,
    partner: PropTypes.string,
    vehicleStatus: PropTypes.any,
    dispatchPartnerType: PropTypes.string,
    ebm: PropTypes.string,
    location: PropTypes.string,
    particluarExpirationDate: PropTypes.string,
  }),
  tableData: PropTypes.array,

  serialNo: PropTypes.number,
};

function CaptainsTripHistoryTableDetailsRow({ row, serialNo, tableData }) {
  const { enqueueSnackbar } = useSnackbar();
  const [openMenu, setOpenMenuActions] = useState(null);

  const handleOpenMenu = (event) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };

  const dispatch = useDispatch();

  const handleAddEvent = (action) => {
    if (action.type === 'edit') {
      dispatch(getVehicleAction(enqueueSnackbar));
    }

    if (action.type === 'view') {
      dispatch(getVehicleByIdAction(enqueueSnackbar, action?.data?.id));
    }

    if (action.type === 'assign') {
      dispatch(getHandoverCaptainAction(enqueueSnackbar, action?.data?.id));
    }

    dispatch({ type: ModalTypes.MODAL_OPEN, payload: action });
    handleCloseMenu();
  };

  const handleDeleteRow = async () => {
    handleCloseMenu();
    try {
      const result = await httpRequest({
        url: `Vehicle/${row.id}`,
        method: 'DELETE',
        urlType: 'settings',
      });
      if (result.code === 1) {
        dispatch(getVehicleAction(enqueueSnackbar));
        enqueueSnackbar('Vehicle deleted successfully', { variant: 'success' });
      } else {
        enqueueSnackbar('Something went wrong, please refresh & try again!!', { variant: 'error' });
      }
    } catch (error) {
      enqueueSnackbar(error.message || 'Something went wrong, please refresh & try again!!', { variant: 'error' });
    }
  };

  return (
    <TableRow>
      <TableCell align="left">{serialNo + 1}</TableCell>

      {/* <TableCell align="left">{row?.ebm}</TableCell> */}
      <TableCell align="center">{row?.location || 'Null'}</TableCell>
      <TableCell align="center">{VEHICLE_STATUS[row?.vehicleStatus]}</TableCell>
      <TableCell align="center">
        {row?.capatain?.captainCode}
        {row?.captains?.map((captain, i) => (
          <Typography key={i} variant="body2" sx={{ fontSize: '13px' }}>
            {captain?.captainCode} ({getCaptainType(captain.captainType)})
          </Typography>
        ))}
      </TableCell>
      <TableCell align="center">{new Date(row?.particluarExpirationDate).toDateString()}</TableCell>

      <TableCell align="right">
        <TableMoreMenu
          open={openMenu}
          onOpen={handleOpenMenu}
          onClose={handleCloseMenu}
          actions={
            <>
              <MenuItem onClick={() => handleAddEvent({ type: 'view', data: row })}>
                <Iconify icon={'eva:eye-outline'} />
                View
              </MenuItem>
              <MenuItem onClick={() => handleAddEvent({ type: 'edit', data: row })}>
                <Iconify icon={'eva:edit-outline'} />
                Edit
              </MenuItem>
              <MenuItem onClick={() => handleAddEvent({ type: 'assign', data: row })}>
                <Iconify icon={'pajamas:assignee'} />
                Assign
              </MenuItem>

              <Divider sx={{ borderStyle: 'dashed' }} />

              <MenuItem onClick={handleDeleteRow} sx={{ color: 'error.main' }}>
                <Iconify icon={'eva:trash-2-outline'} />
                Delete
              </MenuItem>
            </>
          }
        />
      </TableCell>
    </TableRow>
  );
}
