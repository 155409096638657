import PropTypes from 'prop-types';
// @mui
import { Box, Table, TableRow, TableBody, TableCell, TableContainer } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import { useState } from 'react';
import Iconify from '../../../../../components/Iconify';
import Scrollbar from '../../../../../components/Scrollbar';
import { TableHeadCustom, TableNoData } from '../../../../../components/table';
import CustomLoader from '../../../../../components/CustomLoader';

// ----------------------------------------------------------------------

CaptainBonusTable.propTypes = {
  loading: PropTypes.bool,
  tableData: PropTypes.array,
  dense: PropTypes.any,
  serialNo: PropTypes.number,
  tableLabels: PropTypes.array,
};

export default function CaptainBonusTable({ loading, tableLabels, tableData, dense, serialNo }) {
  const [selected, setSelected] = useState(0);
  return (
    <Scrollbar>
      <TableContainer sx={{ minWidth: 720 }} style={{ maxHeight: '70vh' }}>
        <Table size={dense ? 'small' : 'medium'} stickyHeader aria-label="sticky table">
          <TableHeadCustom headLabel={tableLabels} />
          <TableBody>
            {loading ? (
              <Box component="tr" sx={{ height: '60vh' }}>
                <CustomLoader />
              </Box>
            ) : (
              tableData.map((row, i) => (
                <CaptainBonusRow
                  selected={selected}
                  setSelected={setSelected}
                  key={i}
                  row={row}
                  serialNo={serialNo + i}
                />
              ))
            )}

            {!loading && tableData.length === 0 ? <TableNoData isNotFound /> : ''}
          </TableBody>
        </Table>
      </TableContainer>
    </Scrollbar>
  );
}

// ----------------------------------------------------------------------

CaptainBonusRow.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.any,
    captainCode: PropTypes.string,
    remark: PropTypes.string,
    amount: PropTypes.number,
    createdDate: PropTypes.string,
    dateModified: PropTypes.string,
    approvalRejectBy: PropTypes.string,
    approvalComment: PropTypes.string,
    dateApproved: PropTypes.string,
  }),
  serialNo: PropTypes.number,
};

function CaptainBonusRow({ row, serialNo }) {
  return (
    <TableRow>
      <TableCell align="left">{serialNo}</TableCell>
      <TableCell align="center">{row?.captainCode}</TableCell>
      <TableCell align="center">{row?.remark}</TableCell>
      <TableCell align="center">{row?.amount}</TableCell>
      <TableCell align="center">{row?.createdDate}</TableCell>
      <TableCell align="center">{row?.dateModified}</TableCell>
      <TableCell align="right">
        <LoadingButton
          color="warning"
          type="button"
          variant="contained"
          loading={false}
          sx={{ whiteSpace: 'nowrap', py: 1, px: 3 }}
        >
          Approve By Audit{' '}
        </LoadingButton>
      </TableCell>
      <TableCell align="right">
        <LoadingButton
          color="info"
          type="button"
          variant="contained"
          loading={false}
          sx={{ whiteSpace: 'nowrap', py: 1, px: 3 }}
        >
          Change Status{' '}
        </LoadingButton>
      </TableCell>
      <TableCell align="center">{row?.approvalRejectBy}</TableCell>
      <TableCell align="center">{row?.approvalComment}</TableCell>
      <TableCell align="center">
        <Box width="100px">{row?.dateApproved}</Box>
      </TableCell>
      <TableCell align="right">
        <LoadingButton
          color="error"
          type="button"
          variant="contained"
          loading={false}
          sx={{ whiteSpace: 'nowrap', py: 1 }}
        >
          <Iconify icon={'eva:trash-2-fill'} width={20} height={26} />
        </LoadingButton>
      </TableCell>
    </TableRow>
  );
}
