import * as SettingsTypes from './settingsTypes';

const initalState = {
  loading: false,
  fetching: false,
  error: '',
  bankList: [],
  departmentList: [],
  vehicleMakeList: [],
  vehicleFeatureList: [],
  vehicleList: { userData: [] },
  employeeRoute: { userData: [] },
  vehicleByIdDetails: {},
  vehicleModelList: [],
  availableVehicleList: [],
  vehicleInTerminalList: [],

  allCaptainList: { userData: [] },
  captainTripHistory: [],
  captainList: {},
  assignPrimaryCaptain: { userData: [] },
  captainClassificationList: [],
  primaryCaptainList: [],
  captainRecord: {},
  handoverCaptainList: {},
  handoverCapList: {},
  couponList: {},

  routeByTerminalIDList: [],
  virtualBusByRouteList: [],
  routeTypeList: [],
  travelDocTypeList: {},
  travelDocTypeWithAmountList: {},
  subRouteList: { userData: [] },

  terminalList: { userData: [] },
  terminalByDepatureList: [],
  allStateList: { userData: [] },

  regionByCountryList: [],
  stateByRegionList: [],
  terminalByStateList: [],
  routeByTerminalList: [],
  tripsByRouteList: [],

  partnerList: { userData: [] },
  partnerRequestList: { userData: [] },
  partnerTransactionList: { userData: [] },
  partnerPayoutList: [],
  partnerTypeList: [],
  documentType: [],
  documentTypeFeeList: [],
  paymentGatewayList: [],
  extendedDiscountList: [],
  dataBaseLogList: [],
  useDataBaseLog: [],
  bannerList: [],
  azureBugList: [],
  azureBugDetail: {},
  campusAmbassadorDetail: {},
  ambassadorBookingHistory: {},
  campusAmbassadorList: {},
  ambassadorTicketList: {},
  ambassadorRequestList: {},
  lockedOutCaptainList: {},
  vehicleRevenueReport: {},
  dailyPerformanceReport: {},
  bookingCustomerAnalysis: {},
};

// eslint-disable-next-line
const bookingsReducer = (state = initalState, action) => {
  switch (action.type) {
    case SettingsTypes.LOADING_SETTINGS:
      return {
        ...state,
        loading: true,
      };
    case SettingsTypes.FETCHING_SETTINGS:
      return {
        ...state,
        fetching: true,
      };
    case SettingsTypes.VEHICLE_REVENUE_REPORT:
      return {
        ...state,
        loading: false,
        error: '',
        vehicleRevenueReport: action.payload,
      };
    case SettingsTypes.DAILY_PERFORMANCE_REPORT:
      return {
        ...state,
        loading: false,
        error: '',
        dailyPerformanceReport: action.payload,
      };
    case SettingsTypes.BOOKING_CUSTOMER_ANALYSIS:
      return {
        ...state,
        loading: false,
        error: '',
        bookingCustomerAnalysis: action.payload,
      };
    case SettingsTypes.CAMPUS_AMBASSADOR_LIST:
      return {
        ...state,
        loading: false,
        campusAmbassadorList: action.payload,
      };
    case SettingsTypes.LOCKED_OUT_CAPTAINS:
      return {
        ...state,
        loading: false,
        lockedOutCaptainList: action.payload,
      };
    case SettingsTypes.SUSPENDED_INACTIVE_CAPTAINS:
      return {
        ...state,
        loading: false,
        suspendedInactiveCaptain: action.payload,
      };

    case SettingsTypes.CAMPUS_AMBASSADOR_DETAIL:
      return {
        ...state,
        loading: false,
        campusAmbassadorDetail: action.payload,
      };
    case SettingsTypes.AMBASSADOR_BOOKING_HISTORY:
      return {
        ...state,
        loading: false,
        ambassadorBookingHistory: action.payload,
      };
    case SettingsTypes.AMBASSADOR_TICKET_BOOKING:
      return {
        ...state,
        loading: false,
        ambassadorTicketList: action.payload,
      };
    case SettingsTypes.AMBASSADOR_WITHDRAWAL_REQUEST:
      return {
        ...state,
        loading: false,
        ambassadorRequestList: action.payload,
      };
    case SettingsTypes.GET_VEHICLE_LIST:
      return {
        ...state,
        fetching: false,
        vehicleList: action.payload,
      };
    case SettingsTypes.EMPLOYEE_ROUTE:
      return {
        ...state,
        loading: false,
        employeeRoute: action.payload,
      };

    case SettingsTypes.BANK_LIST:
      return {
        ...state,
        loading: false,
        bankList: action.payload,
      };
    case SettingsTypes.DEPARTMENT_LIST:
      return {
        ...state,
        loading: false,
        departmentList: action.payload,
      };
    case SettingsTypes.GET_VEHICLE_LIST_ID:
      return {
        ...state,
        loading: false,
        vehicleByIdDetails: action.payload,
      };
    case SettingsTypes.ASSIGN_PRIMARY_CAPTAIN:
      return {
        ...state,
        loading: false,
        assignPrimaryCaptain: action.payload,
      };
    case SettingsTypes.GET_PRIMARY_CAPTAIN:
      return {
        ...state,
        loading: false,
        primaryCaptainList: action.payload,
      };
    case SettingsTypes.GET_HAND_OVER_CAPTAIN:
      return {
        ...state,
        loading: false,
        handoverCaptainList: action.payload,
      };
    case SettingsTypes.HAND_OVER_CAPTAIN_LIST:
      return {
        ...state,
        loading: false,
        handoverCapList: action.payload,
      };
    case SettingsTypes.VEHICLE_MAKE_LISTING:
      return {
        ...state,
        loading: false,
        vehicleMakeList: action.payload,
      };
    case SettingsTypes.VEHICLE_FEATURES_LISTING:
      return {
        ...state,
        loading: false,
        vehicleFeatureList: action.payload,
      };

    case SettingsTypes.CAPTAIN_LISTING:
      return {
        ...state,
        loading: false,
        captainList: action.payload,
      };
    case SettingsTypes.GET_ALL_CAPTAIN_LISTING:
      return {
        ...state,
        loading: false,
        fetching: false,
        allCaptainList: action.payload,
      };
    case SettingsTypes.CAPTAIN_RECORD:
      return {
        ...state,
        loading: false,
        captainRecord: action.payload,
      };
    case SettingsTypes.CAPTAIN_CLASSIFICATION_LIST:
      return {
        ...state,
        loading: false,
        captainClassificationList: action.payload,
      };
    case SettingsTypes.GET_VIRTUAL_BUS_BY_ROUTE:
      return {
        ...state,
        loading: false,
        virtualBusByRouteList: action.payload,
      };
    case SettingsTypes.GET_TERMINAL_LIST:
      return {
        ...state,
        loading: false,
        terminalList: action.payload,
      };
    case SettingsTypes.GET_STATE_LIST:
      return {
        ...state,
        loading: false,
        allStateList: action.payload,
      };
    case SettingsTypes.TERMINAL_BY_DEPARTURE_SUCCESS:
      return {
        ...state,
        loading: false,
        terminalByDepatureList: action.payload,
      };
    case SettingsTypes.GET_ROUTE_BY_TERMINAL_ID:
      return {
        ...state,
        loading: false,
        routeByTerminalIDList: action.payload,
      };
    case SettingsTypes.GET_AVAILABLE_VEHICLES:
      return {
        ...state,
        loading: false,
        availableVehicleList: action.payload,
      };

    case SettingsTypes.CAPTAIN_TRIP_HISTORY:
      return {
        ...state,
        loading: false,
        captainTripHistory: action.payload,
      };
    case SettingsTypes.GET_VEHICLE_MODEL_LIST:
      return {
        ...state,
        loading: false,
        vehicleModelList: action.payload,
      };
    case SettingsTypes.VEHICLE_IN_TERMINAL_LIST:
      return {
        ...state,
        loading: false,
        vehicleInTerminalList: action.payload,
      };
    case SettingsTypes.ROUTE_TYPE_LIST:
      return {
        ...state,
        loading: false,
        routeTypeList: action.payload,
      };
    case SettingsTypes.TRAVEL_DOC_TYPE_LIST:
      return {
        ...state,
        loading: false,
        travelDocTypeList: action.payload,
      };
    case SettingsTypes.TRAVEL_DOC_TYPE_WITH_AMOUNT_LIST:
      return {
        ...state,
        loading: false,
        travelDocTypeWithAmountList: action.payload,
      };
    case SettingsTypes.GET_PARTNER_LIST:
      return {
        ...state,
        loading: false,
        partnerList: action.payload,
      };
    case SettingsTypes.GET_PARTNER_REQUEST_LIST:
      return {
        ...state,
        loading: false,
        partnerRequestList: action.payload,
      };
    case SettingsTypes.GET_PARTNER_TRANSACTION_LIST:
      return {
        ...state,
        loading: false,
        partnerTransactionList: action.payload,
      };
    case SettingsTypes.GET_PARTNER_PAYOUT_LIST:
      return {
        ...state,
        loading: false,
        partnerPayoutList: action.payload,
      };
    case SettingsTypes.GET_PARTNER_TYPE:
      return {
        ...state,
        loading: false,
        partnerTypeList: action.payload,
      };
    case SettingsTypes.DOCUMENT_TYPE:
      return {
        ...state,
        loading: false,
        documentType: action.payload,
      };
    case SettingsTypes.DOCUMENT_TYPE_FEE:
      return {
        ...state,
        loading: false,
        documentTypeFeeList: action.payload,
      };
    case SettingsTypes.EXTENDED_DISCOUNT_LIST:
      return {
        ...state,
        loading: false,
        extendedDiscountList: action.payload,
      };
    case SettingsTypes.COUPON_LIST:
      return {
        ...state,
        loading: false,
        couponList: action.payload,
      };
    case SettingsTypes.PAYMENT_GATE_LIST:
      return {
        ...state,
        loading: false,
        paymentGatewayList: action.payload,
      };
    case SettingsTypes.SUB_ROUTE_LIST_BY_ROUTE_ID:
      return {
        ...state,
        loading: false,
        subRouteListByRouteId: action.payload,
      };
    case SettingsTypes.GET_SUB_ROUTE_LIST:
      return {
        ...state,
        loading: false,
        subRouteList: action.payload,
      };
    case SettingsTypes.FIND_REGION_BY_COUNTRY:
      return {
        ...state,
        loading: false,
        regionByCountryList: action.payload,
      };
    case SettingsTypes.FIND_STATE_BY_REGION:
      return {
        ...state,
        loading: false,
        stateByRegionList: action.payload,
      };
    case SettingsTypes.FIND_TERMINAL_BY_STATE:
      return {
        ...state,
        loading: false,
        terminalByStateList: action.payload,
      };
    case SettingsTypes.FIND_ROUTE_BY_TERMINAL:
      return {
        ...state,
        loading: false,
        routeByTerminalList: action.payload,
      };
    case SettingsTypes.FIND_TRIPS_BY_ROUTE:
      return {
        ...state,
        loading: false,
        tripsByRouteList: action.payload,
      };

    case SettingsTypes.VENDOR_LIST:
      return {
        ...state,
        loading: false,
        vendorList: action.payload,
      };

    case SettingsTypes.VEHICLE_PART_POSITION:
      return {
        ...state,
        loading: false,
        vehiclePartPosition: action.payload,
      };

    case SettingsTypes.DATABASE_LOG:
      return {
        ...state,
        loading: false,
        dataBaseLogList: action.payload,
      };
    case SettingsTypes.USER_DATABASE_LOG:
      return {
        ...state,
        fetching: false,
        useDataBaseLog: action.payload,
      };
    case SettingsTypes.BANNER_LIST:
      return {
        ...state,
        loading: false,
        bannerList: action.payload,
      };
    case SettingsTypes.AZURE_BUG_LOG:
      return {
        ...state,
        loading: false,
        azureBugList: action.payload,
      };
    case SettingsTypes.AZURE_BUG_LOG_BY_ID:
      return {
        ...state,
        loading: false,
        azureBugDetail: action.payload,
      };

    case SettingsTypes.LISTING_RESET:
      return {
        ...state,
        loading: false,
        fetching: false,
        error: '',
      };
    case SettingsTypes.LOGOUT:
      return {
        loading: false,
        error: '',
        bankList: [],
        vehicleMakeList: [],
        vehicleFeatureList: [],
        vehicleModelList: [],
        vehicleByIdDetails: {},
        availableVehicleList: [],
        vehicleList: { userData: [] },
        employeeRoute: { userData: [] },
        allCaptainList: { userData: [] },
        captainTripHistory: [],
        captainList: {},
        assignPrimaryCaptain: { userData: [] },
        captainClassificationList: [],
        primaryCaptainList: [],
        captainRecord: {},
        handoverCaptainList: {},
        handoverCapList: {},

        routeByTerminalIDList: [],
        routeTypeList: [],
        travelDocTypeList: {},
        travelDocTypeWithAmountList: {},
        virtualBusByRouteList: [],
        subRouteList: { userData: [] },
        subRouteListByRouteId: [],

        terminalList: { userData: [] },
        terminalByDepatureList: [],
        allStateList: { userData: [] },

        regionByCountryList: [],
        stateByRegionList: [],
        terminalByStateList: [],
        routeByTerminalList: [],
        tripsByRouteList: [],

        // partnerList: [],
        // partnerRequestList: { userData: [] },
        // partnerTransactionList: { userData: [] },
        // partnerPayoutList: [],
        // partnerTypeList: [],
        documentType: [],
        documentTypeFeeListList: [],
        paymentGatewayList: [],
        extendedDiscountList: [],
      };

    default:
      return state;
  }
};

export default bookingsReducer;
