import * as CaptainTypes from './captainTypes';

const initalState = {
  loading: false,
  fetching: false,
  error: '',
  partnerList: [],
  captainSalaryList: { userData: [] },
  captainTripHistoryList: {},
  captainRecordHistoryList: { userData: [] },
  captainWith17TripsList: { userData: [] },
  captainTripReportList: {},
  captainDeductionList: {},
  captainTripReportDetail: [],
  partnerTypeList: [],
};

// eslint-disable-next-line
const captainReducer = (state = initalState, action) => {
  switch (action.type) {
    case CaptainTypes.LOADING_CAPTAIN:
      return {
        ...state,
        loading: true,
      };
    case CaptainTypes.FETCHING_CAPTAIN:
      return {
        ...state,
        fetching: true,
      };

    case CaptainTypes.CAPTAIN_WITH_17_TRIPS:
      return {
        ...state,
        fetching: false,
        captainWith17TripsList: action.payload,
      };
    case CaptainTypes.CAPTAIN_RECORD_HISTORY:
      return {
        ...state,
        fetching: false,
        captainRecordHistoryList: action.payload,
      };
    case CaptainTypes.CAPTAIN_TRIPS_HISTORY:
      return {
        ...state,
        fetching: false,
        captainTripHistoryList: action.payload,
      };
    case CaptainTypes.CAPTAIN_TRIPS_REPORT:
      return {
        ...state,
        loading: false,
        captainTripReportList: action.payload,
      };
    case CaptainTypes.CAPTAIN_TRIPS_REPORT_DETAIL:
      return {
        ...state,
        loading: false,
        captainTripReportDetail: action.payload,
      };

    case CaptainTypes.CAPTAIN_DEDUCTION_LIST:
      return {
        ...state,
        loading: false,
        captainDeductionList: action.payload,
      };

    case CaptainTypes.CAPTAIN_SALARY_REPORT_LIST:
      return {
        ...state,
        loading: false,
        captainSalaryList: action.payload,
      };
    case CaptainTypes.CAPTAIN_SALARY_REPORT_DETAILS:
      return {
        ...state,
        loading: false,
        captainSalaryDetailList: action.payload,
      };

    case CaptainTypes.LISTING_RESET:
      return {
        ...state,
        loading: false,
        fetching: false,
        error: '',
      };
    case CaptainTypes.LOGOUT:
      return {
        loading: false,
        error: '',

        partnerList: [],
        partnerRequestList: { userData: [] },
        partnerTransactionList: { userData: [] },
        partnerPayoutList: [],
        partnerTypeList: [],
      };

    default:
      return state;
  }
};

export default captainReducer;
