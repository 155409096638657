// @mui
import { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { Grid, Container, DialogTitle, Box, Button } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useNavigate, useParams } from 'react-router-dom';
import { modalCloseAction } from '../../../../redux/modal/modalActions';
import { httpRequest } from '../../../../https/http';
import CaptainsRecordsTable from './CaptainRecord';
import HeaderBar from '../../../../sections/@dashboard/user/list/Header';
// hooks
import useSettings from '../../../../hooks/useSettings';

// components
import Page from '../../../../components/Page';
// sections
import { DialogAnimate } from '../../../../components/animate';
import { useDispatch, useSelector } from '../../../../redux/store';
import { closeModal, openModal } from '../../../../redux/slices/calendar';
import Iconify from '../../../../components/Iconify';
import { PATH_DASHBOARD } from '../../../../routes/paths';
import CaptainsTripHistoryTable from './CaptainsTripHistoryTable';
import { getCaptainTripHistoryByAction } from '../../../../redux/settings/settingsActions';

// ----------------------------------------------------------------------

export default function CaptainTripHistory() {
  const navigate = useNavigate();

  const [filterName, setFilterName] = useState('');
  const [data, setData] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  const { captainTripHistory } = useSelector((state) => state.settings);

  useEffect(() => {}, [captainTripHistory]);
  // useEffect(() => {
  //   const capID = localStorage.getItem('captainID')
  //     ? JSON.parse(localStorage.getItem('captainID'))
  //     : navigate('/captains/captain');

  //   if (capID) setCaptainIDD(capID);
  //   // getCaptainHistory(capID);
  // }, []);

  // const getCaptainHistory = async (id) => {
  //   try {
  //     setLoading(true);
  //     const result = await httpRequest({
  //       url: `Captains/triphistory/${id}`,
  //       method: 'GET',
  //       urlType: 'captains',
  //     });

  //     if (result.code === 1) {
  //       // const desireArray = Object.keys(result.data).map((key) => ({ name: key, ...result.data[key] }));
  //       setData(result?.data);
  //     }
  //     if (result.code !== 1) {
  //       if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
  //     }
  //     setLoading(false);
  //   } catch (error) {
  //     setLoading(false);
  //     // enqueueSnackbar('Something went wrong, please refresh and try again!', { variant: 'error' });
  //   }
  // };

  const { isOpenModal, modalType } = useSelector((state) => state.calendar);

  const dispatch = useDispatch();
  const { id } = useParams();

  const handleCloseModal = () => {
    dispatch(closeModal());
  };
  const handleAddEvent = (type) => {
    dispatch(openModal(type));
  };

  useEffect(() => {
    dispatch(getCaptainTripHistoryByAction(enqueueSnackbar, id));
  }, []);

  const { themeStretch } = useSettings();

  return (
    <Page title="Captain Trips Report:">
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <HeaderBar filterName={filterName} title={'CAPTAIN TRIP REPORT'} displayValue={'none'} />
        <Box
          onClick={() => navigate(PATH_DASHBOARD.captains.captain, { replace: false })}
          sx={{
            fontSize: 18,
            fontWeight: 'bold',
            mb: 2,
            mx: 2,
            color: 'gray',
            cursor: 'pointer',
            display: 'inline-block',
            ':hover': {
              color: '#E21D00',
            },
          }}
        >
          <Iconify
            sx={{ fontSize: 18, marginRight: '3px', fontWeight: 'bold', mt: '-2px' }}
            icon={`eva:arrowhead-left-outline`}
          />{' '}
          Back
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <CaptainsTripHistoryTable
              title="Captain"
              tableData={data}
              tableLabels={[
                { id: 'SN', label: 'SN' },
                { id: 'registrationNumber', label: 'Registration Number' },
                { id: 'vehicleModel', label: 'Vehicle Model' },
                { id: 'route', label: 'Route' },
                { id: 'date', label: 'Date' },
              ]}
            />
          </Grid>
        </Grid>

        <DialogAnimate open={isOpenModal} onClose={handleCloseModal}>
          {modalType.type === 'availability' && <DialogTitle>Trips Availability</DialogTitle>}
          {modalType.type === 'view' && <DialogTitle>View Trip Schedule</DialogTitle>}
          <hr />

          <Box display="flex" justifyContent="flex-end" sx={{ padding: 3 }}>
            {modalType.type === 'availability' && (
              <Button variant="contained" color="primary" onClick={() => {}} sx={{ marginInlineEnd: 2 }}>
                Save Now
              </Button>
            )}
            {/* {modalType.type === 'view' && (
              <Button variant="contained" color="primary" onClick={handleCloseModal} sx={{ marginInlineEnd: 2 }}>
                Save
              </Button>
            )} */}
            <Button variant="outlined" color="error" onClick={handleCloseModal}>
              Close
            </Button>
          </Box>
        </DialogAnimate>
      </Container>
    </Page>
  );
}
