import { httpRequest } from '../../https/http';
import * as CaptainTypes from './captainTypes';

export const captainTripHistoryAction =
  (enqueueSnackbar, captainCode = '') =>
  async (dispatch) => {
    dispatch({ type: CaptainTypes.FETCHING_CAPTAIN });
    try {
      const result = await httpRequest({
        url: `captaintrip/CaptainTripHistory?CaptainCode=${captainCode}`,
        urlType: 'captains',
      });

      if (result.code === 1) {
        dispatch({ type: CaptainTypes.CAPTAIN_TRIPS_HISTORY, payload: result?.data });
      }

      if (result.code !== 1) {
        dispatch({ type: CaptainTypes.SET_ERROR, payload: 'Request failed!' });
        if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
      }
    } catch (error) {
      dispatch({ type: CaptainTypes.SET_ERROR, payload: 'Request failed!' });
      enqueueSnackbar(error?.message || 'Unable to fetch data! Please refresh and try again', { variant: 'error' });
    }
  };
export const captainWith17TripsAction =
  (enqueueSnackbar, pageNumber = 1, pageSize = 50, paginated = true) =>
  async (dispatch) => {
    dispatch({ type: CaptainTypes.FETCHING_CAPTAIN });
    try {
      const result = await httpRequest({
        url: `captaintrip/getAll17TripCaptain?PageNumber=${pageNumber}&PageSize=${pageSize}&Descending=true&Paginated=${paginated}`,
        urlType: 'captains',
      });
      if (result.code === 1) {
        dispatch({ type: CaptainTypes.CAPTAIN_WITH_17_TRIPS, payload: result?.data });
      }

      if (result.code !== 1) {
        dispatch({ type: CaptainTypes.SET_ERROR, payload: 'Request failed!' });
        if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
      }
    } catch (error) {
      dispatch({ type: CaptainTypes.SET_ERROR, payload: 'Request failed!' });
      enqueueSnackbar(error?.message || 'Unable to fetch data! Please refresh and try again', { variant: 'error' });
    }
  };
export const captainRecordHistoryAction =
  (enqueueSnackbar, captainId = '', pageNumber = 1, pageSize = 50, paginated = true) =>
  async (dispatch) => {
    dispatch({ type: CaptainTypes.LOADING_CAPTAIN });
    try {
      const result = await httpRequest({
        url: `Captains/statushistory?captainId=${captainId}&PageNumber=${pageNumber}&PageSize=${pageSize}&Descending=true&Paginated=${paginated}`,
        urlType: 'captains',
      });
      if (result.code === 1) {
        dispatch({ type: CaptainTypes.CAPTAIN_RECORD_HISTORY, payload: result?.data });
      }

      if (result.code !== 1) {
        dispatch({ type: CaptainTypes.SET_ERROR, payload: 'Request failed!' });
        if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
      }
    } catch (error) {
      dispatch({ type: CaptainTypes.SET_ERROR, payload: 'Request failed!' });
      enqueueSnackbar(error?.message || 'Unable to fetch data! Please refresh and try again', { variant: 'error' });
    }
  };

export const captainTripReportAction =
  (enqueueSnackbar, startDate = '', endDate = '', pageNumber = 1, pageSize = 50, paginated = true) =>
  async (dispatch) => {
    let filteredURL = `captaintrip/allcaptaintrip?PageNumber=${pageNumber}&PageSize=${pageSize}&Descending=true&Paginated=${paginated}`;
    if (startDate) filteredURL = `${filteredURL}&StartDate=${startDate}`;
    if (endDate) filteredURL = `${filteredURL}&EndDate=${endDate}`;
    // if (query?.activationStatus.length > 0) filteredURL = `${filteredURL}&ActivationStatus=${query?.activationStatus}`;
    // if (query?.captainCode) filteredURL = `${filteredURL}&CaptainCode=${query?.captainCode}`;
    // if (query?.captainId) filteredURL = `${filteredURL}&CaptainId=${query?.captainId}`;

    dispatch({ type: CaptainTypes.LOADING_CAPTAIN });
    try {
      const result = await httpRequest({
        url: filteredURL,
        urlType: 'captains',
      });

      if (result.code === 1) {
        dispatch({ type: CaptainTypes.CAPTAIN_TRIPS_REPORT, payload: result?.data });
      }

      if (result.code !== 1) {
        dispatch({ type: CaptainTypes.SET_ERROR, payload: 'Request failed!' });
        if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
      }
    } catch (error) {
      dispatch({ type: CaptainTypes.SET_ERROR, payload: 'Request failed!' });
      enqueueSnackbar(error?.message || 'Unable to fetch data! Please refresh and try again', { variant: 'error' });
    }
  };

export const captainTripReportDetailAction =
  (enqueueSnackbar, id = '', startDate = '', endDate = '') =>
  async (dispatch) => {
    dispatch({ type: CaptainTypes.LOADING_CAPTAIN });
    try {
      const result = await httpRequest({
        url: `captaintrip/GetVehicleBy?Id=${id}&startDate=${startDate}&endDate=${endDate}`,
        urlType: 'captains',
      });

      if (result.code === 1) {
        dispatch({ type: CaptainTypes.CAPTAIN_TRIPS_REPORT_DETAIL, payload: result?.data });
      }

      if (result.code !== 1) {
        dispatch({ type: CaptainTypes.SET_ERROR, payload: 'Request failed!' });
        if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
      }
    } catch (error) {
      dispatch({ type: CaptainTypes.SET_ERROR, payload: 'Request failed!' });
      enqueueSnackbar(error?.message || 'Unable to fetch data! Please refresh and try again', { variant: 'error' });
    }
  };

export const searchCaptainDeduction =
  (enqueueSnackbar, captainCode = '', startDate = '', endDate = '', pageNumber = 1, pageSize = 50, paginated = true) =>
  async (dispatch) => {
    dispatch({ type: CaptainTypes.LOADING_CAPTAIN });

    let filteredURL = `CaptainDeductions/SearchCaptainDeductions?PageNumber=${pageNumber}&PageSize=${pageSize}&Descending=true&Paginated=${paginated}`;
    if (startDate) filteredURL = `${filteredURL}&StartDate=${startDate}`;
    if (endDate) filteredURL = `${filteredURL}&EndDate=${endDate}`;
    if (captainCode) filteredURL = `${filteredURL}&captainCode=${captainCode}`;

    try {
      const result = await httpRequest({
        url: filteredURL,
        urlType: 'captains',
      });

      if (result.code === 1) {
        dispatch({ type: CaptainTypes.CAPTAIN_DEDUCTION_LIST, payload: result?.data });
      }

      if (result.code !== 1) {
        dispatch({ type: CaptainTypes.LISTING_RESET, payload: 'Request failed!' });
        if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
      }
    } catch (error) {
      dispatch({ type: CaptainTypes.LISTING_RESET, payload: 'Request failed!' });
      enqueueSnackbar(error?.message || 'Unable to fetch data! Please refresh and try again', { variant: 'error' });
    }
  };

export const importedDeduction = (data) => async (dispatch) => {
  dispatch({ type: CaptainTypes.CAPTAIN_DEDUCTION_LIST, payload: data });
};

export const captainSalaryReportAction =
  (enqueueSnackbar, query = {}, pageNumber = 1, pageSize = 50, paginated = true) =>
  async (dispatch) => {
    let filteredURL = `CaptainPayment/captainsalarysummarybystatus?PageNumber=${pageNumber}&PageSize=${pageSize}&Descending=true&Paginated=${paginated}`;
    if (query?.startDate) filteredURL = `${filteredURL}&StartDate=${query?.startDate}`;
    if (query?.endDate) filteredURL = `${filteredURL}&EndDate=${query?.endDate}`;
    if (query?.activationStatus.length > 0) filteredURL = `${filteredURL}&ActivationStatus=${query?.activationStatus}`;
    if (query?.captainCode) filteredURL = `${filteredURL}&CaptainCode=${query?.captainCode}`;
    if (query?.captainId) filteredURL = `${filteredURL}&CaptainId=${query?.captainId}`;

    dispatch({ type: CaptainTypes.LOADING_CAPTAIN });
    try {
      const result = await httpRequest({
        url: filteredURL,
        urlType: 'captains',
      });

      if (result.code === 1) {
        dispatch({ type: CaptainTypes.CAPTAIN_SALARY_REPORT_LIST, payload: result?.data });
      }

      if (result.code !== 1) {
        dispatch({ type: CaptainTypes.SET_ERROR, payload: 'Request failed!' });
        if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
      }
    } catch (error) {
      dispatch({ type: CaptainTypes.SET_ERROR, payload: 'Request failed!' });
      enqueueSnackbar(error?.message || 'Unable to fetch data! Please refresh and try again', { variant: 'error' });
    }
  };

export const captainDetailsAction =
  (enqueueSnackbar, query = {}, pageNumber = 1, pageSize = 50, paginated = true) =>
  async (dispatch) => {
    const url = `CaptainPayment/captainsalarysummaryreportdetails?CaptainCode=${query.captainCode}&StartDate=${query.startDate}&EndDate=${query.endDate}&PageNumber=${pageNumber}&PageSize=${pageSize}&Descending=true&Paginated=${paginated}`;

    dispatch({ type: CaptainTypes.LOADING_CAPTAIN });
    try {
      const result = await httpRequest({
        url,
        urlType: 'captains',
      });

      if (result.code === 1) {
        dispatch({ type: CaptainTypes.CAPTAIN_SALARY_REPORT_DETAILS, payload: result?.data });
      }

      if (result.code !== 1) {
        dispatch({ type: CaptainTypes.SET_ERROR, payload: 'Request failed!' });
        if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
      }
    } catch (error) {
      dispatch({ type: CaptainTypes.SET_ERROR, payload: 'Request failed!' });
      enqueueSnackbar(error?.message || 'Unable to fetch data! Please refresh and try again', { variant: 'error' });
    }
  };
