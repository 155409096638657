import * as AuthTypes from './userTypes';

const initalState = {
  loading: false,
  error: '',
  data: { currentPage: 0, totalPages: 0, pageSize: 0, totalCount: 0, userData: [] },
  userType: { currentPage: 0, totalPages: 0, pageSize: 0, totalCount: 0, userData: [] },
  customerRecordList: [],
  userByTerminalList: [],
  userDetails: [],
};

// eslint-disable-next-line
const userReducer = (state = initalState, action) => {
  switch (action.type) {
    case AuthTypes.FETCHING_USER:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case AuthTypes.FETCHED_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case AuthTypes.FETCHED_USER_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        userType: action.payload,
      };
    case AuthTypes.FETCHING_USER_DETAILS:
      return {
        ...state,
        loading: false,
        userDetails: action.payload,
      };
    case AuthTypes.CUSTOMER_RECORDS:
      return {
        ...state,
        loading: false,
        customerRecordList: action.payload,
      };
    case AuthTypes.FETCHING_USER_BY_TERMINAL:
      return {
        ...state,
        loading: false,
        userByTerminalList: action.payload,
      };

    case AuthTypes.FETCHED_USER_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case AuthTypes.CLEAR_USERS_ERROR:
      return {
        ...state,
        loading: false,
        error: '',
      };
    default:
      return state;
  }
};

export default userReducer;
