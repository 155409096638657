// @mui
import { useEffect, useState } from 'react';
import { Container, Grid, Card, Box, TablePagination, FormControlLabel, Switch } from '@mui/material';
import { useSnackbar } from 'notistack';

// hooks
import useSettings from '../../../../hooks/useSettings';

// components
import Page from '../../../../components/Page';
// sections
import { FilterToolBar } from '../../../../sections/@dashboard/user/list';
import { useDispatch, useSelector } from '../../../../redux/store';
import { getHireServiceReportAction } from '../../../../redux/bookings/bookingsActions';
import MaintenanceIncomeTable from './tables/MaintenanceIncomeTable';

// ----------------------------------------------------------------------

export default function MaintenanceIncome() {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  // Pagination
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(50);
  const [totalCount, setTotalCount] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [userData, setUserData] = useState([]);
  const [dense, setDense] = useState(false);
  const paginated = true;

  const { hireServiceReportList, fetchingBooking } = useSelector((state) => state.bookings);

  useEffect(() => {
    dispatch(getHireServiceReportAction(enqueueSnackbar, '', ''));
  }, []);

  useEffect(() => {
    // if (hireServiceReportList?.userData?.length) {
    const { currentPage, totalPages, pageSize, totalCount, userData = [] } = hireServiceReportList;
    setCurrentPage(currentPage - 1);
    setPageSize(pageSize);
    setTotalPage(totalPages);
    setTotalCount(totalCount);
    setUserData(userData);
    // }
  }, [hireServiceReportList]);

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
    dispatch(getHireServiceReportAction(enqueueSnackbar, startDate, endDate, paginated, newPage + 1, pageSize));
  };

  const handleChangeRowsPerPage = (event) => {
    const newPageSize = parseInt(event.target.value, 10);
    setPageSize(newPageSize);
    setCurrentPage(0); // Reset to the first page
    dispatch(getHireServiceReportAction(enqueueSnackbar, startDate, endDate, paginated, 1, newPageSize));
  };

  const onChangeDense = () => {
    setDense(!dense);
  };

  const { themeStretch } = useSettings();

  return (
    <Page>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <FilterToolBar
          isFilter
          placeholder="Search..."
          filterName={''}
          title="CAPTAIN MAINTENANCE INCOMES"
          subheader="Incomes For Approval"
        />
        <Grid item xs={12}>
          <Card sx={{ px: 3, mt: 2, pt: 2 }}>
            <Grid container spacing={3} mt={1}>
              <Grid item xs={12}>
                <Box>
                  <MaintenanceIncomeTable
                    title="Hire Service Report Details"
                    loading={fetchingBooking}
                    dense={dense}
                    serialNo={currentPage * pageSize + 1}
                    tableData={
                      [
                        // {
                        //   captainCode: 'GIGM0979',
                        //   captainName: 'MICHAEL OJOGBO',
                        //   balance: 20000,
                        //   approvalStatus: 1,
                        //   statusUpdatedBy: 'val@gigm.com',
                        //   reasonForRejecting: '',
                        //   remarks: 'Saved new maintenance income',
                        //   lastUpdated: '25-Nov-2020',
                        // },
                      ]
                    }
                    tableLabels={[
                      { id: 'sn', label: 'S/N', align: 'center' },
                      { id: 'captainCode', label: 'Captain Code', align: 'center', whiteSpace: 'nowrap' },
                      { id: 'captainName', label: 'Captain Name', align: 'center', whiteSpace: 'nowrap' },
                      { id: 'balance', label: 'Balance', align: 'center', whiteSpace: 'nowrap' },
                      { id: 'approvalStatus', label: 'Approval Status', whiteSpace: 'nowrap' },
                      { id: 'statusUpdatedBy', label: 'Status Updated By', align: 'center', whiteSpace: 'nowrap' },
                      {
                        id: 'reasonForRejecting',
                        label: 'Reason For Rejecting',
                        align: 'center',
                        whiteSpace: 'nowrap',
                      },
                      { id: 'remarks', label: 'Remarks', align: 'center' },
                      { id: 'lastUpdated', label: 'Last Updated', align: 'center' },
                    ]}
                  />
                </Box>
                <Box sx={{ position: 'relative' }}>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 20, 50, 100, 200, 500, 1000]}
                    component="div"
                    count={totalCount}
                    page={currentPage}
                    onPageChange={handleChangePage}
                    rowsPerPage={pageSize}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />

                  <FormControlLabel
                    control={<Switch checked={dense} onChange={onChangeDense} />}
                    label="Dense"
                    sx={{ px: 3, py: 1.5, top: 0, position: { md: 'absolute' } }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Container>
    </Page>
  );
}
