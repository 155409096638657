import * as ModalTypes from './modalTypes';

const initalState = {
  loading: false,
  error: '',
  data: null,
  isOpenModal: false,
  modalType: '',
};

// eslint-disable-next-line
const modalReducer = (state = initalState, action) => {
  switch (action.type) {
    case ModalTypes.MODAL_OPEN:
      return {
        ...state,
        isOpenModal: true,
        modalType: action.payload.type,
        modalData: action.payload.data,
      };

    case ModalTypes.MODAL_CLOSE:
      return {
        ...state,
        isOpenModal: false,
        modalType: '',
        modalData: null,
      };
    default:
      return state;
  }
};

export default modalReducer;
