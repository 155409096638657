import PropTypes from 'prop-types';
// @mui
import { Box, Table, TableRow, TableBody, TableCell, TableContainer } from '@mui/material';
// components
import { useState } from 'react';
import Scrollbar from '../../../../../components/Scrollbar';
import { TableHeadCustom, TableNoData } from '../../../../../components/table';
import CustomLoader from '../../../../../components/CustomLoader';

// ----------------------------------------------------------------------

CaptainRatingBonusTable.propTypes = {
  loading: PropTypes.bool,
  tableData: PropTypes.array,
  dense: PropTypes.any,
  serialNo: PropTypes.number,
  tableLabels: PropTypes.array,
};

export default function CaptainRatingBonusTable({ loading, tableLabels, tableData, dense, serialNo }) {
  const [selected, setSelected] = useState(0);
  return (
    <Scrollbar>
      <TableContainer sx={{ minWidth: 720 }} style={{ maxHeight: '70vh' }}>
        <Table size={dense ? 'small' : 'medium'} stickyHeader aria-label="sticky table">
          <TableHeadCustom headLabel={tableLabels} />
          <TableBody>
            {loading ? (
              <Box component="tr" sx={{ height: '60vh' }}>
                <CustomLoader />
              </Box>
            ) : (
              tableData.map((row, i) => (
                <CaptainRatingBonusRow
                  selected={selected}
                  setSelected={setSelected}
                  key={i}
                  row={row}
                  serialNo={serialNo + i}
                />
              ))
            )}

            {!loading && tableData.length === 0 ? <TableNoData isNotFound /> : ''}
          </TableBody>
        </Table>
      </TableContainer>
    </Scrollbar>
  );
}

// ----------------------------------------------------------------------

CaptainRatingBonusRow.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.any,
    captainCode: PropTypes.string,
    amountApproved: PropTypes.number,
    dateCreated: PropTypes.string,
    dateApproved: PropTypes.string,
    dateModified: PropTypes.string,
  }),
  serialNo: PropTypes.number,
};

function CaptainRatingBonusRow({ row, serialNo }) {
  return (
    <TableRow>
      <TableCell align="left">{serialNo}</TableCell>
      <TableCell align="center">{row?.captainCode}</TableCell>
      <TableCell align="center">{row?.amountApproved}</TableCell>
      <TableCell align="center">{row?.dateCreated}</TableCell>
      <TableCell align="center">{row?.dateApproved}</TableCell>
      <TableCell align="center">{row?.dateModified}</TableCell>
    </TableRow>
  );
}
