// @mui
import { useEffect, useState } from 'react';
import { Container, Grid, Card, Box, TablePagination, FormControlLabel, Switch } from '@mui/material';
import { useSnackbar } from 'notistack';

// hooks
import useSettings from '../../../../hooks/useSettings';

// components
import Page from '../../../../components/Page';
// sections
import { FilterToolBar } from '../../../../sections/@dashboard/user/list';
import { useDispatch, useSelector } from '../../../../redux/store';
import { getHireServiceReportAction } from '../../../../redux/bookings/bookingsActions';
import DeductionsForm from './forms/DeductionsForm';
import CaptainBouseTable from './tables/CaptainBonusTable';

// ----------------------------------------------------------------------

export default function CaptainBonuses() {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  // Pagination
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(50);
  const [totalCount, setTotalCount] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [userData, setUserData] = useState([]);
  const [dense, setDense] = useState(false);
  const paginated = true;

  const { hireServiceReportList, fetchingBooking } = useSelector((state) => state.bookings);

  useEffect(() => {
    dispatch(getHireServiceReportAction(enqueueSnackbar, '', ''));
  }, []);

  useEffect(() => {
    // if (hireServiceReportList?.userData?.length) {
    const { currentPage, totalPages, pageSize, totalCount, userData = [] } = hireServiceReportList;
    setCurrentPage(currentPage - 1);
    setPageSize(pageSize);
    setTotalPage(totalPages);
    setTotalCount(totalCount);
    setUserData(userData);
    // }
  }, [hireServiceReportList]);

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
    dispatch(getHireServiceReportAction(enqueueSnackbar, startDate, endDate, paginated, newPage + 1, pageSize));
  };

  const handleChangeRowsPerPage = (event) => {
    const newPageSize = parseInt(event.target.value, 10);
    setPageSize(newPageSize);
    setCurrentPage(0); // Reset to the first page
    dispatch(getHireServiceReportAction(enqueueSnackbar, startDate, endDate, paginated, 1, newPageSize));
  };

  const onChangeDense = () => {
    setDense(!dense);
  };

  const { themeStretch } = useSettings();

  return (
    <Page>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <FilterToolBar
          isFilter={false}
          placeholder="Search..."
          filterName={''}
          title="All Captains Bonuses"
          subheader="Other Incomes Details"
        />
        <Grid item xs={12}>
          <Card sx={{ px: 3, mt: 2, pt: 2 }}>
            <DeductionsForm
              getDateInfo={(d) => {
                setStartDate(d?.startDate);
                setEndDate(d?.endDate);
              }}
            />
            <Grid container spacing={3} mt={1}>
              <Grid item xs={12}>
                <Box>
                  <CaptainBouseTable
                    loading={fetchingBooking}
                    dense={dense}
                    serialNo={currentPage * pageSize + 1}
                    tableData={
                      [
                        // {
                        //   captainCode: 'GIGM0979',
                        //   remark: 'SECURITY DEPOSIT',
                        //   amount: 20000,
                        //   createdDate: '6/19/2023 1:31:12 PM',
                        //   dateModified: '6/19/2023 1:31:12 PM',
                        //   approvalRejectBy: 'adedotun.oluwole@thegiggroupng.com ',
                        //   approvalComment: '',
                        //   dateApproved: '6/19/2023 1:40:19 PM',
                        // },
                      ]
                    }
                    tableLabels={[
                      { id: 'sn', label: 'S/N', align: 'center' },
                      { id: 'captainCode', label: 'Captain Code', align: 'center', whiteSpace: 'nowrap' },
                      { id: 'remarks', label: 'Remarks', align: 'center', whiteSpace: 'nowrap' },
                      { id: 'amount', label: 'Amount', align: 'center', whiteSpace: 'nowrap' },
                      { id: 'dateCreated', label: 'Date Created', whiteSpace: 'nowrap' },
                      { id: 'dateModified', label: 'Date Modified', align: 'center', whiteSpace: 'nowrap' },
                      { id: 'approvalStatus', label: 'Approval Status', align: 'center', whiteSpace: 'nowrap' },
                      { id: 'changeStatus', label: 'Change Status', align: 'center' },
                      { id: 'approveReject', label: 'Approve/Rejected By', align: 'center' },
                      { id: 'approvalComment', label: 'Approval Comment', align: 'center' },
                      { id: 'dateApproved', label: 'Date Approved', align: 'center' },
                      { id: 'action', label: 'Action', align: 'center' },
                    ]}
                  />
                </Box>
                <Box sx={{ position: 'relative' }}>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 20, 50, 100, 200, 500, 1000]}
                    component="div"
                    count={totalCount}
                    page={currentPage}
                    onPageChange={handleChangePage}
                    rowsPerPage={pageSize}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />

                  <FormControlLabel
                    control={<Switch checked={dense} onChange={onChangeDense} />}
                    label="Dense"
                    sx={{ px: 3, py: 1.5, top: 0, position: { md: 'absolute' } }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Container>
    </Page>
  );
}
