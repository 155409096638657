export const LOADING_SETTINGS = 'LOADING_SETTINGS';
export const FETCHING_SETTINGS = 'FETCHING_SETTINGS';
export const BANK_LIST = 'BANK_LIST';
export const DEPARTMENT_LIST = 'DEPARTMENT_LIST';
export const GET_VEHICLE_LIST = 'GET_VEHICLE_LIST';
export const GET_VEHICLE_LIST_ID = 'GET_VEHICLE_LIST_ID';
export const VEHICLE_MAKE_LISTING = 'VEHICLE_MAKE_LISTING';
export const VEHICLE_FEATURES_LISTING = 'VEHICLE_FEATURES_LISTING';
export const GET_VEHICLE_MODEL_LIST = 'GET_VEHICLE_MODEL_LIST';
export const GET_AVAILABLE_VEHICLES = 'GET_AVAILABLE_VEHICLES';
export const GET_ALL_CAPTAIN_LISTING = 'GET_ALL_CAPTAIN_LISTING';
export const CAPTAIN_LISTING = 'CAPTAIN_LISTING';
export const CAPTAIN_RECORD = 'CAPTAIN_RECORD';
export const CAPTAIN_TRIP_HISTORY = 'CAPTAIN_TRIP_HISTORY';
export const CAPTAIN_CLASSIFICATION_LIST = 'CAPTAIN_CLASSIFICATION_LIST';
export const ASSIGN_PRIMARY_CAPTAIN = 'ASSIGN_PRIMARY_CAPTAIN';
export const GET_PRIMARY_CAPTAIN = 'GET_PRIMARY_CAPTAIN';
export const GET_HAND_OVER_CAPTAIN = 'GET_HAND_OVER_CAPTAIN';
export const HAND_OVER_CAPTAIN_LIST = 'HAND_OVER_CAPTAIN_LIST';
export const GET_PARTNER_LIST = 'GET_PARTNER_LIST';
export const GET_PARTNER_REQUEST_LIST = 'GET_PARTNER_REQUEST_LIST';
export const GET_PARTNER_TRANSACTION_LIST = 'GET_PARTNER_TRANSACTION_LIST';
export const GET_PARTNER_PAYOUT_LIST = 'GET_PARTNER_PAYOUT_LIST';
export const GET_PARTNER_TYPE = 'GET_PARTNER_TYPE';
export const DOCUMENT_TYPE = 'DOCUMENT_TYPE';
export const DOCUMENT_TYPE_FEE = 'DOCUMENT_TYPE_FEE';
export const VEHICLE_IN_TERMINAL_LIST = 'VEHICLE_IN_TERMINAL_LIST';
export const GET_CAPTAIN_VALIDITY = 'GET_CAPTAIN_VALIDITY';
export const GET_SUB_ROUTE_LIST = 'GET_SUB_ROUTE_LIST';
export const SUB_ROUTE_LIST_BY_ROUTE_ID = 'SUB_ROUTE_LIST_BY_ROUTE_ID';
export const ROUTE_TYPE_LIST = 'ROUTE_TYPE_LIST';
export const GET_ROUTE_BY_TERMINAL_ID = 'GET_ROUTE_BY_TERMINAL_ID';
export const GET_VIRTUAL_BUS_BY_ROUTE = 'GET_VIRTUAL_BUS_BY_ROUTE';
export const GET_TERMINAL_LIST = 'GET_TERMINAL_LIST';
export const GET_STATE_LIST = 'GET_STATE_LIST';
export const TERMINAL_BY_DEPARTURE_SUCCESS = 'TERMINAL_BY_DEPARTURE_SUCCESS';
export const FIND_REGION_BY_COUNTRY = 'FIND_REGION_BY_COUNTRY';
export const FIND_STATE_BY_REGION = 'FIND_STATE_BY_REGION';
export const FIND_TERMINAL_BY_STATE = 'FIND_TERMINAL_BY_STATE';
export const FIND_ROUTE_BY_TERMINAL = 'FIND_ROUTE_BY_TERMINAL';
export const FIND_TRIPS_BY_ROUTE = 'FIND_TRIPS_BY_ROUTE';
export const PAYMENT_GATE_LIST = 'PAYMENT_GATE_LIST';
export const EXTENDED_DISCOUNT_LIST = 'EXTENDED_DISCOUNT_LIST';
export const COUPON_LIST = 'COUPON_LIST';
export const STORE_LIST = 'STORE_LIST';
export const VENDOR_LIST = 'VENDOR_LIST';
export const VEHICLE_PART_LIST = 'VEHICLE_PART_LIST';
export const VEHICLE_PART_POSITION = 'VEHICLE_PART_POSITION';
export const DATABASE_LOG = 'DATABASE_LOG';
export const USER_DATABASE_LOG = 'USER_DATABASE_LOG';
export const AZURE_BUG_LOG = 'AZURE_BUG_LOG';
export const AZURE_BUG_LOG_BY_ID = 'AZURE_BUG_LOG_BY_ID';
export const EMPLOYEE_ROUTE = 'EMPLOYEE_ROUTE';
export const CAMPUS_AMBASSADOR_LIST = 'CAMPUS_AMBASSADOR_LIST';
export const CAMPUS_AMBASSADOR_DETAIL = 'CAMPUS_AMBASSADOR_DETAIL';
export const AMBASSADOR_BOOKING_HISTORY = 'AMBASSADOR_BOOKING_HISTORY';
export const AMBASSADOR_TICKET_BOOKING = 'AMBASSADOR_TICKET_BOOKING';
export const AMBASSADOR_WITHDRAWAL_REQUEST = 'AMBASSADOR_WITHDRAWAL_REQUEST';
export const SUSPENDED_INACTIVE_CAPTAINS = 'SUSPENDED_INACTIVE_CAPTAINS';
export const LOCKED_OUT_CAPTAINS = 'LOCKED_OUT_CAPTAINS';
export const DAILY_PERFORMANCE_REPORT = 'DAILY_PERFORMANCE_REPORT';
export const VEHICLE_REVENUE_REPORT = 'VEHICLE_REVENUE_REPORT';
export const BOOKING_CUSTOMER_ANALYSIS = 'BOOKING_CUSTOMER_ANALYSIS';
export const TRAVEL_DOC_TYPE_LIST = 'TRAVEL_DOC_TYPE_LIST';
export const TRAVEL_DOC_TYPE_WITH_AMOUNT_LIST = 'TRAVEL_DOC_TYPE_WITH_AMOUNT_LIST';
export const BANNER_LIST = 'BANNER_LIST';

export const LISTING_RESET = 'LISTING_RESET';
export const LOGOUT = 'LOGOUT';
