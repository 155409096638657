// @mui
import { useEffect, useState } from 'react';
import { Container, Grid, Card, Box, TablePagination, FormControlLabel, Switch } from '@mui/material';
import { useSnackbar } from 'notistack';

// hooks
import useSettings from '../../../../hooks/useSettings';

// components
import Page from '../../../../components/Page';
// sections
import { FilterToolBar } from '../../../../sections/@dashboard/user/list';
import { useDispatch, useSelector } from '../../../../redux/store';
import { getHireServiceReportAction } from '../../../../redux/bookings/bookingsActions';
import CaptainRatingTable from './tables/CaptainRatingTable';
import CaptainRatingApprovalForm from './forms/CaptainRatingApprovalForm';

// ----------------------------------------------------------------------

export default function CaptianRatingApproval() {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  // Pagination
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(50);
  const [totalCount, setTotalCount] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [userData, setUserData] = useState([]);
  const [dense, setDense] = useState(false);
  const paginated = true;

  const { hireServiceReportList, fetchingBooking } = useSelector((state) => state.bookings);

  useEffect(() => {
    dispatch(getHireServiceReportAction(enqueueSnackbar, '', ''));
  }, []);

  useEffect(() => {
    // if (hireServiceReportList?.userData?.length) {
    const { currentPage, totalPages, pageSize, totalCount, userData = [] } = hireServiceReportList;
    setCurrentPage(currentPage - 1);
    setPageSize(pageSize);
    setTotalPage(totalPages);
    setTotalCount(totalCount);
    setUserData(userData);
    // }
  }, [hireServiceReportList]);

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
    dispatch(getHireServiceReportAction(enqueueSnackbar, '', 'endDate', paginated, newPage + 1, pageSize));
  };

  const handleChangeRowsPerPage = (event) => {
    const newPageSize = parseInt(event.target.value, 10);
    setPageSize(newPageSize);
    setCurrentPage(0); // Reset to the first page
    dispatch(getHireServiceReportAction(enqueueSnackbar, '', 'endDate', paginated, 1, newPageSize));
  };

  const onChangeDense = () => {
    setDense(!dense);
  };

  const { themeStretch } = useSettings();

  return (
    <Page>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <FilterToolBar
          isFilter={false}
          placeholder="Search..."
          filterName={''}
          title="Captains Rating Approval"
          subheader="Captain Rating Summary"
        />
        <Grid item xs={12}>
          <Card sx={{ px: 3, mt: 2, pt: 2 }}>
            <CaptainRatingApprovalForm />
            <Grid container spacing={3} mt={1}>
              <Grid item xs={12}>
                <Box>
                  <CaptainRatingTable
                    title="Hire Service Report Details"
                    loading={fetchingBooking}
                    dense={dense}
                    serialNo={currentPage * pageSize + 1}
                    tableData={
                      [
                        // {
                        //   captainName: 'DANIEL OLOMAH',
                        //   captainDetails: 'DANIEL OLOMAH - LITE 0042',
                        //   status: 'Idle',
                        //   ratingAmount: 2000,
                        // },
                      ]
                    }
                    tableLabels={[
                      { id: 'sn', label: 'S/N', align: 'center' },
                      { id: 'captainName', label: 'Captain Name', align: 'center', whiteSpace: 'nowrap' },
                      { id: 'captainDetails', label: 'Captain Details', whiteSpace: 'nowrap' },
                      { id: 'status', label: 'Status', align: 'center', whiteSpace: 'nowrap' },
                      { id: 'ratingAmount', label: 'Rating Amount', align: 'center', whiteSpace: 'nowrap' },
                      { id: 'approval', label: 'Approval', align: 'center' },
                    ]}
                  />
                </Box>
                <Box sx={{ position: 'relative' }}>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 20, 50, 100, 200, 500, 1000]}
                    component="div"
                    count={totalCount}
                    page={currentPage}
                    onPageChange={handleChangePage}
                    rowsPerPage={pageSize}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />

                  <FormControlLabel
                    control={<Switch checked={dense} onChange={onChangeDense} />}
                    label="Dense"
                    sx={{ px: 3, py: 1.5, top: 0, position: { md: 'absolute' } }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Container>
    </Page>
  );
}
