import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

// form
import { useSnackbar } from 'notistack';

// @mui
import { LoadingButton } from '@mui/lab';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { Autocomplete, Box, Card, Grid, Stack, TextField } from '@mui/material';

// components
import { getHireServiceReportAction } from '../../../../../redux/bookings/bookingsActions';
import { useDispatch, useSelector } from '../../../../../redux/store';

import Iconify from '../../../../../components/Iconify';
import { getAllCaptainByAction } from '../../../../../redux/settings/settingsActions';
import { CLASS_TYPE } from '../../../../../utils';

// import Chip from '../../../theme/overrides/Chip';

// ----------------------------------------------------------------------

DeductionsCreateForm.propTypes = {
  getDateInfo: PropTypes.func,
};

export default function DeductionsCreateForm({ getDateInfo }) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const nextDay = new Date();
  nextDay.setDate(nextDay.getDate() + 1);

  const { fetchingBooking } = useSelector((state) => state.bookings);

  const [captainCode, setCaptainCode] = useState('');
  const [amount, setAmount] = useState('');
  const [remark, setRemark] = useState('');
  const [earningType, setEarningType] = useState('');

  const {
    allCaptainList: { userData: allCaptainList },
  } = useSelector((state) => state.settings);

  const handleSubmit = () => {
    dispatch(
      getHireServiceReportAction(
        enqueueSnackbar
        // new Date(startDate).toDateString(),
        // new Date(endDate).toLocaleString()
      )
    );

    getDateInfo({
      // startDate: new Date(startDate).toDateString(),
      // endDate: new Date(endDate).toDateString(),
    });
  };

  useEffect(() => {
    dispatch(getAllCaptainByAction(enqueueSnackbar));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container spacing={3} sx={{ p: 3 }}>
      <Grid item xs={12}>
        {/* <Card sx={{ p: 3 }}> */}
        <Box>
          <Box
            sx={{
              marginTop: 3,
              display: 'grid',
              columnGap: 2,
              rowGap: 3,
              gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(1, 1fr)' },
            }}
          >
            <Autocomplete
              value={captainCode}
              inputValue={captainCode}
              onInputChange={(event, newInputValue) => {
                setCaptainCode(newInputValue);
              }}
              includeInputInList
              id="controllable-states-demo"
              options={allCaptainList?.length ? ['', ...new Set(allCaptainList.map((c) => c?.captainCode || '-'))] : []}
              renderInput={(params) => <TextField {...params} label="Select a Captain" />}
            />
            <Autocomplete
              value={earningType}
              inputValue={earningType}
              onInputChange={(event, newInputValue) => {
                setEarningType(newInputValue);
              }}
              size="small"
              includeInputInList
              id="controllable-states-demo"
              options={['', ...CLASS_TYPE]}
              renderInput={(params) => <TextField {...params} label="EARNING TYPE" />}
            />
            <TextField
              autoComplete="storeName"
              value={amount}
              onChange={({ target }) => setAmount(target.value)}
              fullWidth
              id="outlined-basic"
              label="Amount"
              variant="outlined"
              className="mb-4"
            />
          </Box>
          <Box sx={{ paddingY: 1 }}>
            <textarea
              value={remark}
              onChange={({ target }) => setRemark(target.value)}
              id="outlined-basic"
              placeholder="Remark"
              style={{
                width: '100%',
                padding: '12px',
                borderRadius: '10px',
                outline: 'none', // Remove the outline
                border: '1px solid #ccc', // Add a gray border
                resize: 'none', // Prevent resizing
              }}
            />
          </Box>
        </Box>

        <Stack alignItems="flex-end" sx={{ mt: 3 }}>
          <LoadingButton type="button" onClick={handleSubmit} variant="contained" loading={fetchingBooking}>
            Create
          </LoadingButton>
        </Stack>
        {/* </Card> */}
      </Grid>
    </Grid>
  );
}
