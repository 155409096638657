import PropTypes from 'prop-types';
// @mui
import { Box, Table, TableRow, TableBody, TableCell, TableContainer } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import { useState } from 'react';
import Iconify from '../../../../../components/Iconify';
import Scrollbar from '../../../../../components/Scrollbar';
import { TableHeadCustom, TableNoData } from '../../../../../components/table';
import CustomLoader from '../../../../../components/CustomLoader';

// ----------------------------------------------------------------------

CaptainRatingTable.propTypes = {
  loading: PropTypes.bool,
  tableData: PropTypes.array,
  dense: PropTypes.any,
  serialNo: PropTypes.number,
  tableLabels: PropTypes.array,
};

export default function CaptainRatingTable({ loading, tableLabels, tableData, dense, serialNo }) {
  const [selected, setSelected] = useState(0);
  return (
    <Scrollbar>
      <TableContainer sx={{ minWidth: 720 }} style={{ maxHeight: '70vh' }}>
        <Table size={dense ? 'small' : 'medium'} stickyHeader aria-label="sticky table">
          <TableHeadCustom headLabel={tableLabels} />
          <TableBody>
            {loading ? (
              <Box component="tr" sx={{ height: '60vh' }}>
                <CustomLoader />
              </Box>
            ) : (
              tableData.map((row, i) => (
                <CaptainRatingRow
                  selected={selected}
                  setSelected={setSelected}
                  key={i}
                  row={row}
                  serialNo={serialNo + i}
                />
              ))
            )}

            {!loading && tableData.length === 0 ? <TableNoData isNotFound /> : ''}
          </TableBody>
        </Table>
      </TableContainer>
    </Scrollbar>
  );
}

// ----------------------------------------------------------------------

CaptainRatingRow.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.any,
    captainName: PropTypes.string,
    captainDetails: PropTypes.string,
    status: PropTypes.string,
    ratingAmount: PropTypes.number,
  }),
  serialNo: PropTypes.number,
};

function CaptainRatingRow({ row, serialNo }) {
  return (
    <TableRow>
      <TableCell align="left">{serialNo}</TableCell>
      <TableCell align="center">{row?.captainName}</TableCell>
      <TableCell align="center">{row?.captainDetails}</TableCell>
      <TableCell align="center">{row?.status}</TableCell>
      <TableCell align="center">{row?.ratingAmount}</TableCell>
      <TableCell align="right">
        <LoadingButton
          color="error"
          type="button"
          variant="contained"
          loading={false}
          sx={{ whiteSpace: 'nowrap', py: 1 }}
        >
          <Iconify icon={'eva:checkmark-square-2-fill'} width={20} height={26} mr={1} /> Approve
        </LoadingButton>
      </TableCell>
    </TableRow>
  );
}
