import PropTypes from 'prop-types';
// @mui
import { List, Box, TextField } from '@mui/material';
import { useState } from 'react';
// hooks
import useLocales from '../../../hooks/useLocales';
//
import { ListSubheaderStyle } from './style';
import NavList from './NavList';

// ----------------------------------------------------------------------

NavSectionVertical.propTypes = {
  isCollapse: PropTypes.bool,
  navConfig: PropTypes.array,
};

export default function NavSectionVertical({ navConfig, isCollapse, ...other }) {
  const { translate } = useLocales();
  const [search, setSearch] = useState('');

  const filterItems = (items, query) =>
    items
      .map((item) => {
        if (item.children) {
          const filteredChildren = filterItems(item.children, query);
          if (filteredChildren.length > 0) {
            return { ...item, children: filteredChildren };
          }
        }
        if (item.title.toLowerCase().includes(query.toLowerCase())) {
          return item;
        }
        return null;
      })
      .filter((item) => item !== null);

  const filteredNavConfig = navConfig
    .map((group) => {
      const filteredItems = filterItems(group.items, search);
      return { ...group, items: filteredItems };
    })
    .filter((group) => group.items.length > 0);

  return (
    <Box {...other}>
      <Box sx={{ px: 3 }}>
        <TextField
          autoComplete="search"
          value={search}
          onChange={({ target }) => setSearch(target.value)}
          fullWidth
          size="small"
          id="outlined-basic"
          label="Search Menu"
          variant="outlined"
          sx={{ mb: 2 }}
        />
      </Box>

      {filteredNavConfig.map((group) => (
        <List key={group.subheader} disablePadding sx={{ px: 2 }}>
          <ListSubheaderStyle
            sx={{
              ...(isCollapse && {
                opacity: 0,
              }),
            }}
          >
            {translate(group.subheader)}
          </ListSubheaderStyle>

          {group.items.map((list) => (
            <NavList
              key={list.title + list.path}
              data={list}
              depth={1}
              hasChildren={!!list.children}
              isCollapse={isCollapse}
            />
          ))}
        </List>
      ))}
    </Box>
  );
}
