/* eslint-disable no-unused-vars */
// @mui
import { useEffect, useState } from 'react';
import {
  Container,
  Grid,
  Card,
  Box,
  TablePagination,
  FormControlLabel,
  Switch,
  DialogTitle,
  Button,
  Stack,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';

// hooks
import { LoadingButton } from '@mui/lab';
import useSettings from '../../../../hooks/useSettings';

// components
import Page from '../../../../components/Page';
// sections
import { FilterToolBar } from '../../../../sections/@dashboard/user/list';
import { useDispatch, useSelector } from '../../../../redux/store';
import DeductionsForm from './forms/DeductionsForm';
import DeductionsTable from './tables/DeductionsTable';
import { DialogAnimate } from '../../../../components/animate';
import * as ModalTypes from '../../../../redux/modal/modalTypes';
import DeductionsCreateForm from './forms/DeductionsCreateForm';
import Iconify from '../../../../components/Iconify';
import DeductionsImportForm from './forms/DeductionsImportForm';

// ----------------------------------------------------------------------

export default function Deductions() {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { themeStretch } = useSettings();
  const { filteredData } = useSelector((state) => state.search);
  const { isOpenModal, modalType, modalData } = useSelector((state) => state.modal);

  const handleCloseModal = () => {
    dispatch({ type: ModalTypes.MODAL_CLOSE });
  };
  const handleAddEvent = (action) => {
    dispatch({ type: ModalTypes.MODAL_OPEN, payload: action });
  };
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  // Pagination
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(50);
  const [totalCount, setTotalCount] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [userData, setUserData] = useState([]);
  const [dense, setDense] = useState(false);
  const paginated = true;

  const { captainDeductionList, loading } = useSelector((state) => state.captains);

  useEffect(() => {
    if (captainDeductionList?.userData?.length) {
      const { currentPage, totalPages, pageSize, totalCount, userData = [] } = captainDeductionList;
      setCurrentPage(currentPage - 1);
      setPageSize(pageSize);
      setTotalPage(totalPages);
      setTotalCount(totalCount);
      setUserData(userData);
    } else {
      setUserData([]);
    }
  }, [captainDeductionList]);

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
    // dispatch(actionMethod(enqueueSnackbar, startDate, endDate, paginated, newPage + 1, pageSize));
  };

  const handleChangeRowsPerPage = (event) => {
    const newPageSize = parseInt(event.target.value, 10);
    setPageSize(newPageSize);
    setCurrentPage(0); // Reset to the first page
    // dispatch(actionMethod(enqueueSnackbar, startDate, endDate, paginated, 1, newPageSize));
  };

  const onChangeDense = () => {
    setDense(!dense);
  };

  useEffect(() => {
    setUserData(filteredData);
  }, [filteredData]);

  const csvHeader = [
    { key: 'routeName', label: 'Route' },
    { key: 'routeTypeName', label: 'Route Type' },
    { key: 'captainFee', label: 'Captain Fee' },
    { key: 'dispatchFee', label: 'Dispatch Fee' },
    { key: 'loaderFee', label: 'Loader Fee' },
    { key: 'routeDistance', label: 'Route Distance (km)' },
    { key: 'availableAtTerminal', label: 'Available At Terminal' },
    { key: 'availableOnline', label: 'Available Online' },
  ];
  return (
    <Page>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <FilterToolBar
          isFilter={false}
          placeholder="Search All Deduction..."
          title="All Captains Deductions"
          subheader="Captain Details"
          buttonDetails={{ isButton: true, label: 'Create', icon: 'plus-outline' }}
          csvHeader={csvHeader}
          exportData={userData}
          exportFileName="advanceBookingReportDetail"
          isShowExportBtn
          filterKeys={['bookingReferenceCode', 'vehicleRegNumber', 'employeeName']}
        />
        <Box sx={{ display: 'flex', justifyContent: 'end' }}>
          {/* <LoadingButton
            type="button"
            onClick={() => handleAddEvent({ type: 'import', data: '' })}
            variant="contained"
            sx={{ marginRight: 2 }}
          >
            <Iconify icon={'hugeicons:view'} sx={{ marginRight: 1, fontSize: '18px' }} /> View Table
          </LoadingButton> */}
          <LoadingButton
            type="button"
            onClick={() => handleAddEvent({ type: 'import', data: '' })}
            variant="contained"
            // loading={fetchingBooking}
          >
            <Iconify icon={'solar:import-linear'} sx={{ marginRight: 1, fontSize: '18px' }} /> Import Excel
          </LoadingButton>
        </Box>
        <Grid item xs={12}>
          <Card sx={{ px: 3, mt: 2, pt: 2 }}>
            <DeductionsForm
              getDateInfo={(d) => {
                setStartDate(d?.startDate);
                setEndDate(d?.endDate);
              }}
            />
            <Grid container spacing={3} mt={1}>
              <Grid item xs={12}>
                <Box>
                  {userData?.length > 1 ? (
                    <Box mb={2} ml={2}>
                      <LoadingButton
                        style={{ marginRight: 8 }}
                        type="button"
                        onClick={''}
                        variant="contained"
                        loading={false}
                        color="success"
                      >
                        <Iconify icon={'eva:checkmark-square-2-outline'} sx={{ marginRight: 1, fontSize: '18px' }} />{' '}
                        Approve All
                      </LoadingButton>
                      <LoadingButton type="button" onClick={''} variant="contained" loading={false}>
                        <Iconify icon={'eva:close-fill'} sx={{ marginRight: 1, fontSize: '18px' }} /> Decline All
                      </LoadingButton>
                    </Box>
                  ) : (
                    ''
                  )}
                  <DeductionsTable
                    title="Deduction Details"
                    loading={loading}
                    dense={dense}
                    serialNo={currentPage * pageSize + 1}
                    tableData={userData || []}
                    tableLabels={[
                      { id: 'sn', label: 'S/N', align: 'center' },
                      { id: 'captainCode', label: 'Captain Code', align: 'center', whiteSpace: 'nowrap' },
                      { id: 'remarks', label: 'Remarks', align: 'center', whiteSpace: 'nowrap' },
                      { id: 'amount', label: 'Amount', align: 'center', whiteSpace: 'nowrap' },
                      { id: 'dateCreated', label: 'Date Created', whiteSpace: 'nowrap' },
                      { id: 'dateModified', label: 'Date Modified', align: 'center', whiteSpace: 'nowrap' },
                      { id: 'approvalStatus', label: 'Approval Status', align: 'center', whiteSpace: 'nowrap' },
                      // { id: 'changeStatus', label: 'Change Status', align: 'center' },
                      { id: 'approveReject', label: 'Approve/Rejected By', align: 'center' },
                      { id: 'approvalComment', label: 'Approval Comment', align: 'center' },
                      { id: 'dateApproved', label: 'Date Approved', align: 'center' },
                      { id: 'action', label: 'Action', align: 'center' },
                    ]}
                  />
                </Box>
                <Box sx={{ position: 'relative' }}>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 20, 50, 100, 200, 500, 1000]}
                    component="div"
                    count={totalCount}
                    page={currentPage}
                    onPageChange={handleChangePage}
                    rowsPerPage={pageSize}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />

                  <FormControlLabel
                    control={<Switch checked={dense} onChange={onChangeDense} />}
                    label="Dense"
                    sx={{ px: 3, py: 1.5, top: 0, position: { md: 'absolute' } }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Card>
          <DialogAnimate open={isOpenModal} onClose={handleCloseModal}>
            {modalType === 'create' && (
              <DialogTitle sx={{ backgroundColor: '#AA1D00', color: 'white', m: 0, pb: 2 }}>Remove Cash</DialogTitle>
            )}
            {modalType === 'import' && (
              <DialogTitle
                sx={{ backgroundColor: '#AA1D00', color: 'white', m: 0, pb: 2, border: '5px solid #AA1D00' }}
              >
                Upload File
              </DialogTitle>
            )}
            {modalType === 'approve_decline' && (
              <DialogTitle
                sx={{ backgroundColor: '#AA1D00', color: 'white', m: 0, pb: 2, border: '5px solid #AA1D00' }}
              >
                Approve/Decline Deduction
              </DialogTitle>
            )}

            <hr style={{ backgroundColor: '#AA1D00', color: 'white', m: 0, p: 0 }} />

            {modalType === 'create' && (
              <DeductionsCreateForm
                modalData={{}}
                // userType={userType}
                // terminalList={terminalList}
                handleCloseModal={handleCloseModal}
                isCreateStore
              />
            )}
            {modalType === 'import' && <DeductionsImportForm handleCloseModal={handleCloseModal} />}
            {modalType === 'approve_decline' && (
              <Grid container spacing={3} sx={{ px: 3 }}>
                <Grid item xs={12}>
                  <Box my={2}>
                    <Typography fontWeight="bold">Are you sure you want to approve this request?</Typography>
                  </Box>
                  <Box display="flex" justifyContent="flex-end" sx={{ mt: 3 }}>
                    <Button sx={{ mr: 1 }} variant="outlined" color="error" onClick={handleCloseModal}>
                      Close
                    </Button>
                    <LoadingButton type="button" onClick={''} variant="contained" loading={loading}>
                      Confirm
                    </LoadingButton>
                  </Box>
                </Grid>
              </Grid>
            )}

            <Box display="flex" justifyContent="flex-end" sx={{ padding: 3 }}>
              {modalType === 'view' && (
                <Button variant="outlined" color="error" onClick={handleCloseModal}>
                  Close
                </Button>
              )}
            </Box>
          </DialogAnimate>
        </Grid>
      </Container>
    </Page>
  );
}
