import PropTypes from 'prop-types';
import { m } from 'framer-motion';
// @mui
import { Container, Typography } from '@mui/material';
import { useSelector } from 'react-redux';

// components
import { MotionContainer, varBounce } from '../components/animate';
// assets
import { ForbiddenIllustration } from '../assets';

// ----------------------------------------------------------------------

RoleBasedGuard.propTypes = {
  hasContent: PropTypes.bool,
  roles: PropTypes.arrayOf(PropTypes.string), // Example ['admin', 'leader']
  children: PropTypes.node.isRequired,
  showNoAccessText: PropTypes.bool,
};

export default function RoleBasedGuard({ hasContent, roles, children, showNoAccessText = false }) {
  // Logic here to get current user role
  const {
    user: { rolesDTOs },
  } = useSelector((state) => state.auth);

  // const currentRole = 'user';
  const currentRole = rolesDTOs.map((r) => r.name); // ['IT Support', 'Administrator']

  // if (typeof roles !== 'undefined' && !roles.includes(currentRole)) {
  if (typeof roles !== 'undefined' && currentRole && !roles.some((role) => currentRole.includes(role))) {
    // eslint-disable-next-line no-nested-ternary
    return hasContent ? (
      <Container component={MotionContainer} sx={{ textAlign: 'center' }}>
        <m.div variants={varBounce().in}>
          <Typography variant="h3" paragraph>
            Permission Denied
          </Typography>
        </m.div>

        <m.div variants={varBounce().in}>
          <Typography sx={{ color: 'text.secondary' }}>You do not have permission to access this page</Typography>
        </m.div>

        <m.div variants={varBounce().in}>
          <ForbiddenIllustration sx={{ height: 260, my: { xs: 5, sm: 10 } }} />
        </m.div>
      </Container>
    ) : showNoAccessText ? (
      <Typography fontSize={14} display="flex" alignItems="center" justifyContent="center" pt={2}>
        No Access
      </Typography>
    ) : null;
  }

  return <>{children}</>;
}
