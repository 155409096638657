import PropTypes from 'prop-types';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router';
import { Stack, Box, CardHeader } from '@mui/material';
import { useDispatch } from 'react-redux';
// components
// import ExportButton from '../../../../components/export-button/ExportButton';
import Iconify from '../../../../components/Iconify';
import * as ModalTypes from '../../../../redux/modal/modalTypes';
import ExportButton from '../../../../components/export-button/ExportButton';
import SearchInput from '../../../../components/search-input/SearchInput';
import { PATH_DASHBOARD } from '../../../../routes/paths';

// ----------------------------------------------------------------------

FilterToolBar.propTypes = {
  title: PropTypes.string,
  placeholder: PropTypes.string,
  isFilter: PropTypes.bool,
  goBack: PropTypes.bool,
  isShowExportBtn: PropTypes.bool,
  subheader: PropTypes.string,
  exportFileName: PropTypes.string,
  buttonDetails: PropTypes.object,
  csvHeader: PropTypes.array,
  exportData: PropTypes.array,
  filterKeys: PropTypes.array,
};

export default function FilterToolBar({
  title,
  subheader = '',
  placeholder,
  isFilter = true,
  goBack = false,
  isShowExportBtn = false,
  buttonDetails = { isLink: false },
  csvHeader = [],
  exportData = [],
  exportFileName = 'GIG Mobility',
  filterKeys = [],
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleAddEvent = (action) => {
    dispatch({ type: ModalTypes.MODAL_OPEN, payload: action });
  };

  const words = title?.toLowerCase().split(' ');

  // Capitalize the first character of each word and join them back
  const titl = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');

  return (
    <Stack>
      <Box>
        <CardHeader title={titl} subheader={subheader} sx={{ mb: 1, py: 0 }} />

        {goBack && (
          <Box
            mb={2}
            mt={1}
            ml="20px"
            display="inline-block"
            sx={{ cursor: 'pointer', fontWeight: 'bold', color: ' #FFA07A' }}
            onClick={() => navigate(-1, { replace: false })}
          >
            <Iconify icon={'eva:arrowhead-left-outline'} sx={{ width: 20, height: 20 }} />
            Back
          </Box>
        )}
      </Box>

      <Stack direction={{ xs: 'column-reverse', sm: 'row' }} mt={1} justifyContent="space-between">
        <Box direction={{ xs: 'column-reverse', sm: 'row' }} justifyContent="space-between" mb={3}>
          <SearchInput isFilter={isFilter} placeholder={placeholder} exportData={exportData} filterKeys={filterKeys} />
        </Box>

        <Box display="flex" mb={{ xs: 0, md: 0 }}>
          {isShowExportBtn && (
            <Box display="flex" justifyContent="flex-end">
              <ExportButton data={exportData} csvHeader={csvHeader} exportFileName={exportFileName} />
            </Box>
          )}

          {buttonDetails?.isButton && (
            <Box pl={1} mb={2}>
              <LoadingButton
                fullWidth
                type="button"
                variant="contained"
                loading={false}
                sx={{ whiteSpace: 'nowrap', px: 2 }}
                onClick={() =>
                  buttonDetails?.isLink
                    ? navigate(PATH_DASHBOARD.user.new, { replace: false })
                    : handleAddEvent({ type: 'create', data: '' })
                }
              >
                <Iconify icon={`eva:${buttonDetails?.icon}`} sx={{ fontWeight: 'bold', height: 20, mr: 1 }} />{' '}
                {buttonDetails.label || 'Create'}
              </LoadingButton>
            </Box>
          )}
        </Box>
      </Stack>
    </Stack>
  );
}
