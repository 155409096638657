import { useState } from 'react';
import PropTypes from 'prop-types';
import { Stack, InputAdornment, TextField, Box, Typography, CardHeader, Button } from '@mui/material';
// components
import Iconify from '../../../../components/Iconify';
import { closeModal, openModal } from '../../../../redux/slices/calendar';
import { useDispatch } from '../../../../redux/store';
import * as ModalType from '../../../../redux/modal/modalTypes';

// ----------------------------------------------------------------------

HeaderBar.propTypes = {
  filterName: PropTypes.string,
  title: PropTypes.string,
  onFilterName: PropTypes.func,
};

export default function HeaderBar({ filterName, title, onFilterName, displayValue }) {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const handleAddEvent = (action) => {
    dispatch({ type: ModalType.MODAL_OPEN, payload: action });
    // dispatch(openModal(type));
  };
  return (
    <Stack spacing={2} direction={{ xs: 'column', sm: 'row' }} justifyContent="space-between" sx={{ py: 2.5, px: 3 }}>
      <CardHeader title={title} subheader={''} sx={{ mb: 2 }} />

      <Box display={displayValue} justifyContent="flex-end" sx={{ padding: 3 }}>
        <Button
          variant="contained"
          color="primary"
          // onClick={() => {}}
          onClick={() => handleAddEvent({ type: 'create', data })}
          sx={{ marginInlineEnd: 2 }}
          style={{ padding: '15px 20px' }}
        >
          Create Schedule
        </Button>
      </Box>
    </Stack>
  );
}
