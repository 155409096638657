import { useEffect, useState } from 'react';

// form
import { useSnackbar } from 'notistack';

// @mui
import { LoadingButton } from '@mui/lab';
import { Autocomplete, Box, Card, Grid, Stack, TextField } from '@mui/material';

// components
import { useDispatch, useSelector } from '../../../../../redux/store';

import Iconify from '../../../../../components/Iconify';
import { getCaptainByAction } from '../../../../../redux/settings/settingsActions';
import { MONTHS } from '../../../../../utils';

export default function CaptainRatingApprovalForm() {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const { fetchingBooking } = useSelector((state) => state.bookings);

  const [month, setMonth] = useState('');
  const [pageNumber, setPageNumber] = useState('');
  const [captainNo, setCaptainNo] = useState('');

  const {
    captainList: { userData: captainData },
  } = useSelector((state) => state.settings);

  const handleSubmit = () => {
    //
  };

  useEffect(() => {
    dispatch(getCaptainByAction(enqueueSnackbar));
  }, []);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Card sx={{ p: 3 }}>
          <Box>
            <Box
              sx={{
                marginTop: 3,
                display: 'grid',
                columnGap: 2,
                rowGap: 3,
                gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(3, 1fr)' },
              }}
            >
              <Autocomplete
                value={month}
                inputValue={month}
                onInputChange={(event, newInputValue) => {
                  setMonth(newInputValue);
                }}
                includeInputInList
                id="controllable-states-demo"
                options={MONTHS}
                renderInput={(params) => <TextField {...params} label="Rating Month" />}
              />

              <TextField
                autoComplete="pageNumber"
                value={pageNumber}
                onChange={({ target }) => setPageNumber(target.value)}
                fullWidth
                id="outlined-basic"
                label="Page Number"
                variant="outlined"
                className="mb-2"
              />

              <TextField
                autoComplete="captainNo"
                value={captainNo}
                onChange={({ target }) => setCaptainNo(target.value)}
                fullWidth
                id="outlined-basic"
                label="Captain Number"
                variant="outlined"
                className="mb-2"
              />
            </Box>
          </Box>

          <Stack alignItems="flex-end" sx={{ mt: 3 }}>
            <LoadingButton type="button" onClick={handleSubmit} variant="contained" loading={fetchingBooking}>
              <Iconify icon={'eva:search-fill'} sx={{ marginRight: 1, fontSize: '18px' }} /> Search
            </LoadingButton>
          </Stack>
        </Card>
      </Grid>
    </Grid>
  );
}
