import PropTypes from 'prop-types';
// @mui
import { useDispatch } from 'react-redux';
import {
  Box,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  Typography,
  MenuItem,
  Divider,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import { useState } from 'react';
import Label from '../../../../../components/Label';
import Iconify from '../../../../../components/Iconify';
import Scrollbar from '../../../../../components/Scrollbar';
import { TableHeadCustom, TableMoreMenu, TableNoData } from '../../../../../components/table';
import CustomLoader from '../../../../../components/CustomLoader';
import * as ModalTypes from '../../../../../redux/modal/modalTypes';

// ----------------------------------------------------------------------

DeductionsTable.propTypes = {
  loading: PropTypes.bool,
  tableData: PropTypes.array,
  dense: PropTypes.any,
  serialNo: PropTypes.number,
  tableLabels: PropTypes.array,
};

export default function DeductionsTable({ loading, tableLabels, tableData, dense, serialNo }) {
  const [selected, setSelected] = useState(0);
  return (
    <Scrollbar>
      <TableContainer sx={{ minWidth: 720 }} style={{ maxHeight: '70vh' }}>
        <Table size={dense ? 'small' : 'medium'} stickyHeader aria-label="sticky table">
          <TableHeadCustom headLabel={tableLabels} />
          <TableBody>
            {loading ? (
              <Box component="tr" sx={{ height: '60vh' }}>
                <CustomLoader />
              </Box>
            ) : (
              tableData.map((row, i) => (
                <DeductionTableRow
                  selected={selected}
                  setSelected={setSelected}
                  key={i}
                  row={row}
                  serialNo={serialNo + i}
                />
              ))
            )}

            {!loading && tableData.length === 0 ? <TableNoData isNotFound /> : ''}
          </TableBody>
        </Table>
      </TableContainer>
    </Scrollbar>
  );
}

// ----------------------------------------------------------------------

DeductionTableRow.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.any,
    captainCode: PropTypes.string,
    remark: PropTypes.string,
    amount: PropTypes.number,
    createdDate: PropTypes.string,
    approvalRemarks: PropTypes.string,
    dateModified: PropTypes.string,
    approvalRejectBy: PropTypes.string,
    approvalComment: PropTypes.string,
    dateApproved: PropTypes.string,
  }),
  serialNo: PropTypes.number,
};

function DeductionTableRow({ row, serialNo }) {
  const [openMenu, setOpenMenuActions] = useState(null);

  const handleOpenMenu = (event) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };

  const dispatch = useDispatch();

  const handleAddEvent = (action) => {
    dispatch({ type: ModalTypes.MODAL_OPEN, payload: action });
    handleCloseMenu();
  };
  return (
    <TableRow>
      <TableCell align="left">{serialNo}</TableCell>
      <TableCell align="center">{row?.captainCode}</TableCell>
      <TableCell align="center">{row?.approvalRemarks || '--'}</TableCell>
      <TableCell align="center">{row?.amount}</TableCell>
      <TableCell align="center">{row?.createdDate ? new Date(row?.createdDate).toISOString() : '--'}</TableCell>
      <TableCell align="center">{row?.dateModified ? new Date(row?.dateModified).toISOString() : '--'}</TableCell>
      <TableCell align="center">
        {row?.isApproved ? (
          <Label variant="filled" color="default">
            Approve By Audit
          </Label>
        ) : (
          <Label variant="filled" color="default">
            Pending
          </Label>
        )}
      </TableCell>
      {/* <TableCell align="right">
        <LoadingButton
          color="info"
          type="button"
          variant="contained"
          loading={false}
          size="small"
          sx={{ whiteSpace: 'nowrap', px: 2 }}
        >
          Change Status{' '}
        </LoadingButton>
      </TableCell> */}
      <TableCell>
        <Box>
          <Typography fontSize={14}>{row?.approvedBy}</Typography>
          <Typography fontSize={14}>{row?.rejectedBy}</Typography>
        </Box>
      </TableCell>
      <TableCell align="center">{row?.approvalRemarks || '--'}</TableCell>
      <TableCell align="center">{row?.approvalDate ? new Date(row?.approvalDate).toISOString() : '--'}</TableCell>
      <TableCell align="right">
        <TableMoreMenu
          open={openMenu}
          onOpen={handleOpenMenu}
          onClose={handleCloseMenu}
          actions={
            <>
              <MenuItem onClick={() => handleAddEvent({ type: 'approve_decline', data: row })}>
                <Iconify icon={'eva:eye-outline'} />
                Approve
              </MenuItem>

              <MenuItem onClick={() => handleAddEvent({ type: 'approve_decline', data: row })}>
                <Iconify icon={'eva:edit-outline'} />
                Decline
              </MenuItem>

              <Divider sx={{ borderStyle: 'dashed' }} />

              <MenuItem onClick={() => handleAddEvent({ type: 'delete', data: row?.id })} sx={{ color: 'error.main' }}>
                <Iconify icon={'eva:trash-2-outline'} />
                Delete
              </MenuItem>
            </>
          }
        />
      </TableCell>
    </TableRow>
  );
}
