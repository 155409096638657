import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { InputAdornment, TextField } from '@mui/material';
import { useDispatch } from 'react-redux';
import Iconify from '../Iconify';
import { filterData } from '../../redux/search/searchActions';

const SearchInput = ({ isFilter, placeholder, exportData, filterKeys }) => {
  const dispatch = useDispatch();

  const [input, setInput] = useState('');
  const [dataToSearch, setDataToSearch] = useState([]);

  useEffect(() => {
    if (!input) setDataToSearch(exportData);
  }, [exportData]);

  // const handleChange = ({ target }) => {
  //   setInput(target.value);
  //   const filteredData = dataToSearch.filter((list) =>
  //     filterKeys.some((prop) => list[prop]?.toLowerCase()?.includes(target.value.toLowerCase()))
  //   );
  //   dispatch(filterData(filteredData));
  // };

  const handleChange = ({ target }) => {
    setInput(target.value.toLowerCase());

    const filteredData = dataToSearch.filter((list) =>
      filterKeys.some((prop) => {
        const isObj = prop.split('.');
        let propValue = '';
        if (isObj[1]?.length > 0) {
          if (list[isObj[0]] && list[isObj[0]][isObj[1]] !== null) {
            propValue = list[isObj[0]][isObj[1]];
          } else {
            propValue = null;
          }
        } else {
          propValue = list[prop];
        }
        if (typeof propValue === 'number') {
          return propValue.toString().toLowerCase().includes(target.value);
        }
        if (typeof propValue === 'string') {
          return propValue.toLowerCase().includes(target.value);
        }
        return false;
      })
    );

    dispatch(filterData(filteredData));
  };

  return isFilter ? (
    <TextField
      // fullWidth
      size="small"
      value={input}
      onChange={handleChange}
      placeholder={placeholder}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Iconify icon={'eva:search-fill'} sx={{ color: 'text.disabled', width: 20, height: 20 }} />
          </InputAdornment>
        ),
      }}
    />
  ) : (
    ''
  );
};

SearchInput.propTypes = {
  isFilter: PropTypes.bool,
  exportData: PropTypes.array,
  filterKeys: PropTypes.array,
  placeholder: PropTypes.string,
};

export default memo(SearchInput);
