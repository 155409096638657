import { PATH_AUTH, PATH_DASHBOARD } from '../../routes/paths';
import { httpRequest } from '../../https/http';
import * as AuthTypes from './authTypes';

export const loginAction = (data, enqueueSnackbar, navigate) => async (dispatch) => {
  try {
    dispatch({ type: AuthTypes.LOGGING_IN });

    // const timeout = setTimeout(() => {
    //   enqueueSnackbar('Request Timeout!', { variant: 'error' });
    //   dispatch({ type: AuthTypes.LOGIN_FAILED });
    // }, 45000);

    const result = await httpRequest({
      url: 'Accounts/Login',
      method: 'POST',
      body: data,
      applyToken: false,
    });

    if (result.code === 1) {
      if (result?.data?.terminalDTO?.id === 0) {
        return enqueueSnackbar('Terminal Not Assigned!', { variant: 'error' });
      }

      const { refreshToken, tokenExpiration } = result.data;
      const tokenInfo = { refreshToken, tokenExpiration };
      localStorage.setItem('gig-access-token', result.data.token);
      localStorage.setItem('gig-refresh-token', JSON.stringify(tokenInfo));
      const today = new Date();
      today.setHours(today.getHours() + 2);
      localStorage.setItem('gig-access-token-timer', JSON.stringify(today));
      if (result?.data?.tempLogin) {
        return navigate(PATH_AUTH.resetPassword);
      }
      enqueueSnackbar('LogIn Successful', { variant: 'success' });
      dispatch({ type: AuthTypes.LOGIN_SUCCESS, payload: result?.data });
      // clearTimeout(timeout);
    } else {
      if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
      dispatch({ type: AuthTypes.LOGIN_FAILED, payload: result.message || 'Invalid login credentials' });
    }
  } catch (error) {
    enqueueSnackbar(error?.message || 'something went wrong, please try again.', { variant: 'error' });
    dispatch({ type: AuthTypes.LOGIN_FAILED, payload: 'something went wrong, please try again.' });
  } finally {
    dispatch({ type: AuthTypes.CLEAR_AUTH_ERROR });
  }
};

export const registerAction = (data, enqueueSnackbar, navigate, reset) => async (dispatch) => {
  try {
    dispatch({ type: AuthTypes.REGISTERING });
    const result = await httpRequest({
      url: 'Accounts/Register',
      method: 'POST',
      body: data,
    });
    if (result.code === 1) {
      enqueueSnackbar('User Created Successful', { variant: 'success' });
      dispatch({ type: AuthTypes.REGISTER_SUCCESS });
      navigate(PATH_DASHBOARD.portal.account, { replace: false });
      reset();
    } else {
      if (result.shortDescription)
        enqueueSnackbar(result.ShortDescription || result.shortDescription, { variant: 'error' });
      dispatch({
        type: AuthTypes.REGISTER_FAILED,
        payload: result.ShortDescription || result.shortDescription || 'Invalid login credentials',
      });
    }
  } catch (error) {
    enqueueSnackbar(error?.message || 'oops !!! something went wrong, please try again.', { variant: 'error' });
    dispatch({
      type: AuthTypes.REGISTER_FAILED,
      payload: error.message || 'oops !!! something went wrong, please try again.',
    });
  } finally {
    dispatch({ type: AuthTypes.CLEAR_AUTH_ERROR });
  }
};

export const updateUserAction = (data, enqueueSnackbar, navigate, reset) => async (dispatch) => {
  try {
    dispatch({ type: AuthTypes.REGISTERING });
    const result = await httpRequest({
      url: 'Accounts/user',
      method: 'PUT',
      body: data,
    });

    if (result.code === 1) {
      enqueueSnackbar('User Updated Successful', { variant: 'success' });
      dispatch({ type: AuthTypes.REGISTER_SUCCESS });
      navigate(PATH_DASHBOARD.portal.account, { replace: false });
      reset();
    } else {
      if (result.shortDescription)
        enqueueSnackbar(result.ShortDescription || result.shortDescription, { variant: 'error' });
      dispatch({
        type: AuthTypes.REGISTER_FAILED,
        payload: result.ShortDescription || result.shortDescription || 'Invalid login credentials',
      });
    }
  } catch (error) {
    enqueueSnackbar(error?.message || 'oops !!! something went wrong, please try again.', { variant: 'error' });
    dispatch({
      type: AuthTypes.REGISTER_FAILED,
      payload: error.message || 'oops !!! something went wrong, please try again.',
    });
  } finally {
    dispatch({ type: AuthTypes.CLEAR_AUTH_ERROR });
  }
};

export const logout =
  (msg = '') =>
  (dispatch) => {
    localStorage.removeItem('gig-access-token');
    localStorage.removeItem('gig-refresh-token');
    dispatch({ type: AuthTypes.LOGOUT, payload: msg });
    // dispatch({ type: BookingsTypes.LOGOUT, payload: msg });
    // dispatch({ type: SettingsTypes.LOGOUT, payload: msg });
    // window.location.href = PATH_AUTH.login;
  };
