export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const LOGGING_IN = 'LOGGING_IN';
export const CLEAR_AUTH_ERROR = 'CLEAR_AUTH_ERROR';

export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAILED = 'REGISTER_FAILED';
export const REGISTERING = 'REGISTERING';
export const NOT_AUTH_MSG = 'NOT_AUTH_MSG';

export const LOGOUT = 'LOGOUT';
