import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

export const handleConvertByte = async (base64Data, reportName = 'report') => {
  try {
    if (!base64Data) {
      throw new Error('Base64 data is empty or invalid.');
    }

    // Decode the base64 string
    const binaryData = atob(base64Data);

    // Convert binary string to an array buffer
    const arrayBuffer = new ArrayBuffer(binaryData.length);
    const uintArray = new Uint8Array(arrayBuffer);
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < binaryData.length; i++) {
      uintArray[i] = binaryData.charCodeAt(i);
    }

    // Convert the array buffer to a workbook
    const workbook = XLSX.read(uintArray, { type: 'array' });

    // Convert the workbook to a binary string
    const binaryString = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' });

    // Convert binary string to a Blob
    const blob = new Blob([s2ab(binaryString)], { type: 'application/octet-stream' });

    // Save the file
    saveAs(blob, `${reportName}.xlsx`);
  } catch (error) {
    console.error('Error converting Base64 to Excel:', error);
  }
};

const s2ab = (s) => {
  const buf = new ArrayBuffer(s.length);
  const view = new Uint8Array(buf);
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < s.length; i++) {
    view[i] = s.charCodeAt(i);
  }
  return buf;
};
