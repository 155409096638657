import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
  width: PropTypes.string,
  height: PropTypes.string,
};

export default function Logo({ disabledLink = false, sx, width = '60', height = '60' }) {
  const theme = useTheme();

  const PRIMARY_LIGHT = theme.palette.primary.light;

  const PRIMARY_MAIN = theme.palette.primary.main;

  const PRIMARY_DARK = theme.palette.primary.dark;

  // OR
  // -------------------------------------------------------
  // const logo = (
  //   <Box
  //     component="img"
  //     src="/logo/logo_single.svg" => your path
  //     sx={{ width: 40, height: 40, cursor: 'pointer', ...sx }}
  //   />
  // );

  const logo = (
    <Box sx={{ width, height, ...sx }}>
      <svg width={width} height={height} viewBox={`0 0 ${width} 60`} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M60 0H0V60H60V0Z" fill="#E21D00" />
        <path
          d="M50.5208 35.2074L52.4947 35.1913L47.9081 25.3983C47.65 24.8459 47.2474 24.4202 46.6128 24.3672C46.5379 24.3613 46.4632 24.3667 46.3909 24.389C45.993 24.5087 45.8141 25.0573 45.7272 25.4175L44.0362 32.4409L38.9894 25.4833C38.7791 25.193 38.5295 24.8855 38.2428 24.6685C38.0612 24.5319 37.8367 24.4143 37.6028 24.4383C36.9944 24.5015 36.7986 24.9382 36.8052 25.5003L36.9088 35.3144L38.8826 35.2989L38.5487 27.8059L43.5723 34.6669C43.8634 35.0643 44.2508 35.3752 44.7652 35.3709C45.2184 35.368 45.4438 35.0627 45.5462 34.6518L47.274 27.7369L50.5207 35.2074L50.5208 35.2074ZM34.922 26.62L34.8946 24.7618L28.8733 24.8094C27.3072 24.8211 25.8821 25.2818 24.8229 26.4767C23.9146 27.4991 23.5474 28.7326 23.5673 30.085C23.5883 31.5026 24.0352 32.7646 24.9904 33.8214C26.0641 35.0102 27.4476 35.5125 29.0311 35.5L35.8119 35.4466L35.7249 29.5436L28.7944 29.5981L28.8196 31.3077L33.8917 31.2676L33.9261 33.6034L29.0037 33.6418C27.9449 33.6504 26.9965 33.3385 26.2803 32.5325C26.0763 32.304 25.9023 32.0507 25.7671 31.7762C25.5028 31.2423 25.3977 30.6637 25.4106 30.0709C25.4301 29.188 25.7118 28.3981 26.3137 27.7435C27.0026 26.9927 27.8946 26.6754 28.9009 26.6676L34.9222 26.62H34.922ZM18.8619 26.7555L18.8345 24.8973L12.8126 24.9449C11.247 24.9572 9.82187 25.4179 8.76221 26.6127C7.8545 27.6351 7.4861 28.8686 7.50608 30.2211C7.527 31.638 7.975 32.8995 8.92968 33.9574C10.0028 35.1463 11.3868 35.648 12.9703 35.6355L19.7507 35.5821L19.6636 29.6795L12.7343 29.734L12.7595 31.4437L17.8304 31.4037L17.8649 33.7383L12.943 33.7773C11.8842 33.7853 10.9358 33.4745 10.219 32.6686C10.0156 32.4395 9.84159 32.1862 9.70581 31.9117C9.44257 31.3782 9.33647 30.7991 9.34938 30.2068C9.36942 29.3239 9.65163 28.5336 10.253 27.8795C10.9425 27.1287 11.8335 26.8109 12.8401 26.803L18.862 26.7554L18.8619 26.7555ZM20.7664 35.5693L22.6248 35.5549L22.4672 24.8643L20.6088 24.8787L20.7664 35.5693Z"
          fill="#FEFEFE"
        />
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
