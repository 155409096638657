import PropTypes from 'prop-types';
import { useState } from 'react';
// @mui
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';

import { useTheme } from '@mui/material/styles';
import { Avatar, TableRow, TableCell, Typography, MenuItem, Divider } from '@mui/material';
// components
import Label from '../../../components/Label';
import Iconify from '../../../components/Iconify';
import { TableMoreMenu } from '../../../components/table';
import * as ModalTypes from '../../../redux/modal/modalTypes';
import { getUserDataBaseLogByEmailAction } from '../../../redux/settings/settingsActions';
import { getUsersDetailsAction } from '../../../redux/user/userAction';

// ----------------------------------------------------------------------

UserTableRow.propTypes = {
  row: PropTypes.object,
  selected: PropTypes.bool,
  onEditRow: PropTypes.func,
};

export default function UserTableRow({ row, selected, onEditRow }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { name, firstName, lastName, avatarUrl, email, phoneNumber, userType, gender, isActive } = row;

  const [openMenu, setOpenMenuActions] = useState(null);

  const handleOpenMenu = (event) => {
    setOpenMenuActions(event.currentTarget);
  };
  const handleAddEvent = (action) => {
    if (action.type === 'viewLog') {
      dispatch(getUserDataBaseLogByEmailAction(enqueueSnackbar, action.data));
    }
    if (action.type === 'view') {
      dispatch(getUsersDetailsAction(enqueueSnackbar, action.data));
    }
    dispatch({ type: ModalTypes.MODAL_OPEN, payload: action });
    handleCloseMenu();
  };
  // const handleAddEvent = (action) => {
  //   dispatch({ type: ModalTypes.MODAL_OPEN, payload: action });
  //   handleCloseMenu();
  // };
  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };

  return (
    <TableRow hover selected={selected}>
      {/* <TableCell padding="checkbox">
        <Checkbox checked={selected} onClick={onSelectRow} />
      </TableCell> */}

      <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
        <Avatar alt={name} src={avatarUrl} sx={{ mr: 2 }} />
        <Typography variant="subtitle2" noWrap>
          {`${firstName} ${lastName}`}
        </Typography>
      </TableCell>

      <TableCell align="left">{email}</TableCell>

      <TableCell align="left" sx={{ textTransform: 'capitalize' }}>
        {phoneNumber}
      </TableCell>

      {/* <TableCell align="center">{gender}</TableCell> */}
      <TableCell align="center">{userType}</TableCell>

      <TableCell align="left">
        <Label
          variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
          color={isActive === 'Active' ? 'success' : 'error'}
          sx={{ textTransform: 'capitalize' }}
        >
          {isActive}
        </Label>
      </TableCell>

      <TableCell align="right">
        <TableMoreMenu
          open={openMenu}
          onOpen={handleOpenMenu}
          onClose={handleCloseMenu}
          actions={
            <>
              <MenuItem
                onClick={() => {
                  handleAddEvent({ type: 'view', data: row.email });
                }}
              >
                <Iconify icon={'eva:eye-outline'} />
                View Details
              </MenuItem>
              <MenuItem
                onClick={() => {
                  onEditRow();
                  handleCloseMenu();
                }}
              >
                <Iconify icon={'eva:edit-fill'} />
                Edit
              </MenuItem>

              <MenuItem
                onClick={() => {
                  handleAddEvent({ type: 'viewLog', data: row.email });
                }}
              >
                <Iconify icon={'eva:eye-outline'} />
                View Logs
              </MenuItem>

              <MenuItem onClick={() => handleAddEvent({ type: 'ban', data: { ...row, type: 'ban' } })}>
                {row?.isActive !== 'Active' ? (
                  <>
                    <Iconify icon={'material-symbols:lock-open-right'} color={row?.status ? 'green' : 'error.main'} />
                    Unban User
                  </>
                ) : (
                  <>
                    <Iconify icon="prime:ban" />
                    Ban User
                  </>
                )}
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleAddEvent({ type: 'viewLog', data: row.email });
                }}
              >
                <Iconify icon={'eva:edit-fill'} />
                Admin Reset
              </MenuItem>
              <Divider sx={{ borderStyle: 'dashed' }} />
              <MenuItem
                onClick={() => {
                  handleAddEvent({ type: 'delete', data: row.id });
                }}
                sx={{ color: 'error.main' }}
              >
                <Iconify icon={'eva:trash-2-outline'} />
                Delete
              </MenuItem>
            </>
          }
        />
      </TableCell>
    </TableRow>
  );
}
