import * as SearchTypes from './searchTypes';

const initalState = {
  loading: false,
  filteredData: [],
};

// eslint-disable-next-line
const searchReducer = (state = initalState, action) => {
  switch (action.type) {
    case SearchTypes.SEARCHING:
      return {
        ...state,
        loading: true,
      };

    case SearchTypes.SEARCH_COMPLETED:
      return {
        ...state,
        loading: false,
        filteredData: action.payload.filteredData,
      };

    default:
      return state;
  }
};

export default searchReducer;
