import * as SearchTypes from './searchTypes';
import { httpRequest } from '../../https/http';

export const filterData = (filteredData) => async (dispatch) => {
  dispatch({ type: SearchTypes.SEARCH_COMPLETED, payload: { filteredData } });
};

export const searchInputItem =
  (enqueueSnackbar, paginated = false) =>
  async (dispatch) => {
    dispatch({ type: SearchTypes.SEARCH_COMPLETED });
    try {
      const result = await httpRequest({
        url: `url?Descending=false&Paginated=${paginated}`,
        urlType: '',
      });

      if (result.code === 1) {
        dispatch({ type: '', payload: result?.data });
      }

      if (result.code !== 1) {
        dispatch({ type: SearchTypes.SEARCH_COMPLETED });
        if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
      }
    } catch (error) {
      dispatch({ type: SearchTypes.SEARCH_COMPLETED });
      enqueueSnackbar(error?.message || 'Unable to fetch data! Please refresh and try again', { variant: 'error' });
    }
  };
