import * as OperationTypes from './operationTypes';

const initalState = {
  loading: false,
  fetching: false,
  error: '',
  allStartUpReportList: { userData: [] },
  allFinalReportList: { userData: [] },
  todayTerminalStartupList: [],
  yesterdayTerminalFinalList: [],
  vehicleNotAtTerminalList: [],
  vehicleNotAccountedForList: [],
  vehicleNotInEndOfDayList: [],
};

// eslint-disable-next-line
const operationReducer = (state = initalState, action) => {
  switch (action.type) {
    case OperationTypes.LOADING_OPERATION:
      return {
        ...state,
        loading: true,
      };
    case OperationTypes.FETCHING_OPERATION:
      return {
        ...state,
        fetching: true,
      };

    case OperationTypes.GET_ALL_STARTUP_REPORT:
      return {
        ...state,
        loading: false,
        allStartUpReportList: action.payload,
      };
    case OperationTypes.GET_ALL_FINAL_REPORT:
      return {
        ...state,
        loading: false,
        allFinalReportList: action.payload,
      };
    case OperationTypes.GET_TODAY_TERMINAL_STARTUP:
      return {
        ...state,
        loading: false,
        todayTerminalStartupList: action.payload,
      };
    case OperationTypes.GET_YESTERDAY_TERMINAL_FINAL:
      return {
        ...state,
        loading: false,
        yesterdayTerminalFinalList: action.payload,
      };
    case OperationTypes.GET_VEHICLE_NOT_AT_TERMINAL:
      return {
        ...state,
        loading: false,
        vehicleNotAtTerminalList: action.payload,
      };
    case OperationTypes.GET_VEHICLE_NOT_IN_END_OF_DAY:
      return {
        ...state,
        loading: false,
        vehicleNotInEndOfDayList: action.payload,
      };
    case OperationTypes.GET_VEHICLE_NOT_ACCOUNTED_FOR:
      return {
        ...state,
        loading: false,
        vehicleNotAccountedForList: action.payload,
      };

    case OperationTypes.LISTING_RESET:
      return {
        ...state,
        loading: false,
        fetching: false,
        error: '',
      };
    case OperationTypes.LOGOUT:
      return {
        loading: false,
        error: '',
      };

    default:
      return state;
  }
};

export default operationReducer;
