import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { m, AnimatePresence } from 'framer-motion';
// @mui
import { Dialog, Box, Paper } from '@mui/material';
//
import { varFade } from './variants';
//
import './modal.css';

// ----------------------------------------------------------------------

DialogAnimate.propTypes = {
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  sx: PropTypes.object,
  variants: PropTypes.object,
  size: PropTypes.string,
};

export default function DialogAnimate({ open = false, variants, size = 'xs', onClose, children, sx, ...other }) {
  return (
    <>
      <div>
        {open && (
          <Dialog
            fullWidth
            maxWidth={size}
            open={open}
            onClose={onClose}
            className="myModal"
            PaperComponent={(props) => (
              <Box
                component={m.div}
                // {...(variants ||
                //   varFade({
                //     distance: 120,
                //     durationIn: 0.32,
                //     durationOut: 0.24,
                //     easeIn: 'easeInOut',
                //   }).inUp)}
                className="myModal"
                sx={{
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Box className="myModal" onClick={onClose} sx={{ width: '100%', height: '100%', position: 'fixed' }} />
                <Paper sx={sx} {...props}>
                  {props.children}
                </Paper>
              </Box>
            )}
            {...other}
          >
            {children}
          </Dialog>
        )}
      </div>
    </>
  );
}
